import { put, call, takeEvery } from "redux-saga/effects";
import * as types from "./types";
import * as apis from "../../network/apis/notifications";
import {
  getAllNotificationsResponse,
  getNotificationsResponse
} from "./actions";
import { replaceSeenNotificationInList } from "../../utils/Helpers";

export function* getNotificationsSaga({ payload }) {
  try {
    const response = yield call(apis.getNotifications, payload);
    yield put(
      getNotificationsResponse({
        list: response.data.notifications,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* setNotificationSeen({ payload }) {
  try {
    const response = yield call(apis.setNotificationSeen, payload);
    yield replaceSeenNotificationInList(response.data.notification);
  } catch (err) {
    console.log(err);
  }
}

export function* getAllNotificationsSage() {
  try {
    const response = yield call(apis.getNotifications, {
      items: 1,
      page: -1
    });
    yield put(
      getAllNotificationsResponse({
        list: response.data.notifications
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export default function* NotificationsSagas() {
  yield takeEvery(
    types.GET_NOTIFICATIONS_REQUEST,
    getNotificationsSaga
  );
  yield takeEvery(types.SET_NOTIFICATION_SEEN, setNotificationSeen);
  yield takeEvery(
    types.GET_ALL_NOTIFICATIONS_REQUEST,
    getAllNotificationsSage
  );
}
