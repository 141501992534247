import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { browserName } from "react-device-detect";

import messages from "../../../assets/locale/messages";
import AuthLayout from "../../../components/AuthLayout";
import Input from "../../../components/Input";
import Checkbox from "../../../components/Checkbox";
import Button from "../../../components/Button";
import { loginRequest } from "../../../store/Auth/actions";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { generateDeviceId } from "../../../utils/Helpers";
import { getFirebaseToken } from "../../../firebase/helpers";
import "./Login.scss";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector((state) => state.locale.lang);
  const { auth } = messages[lang];

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(auth.validations.validEmail)
        .required(auth.validations.email),
      password: Yup.string().required(auth.validations.password)
    }),
    onSubmit: ({ email, password, rememberMe }) => {
      dispatch(
        loginRequest({
          data: {
            email,
            password,
            device_id: `${browserName}-${generateDeviceId()}`,
            fcm_token: getFirebaseToken()
          },
          rememberMe
        })
      );
    }
  });

  return (
    <AuthLayout showWelcomeTo={true}>
      <form className="form-auth" onSubmit={handleSubmit} noValidate>
        <Input
          id="email"
          label={auth.labels.email}
          name="email"
          type="email"
          placeholder={auth.placeholders.email}
          onChange={(value) => {
            setFieldTouched("email");
            setFieldValue("email", value.trim());
          }}
          value={values["email"]}
          inputClass="mb-1"
          labelClassName="font-medium mb-2"
          inputWrapperClass="my-4"
          isInputHasErr={!!(touched["email"] && errors["email"])}
          errMsg={errors["email"]}
        />
        <Input
          id="password"
          label={auth.labels.password}
          name="password"
          type="password"
          placeholder={auth.placeholders.password}
          onChange={(value) => {
            setFieldTouched("password");
            setFieldValue("password", value.trim());
          }}
          value={values["password"]}
          inputClass="mb-1"
          labelClassName="font-medium mb-2"
          inputWrapperClass="mt-4"
          isInputHasErr={
            !!(touched["password"] && errors["password"])
          }
          errMsg={errors["password"]}
        />
        <p
          className="fsize-14 text-gray text-start forgot-password pointer"
          onClick={() => {
            history.push(ROUTE_PATHS.forgotPassword);
          }}
        >
          {auth.labels.forgotPassword}
        </p>
        <Checkbox
          id="rememberMe"
          label={auth.labels.remember}
          labelClass="checkbox-label-login fsize-14 mb-4"
          name="rememberMe"
          checked={values["rememberMe"]}
          onChange={(checked) => {
            setFieldTouched("rememberMe");
            setFieldValue("rememberMe", checked);
          }}
        />
        <Button
          label={auth.labels.signin}
          type="submit"
          labelClass="fsize-14 text-white py-1"
          className="mb-4"
          block
          disabled={!dirty || !isValid}
        />
      </form>
    </AuthLayout>
  );
};

export default Login;
