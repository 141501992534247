import React from "react";

export const LocationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="28"
    fill="none"
    viewBox="0 0 22 28"
    {...props}
  >
    <path
      stroke="#AAA7A7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11 15.25a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
    ></path>
    <path
      stroke="#AAA7A7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11 1.5a10 10 0 00-10 10c0 2.365.502 3.912 1.875 5.625L11 26.5l8.125-9.375C20.497 15.412 21 13.865 21 11.5a10 10 0 00-10-10v0z"
    ></path>
  </svg>
);
