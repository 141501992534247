import { axiosInstance } from "./";

const viewProfile = async (id) =>
  await axiosInstance.get(`/wholesalers/${id}`);

const editProfile = async ({ id, data }) =>
  await axiosInstance.patch(`/wholesalers/${id}`, data);

const getDeliveryMethods = async (params) =>
  await axiosInstance.get(`delivery_methods`, { params });

export { viewProfile, editProfile, getDeliveryMethods };
