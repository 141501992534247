import React from "react";

export const Exclamation = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="14"
      fill="none"
      viewBox="0 0 5 14"
      {...props}
    >
      <path
        fill="#828282"
        d="M4.279 5.059l-3.15.394-.112.523.619.114c.404.096.484.242.396.645l-1.015 4.768c-.267 1.233.145 1.814 1.111 1.814.75 0 1.62-.347 2.015-.822l.12-.572c-.274.242-.676.338-.943.338-.378 0-.515-.266-.418-.733l1.377-6.47zm.096-2.872a1.375 1.375 0 11-2.75 0 1.375 1.375 0 012.75 0z"
      ></path>
    </svg>
  );
};
