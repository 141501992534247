import React from "react";

export const NonAvailable = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="23"
    fill="none"
    viewBox="0 0 37 23"
    {...props}
  >
    <path
      fill="#D1373F"
      fillOpacity="0.9"
      d="M26.457.957a10.543 10.543 0 110 21.086H10.643a10.543 10.543 0 010-21.086h15.814zM10.643 16.771a5.271 5.271 0 100-10.542 5.271 5.271 0 000 10.542z"
    ></path>
  </svg>
);
