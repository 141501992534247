import React from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import history from "./History";
import { ROUTE_PATHS } from "../utils/RoutesPaths";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
// ========= Components =========
import Login from "../containers/Auth/Login";
import ForgotPassword from "../containers/Auth/ForgotPassword";
import CheckEmail from "../containers/Auth/CheckEmail";
import SetPassword from "../containers/Auth/SetPassword";
import Warehouse from "../containers/Warehouse";
import KuzloProducts from "../containers/Products/KuzloProducts";
import MyProducts from "../containers/Products/MyProducts";
import QuickEdit from "../containers/Products/MyProducts/QuickEdit";
import ViewProduct from "../containers/Products/MyProducts/ViewProduct";
import ViewProfile from "../containers/Profile";
import EditProfile from "../containers/Profile/EditProfile";
import EditWarehouse from "../containers/Warehouse/EditWarehouse";
import PickLocation from "../containers/Warehouse/PickLocation";
import Orders from "../containers/Orders";
import ViewOrder from "../containers/Orders/ViewOrder";
// import Notifications from "../containers/Notifications";
import Offers from "../containers/Offers";
import ViewOffer from "../containers/Offers/ViewOffer";

const Routes = (
  <Router history={history}>
    <Switch>
      <PrivateRoute
        component={ViewProfile}
        path={ROUTE_PATHS.viewProfile}
        exact
      />
      <PrivateRoute
        component={EditProfile}
        path={ROUTE_PATHS.editProfile}
        exact
      />
      <PrivateRoute
        component={Warehouse}
        path={ROUTE_PATHS.warehouse}
        exact
      />
      <PrivateRoute
        component={EditWarehouse}
        path={ROUTE_PATHS.editWarehouse}
        exact
      />
      <PrivateRoute
        component={PickLocation}
        path={ROUTE_PATHS.editWarehouseLocation}
      />
      <PrivateRoute
        component={MyProducts}
        path={ROUTE_PATHS["myProducts"]}
        exact
      />
      <PrivateRoute
        component={QuickEdit}
        path={ROUTE_PATHS.myProductsQuickedit}
        exact
      />
      <PrivateRoute
        component={ViewProduct}
        path={ROUTE_PATHS["viewMyProducts"]}
        exact
      />
      <PrivateRoute
        component={KuzloProducts}
        path={ROUTE_PATHS["serviceProviderProducts"]}
        exact
      />
      <PrivateRoute
        component={Orders}
        path={ROUTE_PATHS["orders"]}
        exact
      />
      <PrivateRoute
        component={ViewOrder}
        path={ROUTE_PATHS["viewOrder"]}
      />
      <PrivateRoute
        component={Offers}
        path={ROUTE_PATHS.offers}
        exact
      />
      <PrivateRoute
        component={ViewOffer}
        path={[ROUTE_PATHS.viewOffer]}
        exact
      />
      {/* <PrivateRoute
        component={Notifications}
        path={ROUTE_PATHS["notifications"]}
      /> */}
      <PublicRoute
        component={Login}
        path={[ROUTE_PATHS.login, ROUTE_PATHS.root]}
        exact
      />
      <PublicRoute
        component={ForgotPassword}
        path={[ROUTE_PATHS.forgotPassword]}
        exact
      />
      <PublicRoute
        component={CheckEmail}
        path={[ROUTE_PATHS.checkEmail]}
        exact
      />
      <PublicRoute
        component={SetPassword}
        path={[ROUTE_PATHS.setPassword, ROUTE_PATHS.verifyAccount]}
      />

      <Redirect from="**" to={ROUTE_PATHS.root} />
    </Switch>
  </Router>
);

export default Routes;
