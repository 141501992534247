const kuzloEn = {
  navbar: {
    notifications: "Notifications",
    profile: "Profile",
    search: "Search",
    seeAll: "See All",
    markRead: "Mark as seen",
    noNotifications: "There are no notifications yet"
  },
  general: {
    invalidDate: "تاريخ غير صحيح",
    le: "جنيه",
    showMore: "مشاهدة المزيد",
    emptySearch: "عذرا لا يوجد نتائج مطابقة للبحث",
    offerValue: "قيمة العرض",
    startDate: "تاريخ البداية",
    endDate: "تاريخ الانتهاء",
    startTime: "وقت البداية",
    endTime: "وقت الانتهاء",
    outStock: "نفذ من المخزن",
    offers: "خصومات",
    offer: "خصم",
    noteOfChangingProductsWillAffectOffers:
      "برجاء الانتباه انه عند حذف المنتج او تغيير سعره سوف يتم الغاء العروض المتاحه عليه تلقائيا"
  },
  products: {
    title: "Products",
    myProducts: "My Products",
    emptySearch: "No search results ",
    pound: "le",
    productCard: {
      description: "description",
      Category: "Category",
      Related: "Related",
      modalLabel: "Add Product ",
      price: "Price",
      pound: "pound",
      outOfStock: "out of Stock",
      quantity: "Quantity",
      minQuantity: "Min Order Quantity",
      maxQuantity: "  Max Order Quantity",
      modalButton: " save to my products ",
      label: "Add to my products",
      successMessage:
        "The product has been successfully added to your product list",
      priceErrorReq: "Product is manadtory",
      priceErrorMin: "Price must be greater than 0 EGP",
      pricrNumber: "please enter a valid price",
      quantityError: "Quantity must be greater than or equal to 0",
      minError: "Min Quantity must be greater than or equal to 0",
      maxError: "Max Quantity must be greater than or equal to 0",
      minMaxError: "Max Quantity must be greater than Min Quantity",
      noDescription: "No description"
    },
    serviceProviderProducts: "Kuzlo Products",
    filter: "Categories",
    search: "Search for a product name",
    emptyState: {
      subtitle: "There are no products to display yet",
      description: "Start now to add products from the kuzlo store",
      buttonLabel: "Add Products"
    },
    deleteProduct: {
      message:
        "Do you want to permanently delete this product from your products?",
      delete: "delete",
      cancel: "cancel"
    },
    viewProduct: {
      productDetails: " Product Details",
      inStock: "In Stock",
      outStock: " out of Stock ",
      deleteProduct: " Delete Product",
      editProduct: "تعديل",
      category: "category",
      pound: "pound",
      description: "description",
      price: "price",
      quantity: "quantity",
      minQuantity: "Min Order Quantity"
    },
    editMessege: "Edited",
    quickEdit: {
      buttonText: "تعديل سريع",
      editPrice: "تعديل السعر",
      convertUnavailable: "تحويل لغير متوفرة",
      convertAvailable: "تحويل لمتوفرة",
      delete: "حذف",
      selectedProducts: "المنتجات المحددة",
      thisItemsWillBeDeleted: "( سيتم حذف هذه المنتجات )",
      thisItemsWillBeAvailable: "( سيتم وضع هذه المنتجات  ك متوفرة )",
      thisItemsWillBeNotAvailable:
        "( سيتم وضع هذه المنتجات  ك غير متوفرة )",
      noResultFound: "لا يوجد نتائج",
      deleteProducts: "حذف المنتجات",
      convertToAvailable: "تحويل المنتجات لمتوفرة",
      convertToNotAvailable: "تحويل المنتجات لغير متوفرة",
      deleteConfirmationMsg:
        "هل تريد حذف هذه المنتجات بشكل نهائي من منتجاتك؟",
      editPricesConfirmationMsg: "هل تريد تعديل أسعار هذه المنتجات؟",
      convertToAvailableConfirmationMessage:
        "هل تريد تحويل هذه المنتجات لمنتجات متوفرة؟",
      convertToUnavailableConfirmationMessage:
        "هل تريد تحويل هذه المنتجات لمنتجات غير متوفرة؟",
      edit: "تعديل",
      cancel: "إلغاء",
      convert: "تحويل",
      discardChanges: "تجاهل التغييرات",
      successDeleteMsg: "تم حذف المنتجات بنجاح",
      successEditPricesMsg: "تم تعديل الأسعار بنجاح",
      convertInStockSuccess: "تم تحويل المنتجات لمتوفرة بنجاح",
      le: "جنيه",
      piceErrorMsg: "يجب إدخال سعر أكبر من 0",
      saveChanges: "حفظ التغييرات",
      navigationModalMessage: "هل تريد تجاهل التغييرات؟",
      tableTitles: {
        productName: "اسم المنتج",
        productCategory: "التصنيف",
        price: "السعر"
      }
    },
    appliedOffers: "العروض على المنتج"
  },
  auth: {
    login: "Login",
    logout: "Logout",
    welcome: "Welcome In",
    subTitle: "Enter your email, to change your password",
    checkEmail: "Please check your email to complete change password",
    setPasswordSuccessMsg: "Password is saved successfully",
    resendVerification:
      " اذا انتهي الزمن الخاص برابط التفعيل اضغط علي",
    url: "ارسال رابط جديد",
    labels: {
      email: "Email",
      password: "Password",
      remember: "Remember me next time",
      signin: "Sign in",
      forgotPassword: "Forgot Password?",
      confirm: "Confirm",
      newPassword: "New Password",
      confirmPassword: "Confirm New Password",
      newPasswordHint: "Must be 8 characters or numbers at least"
    },
    placeholders: {
      email: "Enter your email",
      password: "Enter your password",
      newPassword: "Enter New Password"
    },
    validations: {
      email: "Email is required",
      validEmail: "Email must be a valid email address",
      password: "Password is required",
      passwordMatch: "Password & confirm password should match",
      passwordMinLength:
        "Password must be 8 characters or numbers at least"
    }
  },
  profile: {
    profile: "Profile",
    name: "Name",
    email: "Email",
    mobile: "Mobile",
    address: "Address",
    minOrderPrice: "Min order price",
    logo: "Logo",
    edit: "Edit",
    bankInfo: "Bank Info",
    bankName: "Bank Name",
    branchName: "Branch Name",
    accountNumber: "Account Number",
    ibanNumber: "IBAN",
    incontactInfo: "Incontact Info",
    deliveryMethods: "Delivery Methods",
    seviceProvider: "Kuzlo",
    pickup: "Pick Up",
    direct: "Direct delivery",
    paymentMethods: "Payment Methods",
    cash: "Direct pay",
    online: "Online pay",
    status: "Status",
    notVerified: "Not Verified",
    verified: "Verified",
    active: "Active",
    deactivated: "Deactivated",
    published: "Published",
    joiningDate: "Joining Date",
    enterPlaceholder: "Enter {{field}}",
    validations: {
      required: "{{field}} is required",
      validEmail: "Email must be a valid email address",
      validPhone: "Phone must be a valid phone number",
      validIban:
        "IBAN number must be a valid IBAN number (29 digits)",
      delivery: "You have to choose at least one delivery method",
      payment: "You have to choose at least one payment method",
      arabic: "This field must be in Arabic"
    },
    saveChanges: "Save Changes",
    successMsg: "Profile updated successfully",
    uploadLabel: "Upload"
  },
  warehouse: {
    title: "Warehouse",
    warehouseDetails: "Warehouse Details",
    minOrderPrice: " الحد الادني للطلب",
    name: "Name",
    location: "Location",
    coverageArea: "Coverage Areas",
    viewMap: "View Map",
    from: "from",
    to: "to",
    edit: "Edit",
    searchMapPlaceholder: "Search for location",
    warehouseLocation: "Warehhouse Location",
    nameRequired: "Name is required",
    locationRequired: "Location is required",
    enterName: "Enter name",
    saveChanges: "Save Changes",
    saveAndContinue: "Save and Continue",
    pickLocationTitle:
      "Choose and select the location of your warehouse",
    warehouseUpdatedSuccessMsg: "Warehouse updated successfully",
    noWarehouseTitle: "Currently you don't have a warehouse",
    noWarehouseDescription: "Contact Kuzlo to add a warehouse",
    noCoverageAreas: "There are no coverage areas yet"
  },
  orders: {
    title: "Orders",
    searchPlaceholder: "  Order number or Retailer phone number",
    retailerPlaceholder: "Retailer's Store name",
    orderStatusPlaceholder: " Order Status",
    notes: "Notes",
    startDate: "Start Date ",
    endDate: " End Date",
    onGoing: " On Going",
    history: "History",
    orderNumber: " Order No",
    warehouseName: "Warehouse Name",
    checkoutDate: " Checkout Date",
    details: " View Details",
    emptyState: "There are no orders to display yet",
    filterOrder: {
      pending: " Pending",
      in_progress: "InProgress ",
      out_for_delivery: " Out For Delivery",
      delivered: "Delivered ",
      cancelled: "Cancelled ",
      rejected: "Rejected "
    },
    viewOrder: {
      details: "تفاصيل الطلب",
      wholsaalerDetails: "بيانات تاجر التجزئة",
      name: "الاسم",
      warehouse: "المتجر",
      phone: "رقم الموبايل",
      deliveryAdress: "عنوان التوصيل",
      order: "الطلب",
      orderNumber: "رقم الطلبية",
      orderValue: "قيمة اجمالي الطلبية",
      orderValueAfterDiscount: "قيمة اجمالي الطلبية بعد الخصم",
      deliveryMethod: "طريقة التوصيل ",
      paymentMethod: "طريقة الدفع ",
      date: "تاريخ الدفع",
      status: "Choose the Status",
      currentStatus: "حالة الطلب الحالية",
      expectedDelivery: "وقت التوصيل المتوقع",
      orderProducts: "منتجات الطلبية",
      viewProducts: "مشاهدة كل المنتجات",
      productList: "قائمة منتجات الطلبية",
      totalCard: "الاجمالي",
      productName: "اسم المنتج",
      price: "سعر المنتج",
      quentity: "الكمية المطلوبة",
      le: "le",
      day: "يوم",
      retailerLocation: "الموقع",
      shareLocation: "نشر الموقع",
      linkCopied: "تم نسخ الرابط بنجاح",
      appliedPromoCode: "كود الخصم",
      promoCodeDiscount: "قيمة الخصم"
    }
  },
  offers: {
    sideMenuTitle: "العروض",
    filterStatus: {
      expired: "منتهي",
      pending: "قيد الانتظار",
      active: "جاري الان"
    },
    filterSubStatus: {
      enabled: "مفعل",
      disabled: "غير مفعل"
    },
    emptyState: "لا يوجد عروض لعرضها الي الان",
    filtersPlaceHolders: {
      status: "حالة العرض",
      subStatus: "الحالة الفرعية"
    },
    searchPlaceHolder: "إسم المنتج",
    table: {
      headlines: {
        status: "الحالة",
        title: "الإسم",
        date: "التاريخ",
        time: "الوقت",
        view: "مشاهدة التفاصيل",
        enabled: "مفعل"
      }
    },
    outOfStock: "نفذ من المخزن",
    start: "من",
    end: "إلي",
    pending: "قيد الانتظار",
    active: "جاري الان",
    expired: "منتهي",
    viewOffer: {
      product: "المنتج",
      discountVlaue: "قيمة الخصم",
      amount: "قيمة نقدية",
      percentage: "نسبة مئوية",
      le: "جنيه",
      startDateAndTime: "تاريخ ووقت البداية",
      startDate: "تاريخ البداية",
      startTime: "وقت البداية",
      endDateAndTime: "تاريخ ووقت النهاية",
      endDate: "تاريخ النهاية",
      endTime: "وقت النهاية",
      description: "الوصف",
      category: "التصنيف",
      noProducts: "لا يوجد",
      prevProductOffersQoute: "يوجد عروض بالفعل على هذا المنتج",
      viewDetails: "مشاهدة التفاصيل",
      start: "من",
      end: "إلي",
      originalPrice: "السعر الأصلي",
      currentPrice: "السعر الحالي",
      status: "الحالة",
      value: "القيمة",
      price: "السعر",
      expirationDate: "تاريخ إنتهاء صلاحية العرض",
      expirationReason: "سبب إنتهاء صلاحية العرض",
      enabled: "مفعل",
      disabled: "غير مفعل"
    }
  }
};

export default kuzloEn;
