import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import { SwiperSlide } from "swiper/react";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { Share } from "@material-ui/icons";

import Breadcrumb from "../../../components/Breadcrumb";
import Stepper from "../../../components/Stepper";
import ProductCard from "../../../components/ProductCard";
import Modal from "../../../components/Modal";
import Table from "../../../components/Table";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import GoogleMaps from "../../../components/GoogleMaps";
import { showHideSnackbar } from "../../../store/Snackbar/actions";
import EgyptFlag from "../../../assets/svgs/flag-egypt.svg";
import messages from "../../../assets/locale/messages";
import { formatDate } from "../../../utils/Helpers";
import { orderStatus } from "../../../utils/Constants";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { Exclamation } from "../../../utils/Icons/Exclamation";
import Export from "../../../utils/Icons/Export";
import {
  getOrderDetailsRequest,
  getOrderDetailsResponse,
  getOrderPDFRequest,
  getOrderPDFResponse,
  updateOrderRequest
} from "../../../store/Orders/actions";
import "./ViewOrder.scss";

const ExclamationTooltip = withStyles((theme) => ({
  tooltip: {
    color: theme.tooltip.backgroundOrange,
    fontSize: "1rem"
  },
  arrow: {
    fontSize: "1rem"
  }
}))(Tooltip);

const ViewOrder = () => {
  const lang = useSelector((state) => state.locale.lang);
  const orderDetails = useSelector(
    (state) => state.orders.orderDetails
  );
  const pdfUrl = useSelector((state) => state.orders.pdfUrl);

  const [openModal, setOpenAModal] = useState(false);
  const [nextOrderStatus, setNextOrderStatus] = useState();
  const [retailerLocationCoords, setRetailerLocationCoords] =
    useState({ coords: null });

  const { orders, general } = messages[lang];
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getOrderDetailsRequest(id));
    }
    return () => {
      setNextOrderStatus(null);
      dispatch(getOrderDetailsResponse(null));
    };
  }, [id]);

  useEffect(() => {
    setRetailerLocationCoords({
      coords: {
        lat: orderDetails?.address?.lat,
        lng: orderDetails?.address?.lng
      }
    });
  }, [orderDetails]);

  useEffect(() => {
    if (pdfUrl) {
      window.open(pdfUrl);
    }
    return () => {
      dispatch(getOrderPDFResponse(null));
    };
  }, [pdfUrl]);

  const productImagesSlides = (productImage) => {
    let slides = [];
    slides.push(
      <SwiperSlide key={1}>
        <div
          className={`${
            productImage
              ? "product-card-image-container "
              : "product-card-image-container product-card-image-container-empty"
          }`}
          style={{
            backgroundImage: `url(${
              productImage ||
              process.env.REACT_APP_PRODUCTS_PLACEHOLDER
            })`
          }}
        ></div>
      </SwiperSlide>
    );

    return slides;
  };

  const handleView = () => {
    setOpenAModal(true);
  };
  const handleCloseModal = () => {
    setOpenAModal(false);
  };

  const handleOrderStatus = (selectedStatus) => {
    setNextOrderStatus(selectedStatus.value);
  };

  const updateOrder = ({ status }) => {
    dispatch(
      updateOrderRequest({
        id,
        data: { status }
      })
    );
  };

  useEffect(() => {
    if (nextOrderStatus) {
      updateOrder({
        status: nextOrderStatus
      });
    }
  }, [nextOrderStatus]);

  const handleShareClick = () => {
    const locationLink = `https://www.google.com/maps/search/?api=1&query=${retailerLocationCoords?.coords?.lat},${retailerLocationCoords?.coords?.lng}`;
    navigator.clipboard.writeText(locationLink);
    dispatch(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: orders.viewOrder.linkCopied
      })
    );
  };

  return (
    <div className="content-wrapper view-order ">
      <div className="d-flex justify-content-between align-items-start logo-container ">
        <div className="d-flex align-items-center">
          <Breadcrumb
            linksArray={[
              {
                link: ROUTE_PATHS["orders"],
                name: orders.viewOrder.listOrders
              },
              {
                name: orders.viewOrder.details
              }
            ]}
          />
          <Button
            outlined
            label={
              <div className="d-flex align-items-center">
                <Export
                  fontSize="large"
                  className="opacity_7 export-icon"
                />
                <span className="pe-1 fsize-16 font-semibold ">
                  {orders.viewOrder.export}
                </span>
              </div>
            }
            labelClass=" font-white"
            className=" me-5 export-btn "
            onClick={() => {
              dispatch(getOrderPDFRequest(id));
            }}
          />
        </div>
        <div
          style={{
            backgroundImage: `url(${orderDetails?.wholesaler_logo})`
          }}
          className="warehouse-logo "
        ></div>
      </div>

      <div className="pe-5 me-2">
        <div className="font-medium text-dark fsize-18">
          {orders.viewOrder.wholsaalerDetails}
        </div>
        <div className="row mt-4">
          <div className="col-4">
            <div className="text-dark font-reguler">
              {orders.viewOrder.name}
            </div>
            <div className="text-dark-blue font-bold  fsize-16">
              {orderDetails?.retailer_name}
            </div>
          </div>
          <div className="col-4">
            <div className="text-dark font-reguler">
              {orders.viewOrder.warehouse}
            </div>
            <div className="text-dark-blue   fsize-16 d-flex justify-content-start align-items-center">
              <p className="font-bold">
                {orderDetails?.retailer_storename}
              </p>
            </div>
          </div>
          <div className="col-4">
            <div className="text-dark font-reguler">
              {orders.viewOrder.phone}
            </div>
            <div className="d-flex align-items-center">
              <div className="text-dark-blue font-bold fsize-16 phone-number">
                +20 {orderDetails?.retailer_phone_number}
              </div>
              <div className="flag-container bg-gray-flag p-2">
                <img className="img-fluid" src={EgyptFlag} alt="eg" />
              </div>
            </div>
          </div>
        </div>
        <div className=" border-gray-hr-light  ms-5">
          <div className="text-dark font-reguler ">
            {orders.viewOrder.deliveryAdress}
          </div>
          <div className="text-dark-blue  fsize-16 d-flex align-items-center">
            <p className="ms-1 pb-3 font-bold">
              {orderDetails?.address?.building_number}
            </p>
            <p className="ms-1 pb-3 font-bold ">
              {orderDetails?.address?.street_name}
            </p>
            <p className="ms-1 pb-3 font-bold">
              {orderDetails?.address?.area}
            </p>
            <p className="ms-1 pb-3 font-bold">
              {orderDetails?.address?.additional_info}
            </p>
          </div>
        </div>

        <div className="border-bottom mb-3 mt-4 pb-5">
          <div className="text-dark font-reguler">
            {orders.viewOrder.retailerLocation}
          </div>
          <div className="mt-4">
            {retailerLocationCoords?.coords?.lat &&
              retailerLocationCoords?.coords?.lng && (
                <>
                  <div className="position-relative">
                    <div className="shareLocationBtn ">
                      <Button
                        outlined
                        className="me-5 px-4 "
                        label={
                          <span className="d-flex align-items-center">
                            <Share className="share-icon" />
                            <span className="pe-2">
                              {orders.viewOrder.shareLocation}
                            </span>
                          </span>
                        }
                        labelClass="fsize-16 ps-2"
                        onClick={handleShareClick}
                      />
                    </div>
                  </div>
                  <GoogleMaps
                    hasSearch={false}
                    savedCoords={retailerLocationCoords}
                    savedPolygon={[]}
                    className="w-75 position-relative orderDetailsMap"
                    viewOnly={true}
                    isMarker={true}
                    isPolygon={false}
                  />
                </>
              )}
          </div>
        </div>
        <div className="mb-5 ">
          <div className="font-medium text-dark fsize-18">
            {orders.viewOrder.order}
          </div>
          <div
            className={`d-flex align-items-center mt-4 ${
              orderDetails?.promo_code !== null
                ? "justify-content-start flex-wrap"
                : "justify-content-between w-85"
            } `}
          >
            <div className="ms-4 mt-3">
              <div className="text-dark font-reguler">
                {orders.viewOrder.orderNumber}
              </div>
              <div className="text-dark-blue font-bold fsize-16 mt-3">
                {orderDetails?.order_number}
              </div>
            </div>

            <div className="ms-4 mt-3">
              <div className="text-dark font-reguler">
                {orders.viewOrder.deliveryMethod}
              </div>
              <div className="text-dark-blue font-bold fsize-16 mt-3">
                {orderDetails?.delivery_method}
              </div>
            </div>
            <div className="ms-4 mt-3">
              <div className="text-dark font-reguler">
                {orders.viewOrder.paymentMethod}
              </div>
              <div className="text-dark-blue font-bold fsize-16 mt-3">
                {orderDetails?.payment_method}
              </div>
            </div>
            <div className="ms-4 mt-3">
              <div className="text-dark font-reguler">
                {orders.viewOrder.date}
              </div>
              <div className="text-dark-blue font-bold fsize-16 pe-2 mt-3">
                {formatDate(
                  orderDetails?.created_at,
                  "DD/MM/yyyy",
                  "en"
                )}
              </div>
            </div>
            {orderDetails?.promo_code !== null && (
              <div className="d-flex align-items-center justify-content-between ">
                <div className="ms-4 mt-3">
                  <div className="text-dark font-reguler">
                    {orders.viewOrder.appliedPromoCode}
                  </div>
                  <div className="mt-2 general-values-container">
                    <span className="text-dark-blue font-bold fsize-16 ">
                      {orderDetails?.promo_code?.code}
                    </span>
                  </div>
                </div>
                <div className="ms-4 mt-3">
                  <div className="text-dark font-reguler">
                    {orders.viewOrder.promoCodeDiscount}
                  </div>
                  <div className="mt-2 d-flex justify-content-center general-values-container">
                    <span className="text-dark-blue font-bold fsize-16">
                      {`${orderDetails?.promo_code?.discount_value} 
                                ${
                                  orderDetails?.promo_code
                                    ?.discount_type === "percentage"
                                    ? "%"
                                    : orders.viewOrder.le
                                }`}
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex flex-column align-items-start ms-4 mt-3">
              <div className="text-dark font-reguler">
                {orders.viewOrder.orderValue}
              </div>
              <div
                className={`text-dark-blue  fsize-16 mt-2 order-value ${
                  orderDetails?.total_price_after_discount !==
                  orderDetails?.total_price
                    ? "line-through"
                    : ""
                }`}
              >
                <span className="font-bold ">
                  {orderDetails?.total_price}
                </span>
                <span className="ps-1 font-bold">
                  {orders.viewOrder.le}
                </span>
              </div>
            </div>
            {orderDetails?.total_price_after_discount !==
              orderDetails?.total_price && (
              <div className="d-flex flex-column align-items-start ms-4 mt-3">
                <div className="text-dark font-reguler">
                  {orders.viewOrder.orderValueAfterDiscount}
                </div>
                <div className="text-dark-blue  fsize-16 mt-2 order-value">
                  <span className="font-bold">
                    {orderDetails?.total_price_after_discount}
                  </span>
                  <span className="ps-1 font-bold">
                    {orders.viewOrder.le}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex mb-4">
          <div className="stepper  col-6 mb-4">
            <Stepper
              steps={[
                {
                  label: orders.filterOrder.pending,
                  value: orderStatus.pending,
                  disabled: true
                },
                {
                  label: orders.filterOrder.in_progress,
                  value: orderStatus.inProgress,
                  disabled:
                    orderDetails?.status !== orderStatus.pending &&
                    orderDetails?.status !==
                      orderStatus.outForDelivery
                },
                {
                  label: orders.filterOrder.out_for_delivery,
                  value: orderStatus.outForDelivery,
                  disabled:
                    orderDetails?.status !== orderStatus.inProgress
                },
                {
                  label: orders.filterOrder.delivered,
                  value: orderStatus.delivered,
                  disabled:
                    orderDetails?.status !==
                    orderStatus.outForDelivery
                }
              ]}
              handleSelectStep={handleOrderStatus}
              currentStep={orderDetails?.status}
              dynamicChange={false}
            />
          </div>
          <div className=" p-1 col-2 cancel-btn">
            <Button
              className={`px-5 ${
                orderDetails?.status === orderStatus.cancelled
                  ? "active-cancell"
                  : ""
              }`}
              label={orders.filterOrder.cancelled}
              type="button"
              labelClass="fsize-16 "
              onClick={() => {
                setNextOrderStatus(orderStatus.cancelled);
              }}
              outlined
              disabled={orderDetails?.status == orderStatus.delivered}
            />
          </div>
          <div className=" p-1 col-2 reject-btn">
            <Button
              className={`px-5 ${
                orderDetails?.status === orderStatus.rejected
                  ? "active-reject"
                  : ""
              }`}
              label={orders.filterOrder.rejected}
              type="button"
              labelClass="fsize-16 "
              onClick={() => {
                setNextOrderStatus(orderStatus.rejected);
              }}
              outlined
              disabled={orderDetails?.status !== orderStatus.pending}
            />
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className=" text-dark fsize-14  pt-2">
            {orders.viewOrder.currentStatus}:
          </div>
          <div className="font-bold  second-black  fsize-14 pe-2  pt-2">
            {orders.filterOrder[orderDetails?.status]}
          </div>
        </div>

        <div className="row mb-4  pb-5  align-items-center border-bottom border-gray-hr-light">
          <div className=" text-dark fsize-14  pt-2 col-2">
            {orders.viewOrder.expectedDelivery} :
          </div>

          <div className="font-bold text-dark-blue  fsize-16 pt-2 col-1">
            <Input
              id="days"
              name="days"
              type="number"
              disabled
              value={orderDetails?.expected_receiving_days?.toString()}
              inputClass="mb-1 p-2"
              labelClassName="font-medium mb-2"
              inputWrapperClass="delivery-days-input"
              min="1"
            />
          </div>
          <div className="pe-1 font-bold col-1 d-flex">
            {orders.viewOrder.day}
            <ExclamationTooltip
              title={
                <div>{orders.viewOrder.expectedDaysTooltip}</div>
              }
              placement="left"
              arrow
            >
              <div className="exclamation me-2 d-flex justify-content-center align-items-center">
                <Exclamation />
              </div>
            </ExclamationTooltip>
          </div>
        </div>
        <div className="order-product ms-5 pb-4">
          <div className=" text-dark ms-2 my-2 fsize-14">
            {orders.viewOrder.orderProducts}
          </div>
          <div className="row">
            {orderDetails?.products_array?.map((product, index) => {
              if (index < 3) {
                return (
                  <div className="col-3 mb-3" key={index}>
                    <ProductCard
                      prdImgs={productImagesSlides(product.image)}
                      prdNameParent={product.name}
                      prdPrice={`${product.price_per_product.toString()} ${
                        orders.viewOrder.le
                      }`}
                      prdQuent={product.quantity.toString()}
                      actions
                      orderProductHasOffer={product.offer_id !== null}
                      product={product}
                      actionsElement={
                        <div className="d-flex justify-content-between align-items-center px-3 mt-0">
                          <p className="text-dark-blue">
                            {orders.viewOrder.totalCard}
                          </p>
                          <p className="price-label">
                            {product.total_price_after_discount !==
                            null
                              ? product.total_price_after_discount.toFixed(
                                  2
                                )
                              : product.total_price.toFixed(2)}
                            <span className="ps-1">
                              {orders.viewOrder.le}
                            </span>
                          </p>
                        </div>
                      }
                    />
                  </div>
                );
              }
            })}

            {orderDetails?.products_array?.length > 3 && (
              <div className="col-3 mt-2 ">
                <Button
                  className=" px-4 "
                  label={orders.viewOrder.viewProducts}
                  type="button"
                  labelClass="fsize-16  view-products"
                  outlined
                  onClick={() => {
                    handleView();
                  }}
                />
                <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  className="order-modal"
                  modalLabel={
                    <span className="modalLabel fsize-16">
                      {orders.viewOrder.productList}
                    </span>
                  }
                  modalBody={
                    <Table
                      headlines={[
                        " ",
                        " ",
                        orders.viewOrder.productName,
                        orders.viewOrder.price,
                        orders.viewOrder.quentity,
                        orders.viewOrder.totalCard
                      ]}
                      rows={orderDetails?.products_array?.map(
                        (row, index) => (
                          <TableRow key={index}>
                            <TableCell className="font-reguler text-dark-blue fsize-14">
                              {row.offer_id !== null && (
                                <div className="py-1 offer-flag-inside-modal">
                                  {general.offer}
                                </div>
                              )}
                            </TableCell>
                            <TableCell className="font-reguler text-dark-blue fsize-14 table-image  ">
                              <img
                                src={
                                  row.image ||
                                  process.env
                                    .REACT_APP_PRODUCTS_PLACEHOLDER
                                }
                                alt="product"
                              />
                            </TableCell>
                            <TableCell className="font-reguler font-medium text-dark-blue fsize-14   ">
                              <p> {row.name}</p>
                            </TableCell>
                            <TableCell className="font-reguler text-dark-blue fsize-14 ">
                              <div
                                className={`${
                                  row.offer_id !== null &&
                                  "line-through"
                                }`}
                              >
                                <span className="pe-1">
                                  {row.price_per_product}
                                </span>
                                {orders.viewOrder.le}
                              </div>
                              {row.offer_id !== null && (
                                <div>
                                  <span className="pe-1">
                                    {row.new_price_per_product}
                                  </span>
                                  {orders.viewOrder.le}
                                </div>
                              )}
                            </TableCell>
                            <TableCell className="font-reguler text-dark-blue  ">
                              {row.quantity}
                            </TableCell>
                            <TableCell className="font-medium err-asterisk  fsize-14 table-price ">
                              <span className="pe-1">
                                {row.total_price_after_discount !==
                                null
                                  ? row.total_price_after_discount.toFixed(
                                      2
                                    )
                                  : row.total_price.toFixed(2)}
                              </span>
                              {orders.viewOrder.le}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    />
                  }
                />
              </div>
            )}
          </div>
          {orderDetails?.delivery_notes && (
            <div
              className="delivery-notes m-2 p-4"
              id="order-delivery-notes"
            >
              <p className="fsize-18 font-medium mb-2">
                {orders.notes}
              </p>
              <p>{orderDetails?.delivery_notes}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewOrder;
