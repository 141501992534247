import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import messages from "../../../../assets/locale/messages";
import { CustomPrevOffersCalendar } from "../../../../utils/Icons/CustomPrevOffersCalendar";
import { CustomPrevOffersClock } from "../../../../utils/Icons/CustomPrevOffersClock";
import {
  offerActiveStatus,
  offerPendingStatus,
  offerExpiredStatus
} from "../../../../utils/Constants";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import { formatDate } from "../../../../utils/Helpers";
import "./PreviousOffersModal.scss";

const PreviousOffersModal = ({ prevOffer, closeModal }) => {
  const history = useHistory();

  const lang = useSelector((state) => state.locale.lang);
  const { offers } = messages[lang];

  const checkOfferStatus = (status) => {
    if (offerActiveStatus.includes(status)) {
      return "active";
    } else if (offerPendingStatus.includes(status)) {
      return "pending";
    } else if (offerExpiredStatus.includes(status)) {
      return "expired";
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mb-3 mt-4 previous-offers-modal-container ">
      <div
        className="d-flex justify-content-center w-100 fsize-20 font-cairo-semibold text-product-card-text mb-2 previous-offer-title"
        onClick={() => {
          history.push(
            ROUTE_PATHS["viewOffer"].replace(":id", prevOffer.id)
          );
          closeModal();
        }}
      >
        {prevOffer.name}
      </div>

      <div className="d-flex justify-content-between px-4 mt-4 w-100 ">
        <div className="d-flex justify-content-start align-items-baseline">
          <span className="mx-2 py-1 gray-text fsize-14">
            {offers.viewOffer.status}
          </span>
          <span
            className={`status status-${checkOfferStatus(
              prevOffer.status
            )} `}
          >
            {offerActiveStatus.includes(prevOffer?.status)
              ? offers.active
              : offerPendingStatus.includes(prevOffer?.status)
              ? offers.pending
              : offers.expired}
          </span>
        </div>
        <div className="d-flex justify-content-start align-items-baseline">
          <span className="mx-2 gray-text fsize-14 pt-2">
            {offers.viewOffer.value}
          </span>
          <div className="text-uppercase prev-offer-value fsize-16 font-cairo-semibold">
            {prevOffer.discount_value}{" "}
            {`${
              prevOffer.discount_type === "amount"
                ? offers.viewOffer.le
                : "%"
            }`}
          </div>
        </div>
      </div>
      <div className="w-100 px-4 mt-3 price-container">
        <p>
          <span className="mx-2 gray-text fsize-14 pt-2">
            {offers.viewOffer.price}
          </span>
          <span className="mx-2 new-price">
            {prevOffer.new_price}
          </span>
          <span className="mx-2 old-price">{prevOffer.price}</span>

          <span className="mx-2 currency">{offers.viewOffer.le}</span>
        </p>
      </div>
      <div className="mt-4 w-100 dates-container position-relative">
        <div className="d-flex justify-content-between  w-95 pe-5 ps-3">
          <div className="d-flex">
            <p className="my-0 date-title me-4 ms-2 gray-text ">
              {offers.viewOffer.start}
            </p>
            <p className="my-0 date-text-color fsize-14 color-primary-light font-light">
              {formatDate(prevOffer.start_datetime, "DD/MM/YYYY")}
            </p>
          </div>
          <div className="d-flex">
            <p className="my-0 ms-3 gray-text ">
              {offers.viewOffer.start}
            </p>
            <p className="my-0  fsize-14 color-primary-light font-light direction-ltr ">
              {formatDate(prevOffer.start_datetime, "A hh:mm")}
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-3 w-95 pe-5 ps-3">
          <div className="d-flex">
            <p className="my-0 date-title me-4 ms-2 gray-text ">
              {offers.viewOffer.end}
            </p>
            <p className="my-0 date-text-color fsize-14 color-primary-light font-light">
              {formatDate(prevOffer.end_datetime, "DD/MM/YYYY")}
            </p>
          </div>
          <div className="d-flex">
            <p className="my-0 ms-3 gray-text ">
              {offers.viewOffer.end}
            </p>
            <p className="my-0  fsize-14 color-primary-light font-light">
              {formatDate(prevOffer.end_datetime, "hh:mm A")}
            </p>
          </div>
        </div>

        <div className="calendar-icon-container">
          <CustomPrevOffersCalendar />
        </div>
        <div className="clock-icon-container">
          <CustomPrevOffersClock />
        </div>
      </div>
    </div>
  );
};

export default PreviousOffersModal;

PreviousOffersModal.propTypes = {
  prevOffer: PropTypes.object,
  closeModal: PropTypes.func
};
