import * as types from "./types";

export const loginRequest = (data) => ({
  type: types.LOGIN_REQUEST,
  payload: data
});

export const loginResponse = (data) => ({
  type: types.LOGIN_RESPONSE,
  payload: data
});

export const logoutRequest = (data) => ({
  type: types.LOGOUT_REQUEST,
  payload: data
});

export const forgotPasswordRequest = (data) => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  payload: data
});

export const setPasswordRequest = (data) => ({
  type: types.SET_PASSWORD_REQUEST,
  payload: data
});

export const resendEmailVerification = (data) => ({
  type: types.RESEND_EMAIL_VERIFICATION_REQUEST,
  payload: data
});
