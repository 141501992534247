import React from "react";

export const SolidDelete = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    fill="none"
    viewBox="0 0 18 20"
    {...props}
  >
    <path
      fill="#EB5757"
      d="M.75 4h3V2.125c0-.827.673-1.5 1.5-1.5h7.5c.827 0 1.5.673 1.5 1.5V4h3a.75.75 0 01.75.75v.75a.188.188 0 01-.188.188h-1.415l-.579 12.257c-.037.8-.698 1.43-1.498 1.43H3.68c-.802 0-1.46-.628-1.498-1.43L1.603 5.687H.188A.188.188 0 010 5.5v-.75A.75.75 0 01.75 4zm4.688 0h7.125V2.312H5.436V4z"
    ></path>
  </svg>
);
