import {
  SET_ORDERS_ROUTE,
  SET_WHOLESALER_PRODUCTS_ROUTE,
  SET_OFFERS_ROUTE
} from "./types";

export const setOrdersRoute = (payload) => ({
  type: SET_ORDERS_ROUTE,
  payload
});

export const setWholesalerProductsRoute = (payload) => ({
  type: SET_WHOLESALER_PRODUCTS_ROUTE,
  payload
});

export const setOffersRoute = (payload) => ({
  type: SET_OFFERS_ROUTE,
  payload
});
