import { axiosInstance } from "./";

const addWholesalerProduct = async (payload) => {
  return await axiosInstance.post(`wholesalers_products`, payload);
};
const getWholesalerProducts = async (params) => {
  return await axiosInstance.get("wholesalers_products", { params });
};

const deleteWholesalerProduct = async (id) =>
  axiosInstance.delete(`wholesalers_products/${id}`);

const getWholesalerProduct = async ({ id }) =>
  axiosInstance.get(`wholesalers_products/${id}`);

const editWholesalerProduct = async ({ data, id }) =>
  await axiosInstance.patch(`wholesalers_products/${id}`, data);

const deleteWholesalerProducts = async ({
  wholesalers_product_ids
}) =>
  await axiosInstance.delete(`wholesalers_products/bulk_destroy`, {
    data: { wholesalers_product_ids }
  });

const editWholesalerProductsPrices = async ({
  wholesaler_products
}) =>
  await axiosInstance.patch(`wholesalers_products/bulk_update`, {
    wholesaler_products
  });

const editWholesalerProductsInOutStock = async ({
  wholesalers_product_ids,
  is_in_stock
}) =>
  await axiosInstance.patch(`wholesalers_products/update_stock`, {
    wholesalers_product_ids,
    is_in_stock
  });

export {
  getWholesalerProduct,
  addWholesalerProduct,
  getWholesalerProducts,
  deleteWholesalerProduct,
  editWholesalerProduct,
  deleteWholesalerProducts,
  editWholesalerProductsPrices,
  editWholesalerProductsInOutStock
};
