import { put, call, takeEvery } from "redux-saga/effects";

import * as types from "./types";
import * as apis from "../../network/apis/offers";
import {
  getOffersResponse,
  getOffersRequest,
  getPreviousProductOffersResponse,
  getOfferResponse
} from "./actions";

export function* getOffersSaga({ payload }) {
  try {
    const response = yield call(apis.getOffers, payload);
    yield put(
      getOffersResponse({
        list: response.data.offers,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
    if (
      +response.headers["current-page"] >
      +response.headers["total-pages"]
    ) {
      yield put(
        getOffersRequest({
          ...payload,
          page: +response.headers["total-pages"]
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getPreviousProductOffersSaga({ payload }) {
  try {
    const response = yield call(apis.getOffers, payload);
    yield put(getPreviousProductOffersResponse(response.data.offers));
  } catch (err) {
    console.log(err);
  }
}

export function* getOfferSaga({ payload }) {
  try {
    const response = yield call(apis.getOffer, payload);
    yield put(getOfferResponse(response.data.offer));
  } catch (err) {
    console.log(err);
  }
}

export default function* OffersSagas() {
  yield takeEvery(types.GET_OFFERS_REQUEST, getOffersSaga);
  yield takeEvery(
    types.GET_PREVIOUS_PRODUCT_OFFERS_REQUEST,
    getPreviousProductOffersSaga
  );
  yield takeEvery(types.GET_OFFER_REQUEST, getOfferSaga);
}
