import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";

import messages from "../../../assets/locale/messages";
import Filter from "../../../components/Filter";
import SearchInput from "../../../components/SearchInput";
import ProductCard from "../../../components/ProductCard";
import Pagination from "../../../components/Pagination";
import EmptyState from "../../../components/EmptyState";
import Switch from "../../../components/Switch";
import AddEditProduct from "../../../components/AddEditProduct";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import AlertNote from "../../../components/AlertNote";
import {
  getWholesalerProductsRequest,
  getWholesalerProductsResponse,
  editWholesalerProductRequest,
  getWholesalerProductRequest,
  deleteWholsalerProductRequest
} from "../../../store/WholsalerProducts/actions";
import { getCategoriesRequest } from "../../../store/KuzloProducts/actions";
import { setWholesalerProductsRoute } from "../../../store/Routing/actions";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { EditIcon } from "../../../utils/Icons/EditIcon";
import { QuickEdit } from "../../../utils/Icons/QuickEdit";
import { productStatus } from "../../../utils/Constants";
import DeleteIcon from "../../../utils/Icons/Delete";
import "./MyProducts.scss";

const MyProducts = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const { products, general } = messages[lang];
  const isLoading = useSelector((state) => state.loader);
  const list = useSelector((state) => state.wholesLerProducts.list);
  const meta = useSelector((state) => state.wholesLerProducts.meta);
  const categories = useSelector(
    (state) => state.kuzloProducts.categories
  );
  const productsRoutingData = useSelector(
    (state) => state.routing.wholesalerProducts
  );
  const product = useSelector(
    (state) => state.wholesLerProducts.product
  );

  const [searchValue, setSearchValue] = useState(
    productsRoutingData?.search || ""
  );

  const [searchQuery, setSearchQuery] = useState(
    productsRoutingData?.search || ""
  );
  const [category, setCategory] = useState(
    productsRoutingData?.category || null
  );
  const [status, setStatus] = useState(
    productsRoutingData?.status || null
  );
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [page, setPage] = useState(productsRoutingData?.page || 1);
  const [openEdit, setOpenEdit] = useState(false);

  const updateSearchQuery = () => {
    setSearchValue(searchQuery);
  };
  const delayedSearchQuery = useCallback(
    debounce(updateSearchQuery, 500),
    [searchQuery]
  );
  useEffect(() => {
    delayedSearchQuery();
    // Cancel the debounce on useEffect cleanup.
    return delayedSearchQuery.cancel;
  }, [searchQuery, delayedSearchQuery]);

  useEffect(() => {
    getWholsalerProducts(page, searchValue, category?.id, status);
  }, [page]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      getWholsalerProducts(
        productsRoutingData?.page || 1,
        searchValue,
        category?.id,
        status
      );
      setPage(productsRoutingData?.page || 1);
    }
  }, [searchValue, category, status]);

  useEffect(() => {
    dispatch(
      getCategoriesRequest({
        page: -1,
        items: 1
      })
    );
    dispatch(
      getWholesalerProductsResponse({
        list: [],
        meta: {}
      })
    );
  }, []);

  useEffect(() => {
    const options = categories.map((category) => ({
      id: category.id,
      label: category.name
    }));
    setCategoriesOptions(options);
  }, [categories]);

  useEffect(() => {
    if (productsRoutingData) {
      dispatch(setWholesalerProductsRoute(null));
    }
  }, [productsRoutingData]);

  const productImagesSlides = (imagesArray) => {
    let slides = [];
    if (imagesArray.length) {
      slides = imagesArray.map((image) => (
        <SwiperSlide key={image.id}>
          <div
            className="product-card-image-container"
            style={{ backgroundImage: `url(${image.url})` }}
          ></div>
        </SwiperSlide>
      ));
    } else {
      slides.push(
        <SwiperSlide key={1}>
          <div
            className="product-card-image-container product-card-image-container-empty"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_PRODUCTS_PLACEHOLDER})`
            }}
          ></div>
        </SwiperSlide>
      );
    }
    return slides;
  };

  const getWholsalerProducts = (
    pageNumber,
    search,
    categotyId,
    status
  ) => {
    dispatch(
      getWholesalerProductsRequest({
        page: pageNumber,
        items: 12,
        query: search,
        product_category_id: categotyId || undefined,
        is_in_stock: status?.value
      })
    );
  };

  const viewProduct = (id) => {
    dispatch(
      setWholesalerProductsRoute({
        page,
        search: searchValue,
        category,
        status
      })
    );
    history.push(ROUTE_PATHS.viewMyProducts.replace(":id", id));
  };

  const handleAddProducts = () => {
    history.push(ROUTE_PATHS["serviceProviderProducts"]);
  };
  const toggleStock = (id, inStock) => {
    dispatch(
      editWholesalerProductRequest({
        id: id,
        page,
        query: searchValue,
        product_category_id: category?.id,
        is_in_stock: status?.value,
        data: {
          is_in_stock: !inStock
        }
      })
    );
  };

  const handleEdit = (id) => {
    setOpenEdit(true);
    dispatch(getWholesalerProductRequest({ id }));
  };

  const handleCloseEditModal = () => {
    setOpenEdit(false);
  };

  const handleDelete = (id) => {
    dispatch(
      deleteWholsalerProductRequest({
        productId: id,
        page,
        searchValue,
        categoryId: category?.id
      })
    );
  };
  const List = () => {
    if (
      !isLoading &&
      list.length == 0 &&
      !searchValue &&
      !category &&
      !status
    ) {
      return (
        <>
          <div className="empty-state content-wrapper d-flex justify-content-center">
            <EmptyState
              subTitle={products.emptyState.subtitle}
              description={products.emptyState.description}
              buttonLabel={products.emptyState.buttonLabel}
              handleButtonClick={() => {
                handleAddProducts();
              }}
            />
          </div>
        </>
      );
    } else if (
      list.length == 0 &&
      (searchValue || category || status)
    ) {
      return (
        <div className="empty-state content-wrapper d-flex justify-content-center">
          <EmptyState subTitle={products.emptySearch} />
        </div>
      );
    } else {
      return (
        <>
          <div className="content-wrapper">
            <div className="row my-products">
              {list.map((product, index) => (
                <div className="col-3 mb-4" key={index}>
                  <ProductCard
                    prdImgs={productImagesSlides(
                      product.product.images
                    )}
                    initialSlide={product.product?.images?.findIndex(
                      (image) => image.is_default
                    )}
                    prdDescription={product.product.description}
                    prdCategory={
                      product.product.super_product.product_category
                        .name
                    }
                    prdNameParent={product.product.concatenated_name}
                    prdSpecifications={product.product.specifications}
                    hasOffer={product.has_offer}
                    deactivated={product.status !== "active"}
                    insideWholesalerProducts
                    handleViewProduct={() => {
                      viewProduct(product.id);
                    }}
                    actions={true}
                    actionsElement={
                      <div className="d-flex justify-content-between align-items-center px-2 actions-card">
                        <div className="d-flex align-items-center">
                          <div className="d-flex flex-column align-items-center">
                            <Switch
                              checked={product?.is_in_stock || false}
                              name="inStock"
                              onChange={() => {
                                toggleStock(
                                  product.id,
                                  product?.is_in_stock
                                );
                              }}
                            />
                            <p className="toggle-text fsize-10">
                              {product?.is_in_stock
                                ? products.viewProduct.inStock
                                : products.viewProduct.outStock}
                            </p>
                          </div>
                          <div
                            className="d-flex align-items-start flex-column  edit me-2 mt-2"
                            onClick={() => {
                              handleEdit(product.id);
                            }}
                          >
                            <EditIcon />
                            <span className="fsize-10 pt-1 toggle-text">
                              {products.viewProduct.editProduct}
                            </span>
                          </div>
                          <div
                            className="d-flex align-items-center flex-column delete me-2 mt-2"
                            onClick={() => {
                              handleDelete(product.id);
                            }}
                          >
                            <DeleteIcon />
                            <span className="fsize-10  toggle-text">
                              {products.viewProduct.delete}
                            </span>
                          </div>
                        </div>
                        <div className="priceLabel text-white">
                          {product.price} {products.pound}
                        </div>
                      </div>
                    }
                    isOutOfStock={!product.is_in_stock}
                  />
                </div>
              ))}
            </div>

            <div className="d-flex justify-content-center align-items-center pt-4 pb-3">
              {meta.pages > 1 && (
                <Pagination
                  count={meta.pages}
                  page={meta.currentPage || page}
                  handleChange={(event, page) => {
                    setPage(page);
                  }}
                  defaultPage={1}
                />
              )}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pe-4 pb-3">
        <div className="fsize-18 font-bold ">
          {products.myProducts}
        </div>
        <div className="d-flex kuzlo-Products ">
          {list?.length > 0 && (
            <div className="mx-3 quick-edit-button">
              <Link to={ROUTE_PATHS.myProductsQuickedit}>
                <Button
                  label={
                    <span className="d-flex align-items-center">
                      <QuickEdit />
                      <span className="fsize-16 me-3">
                        {products.quickEdit.buttonText}
                      </span>
                    </span>
                  }
                  className="text-white py-2 px-4"
                />
              </Link>
            </div>
          )}

          <Filter
            placeholder={products.productStatus}
            options={productStatus}
            name="stock"
            onChange={(value) => {
              setStatus(value);
            }}
            value={status}
            inputClassName="mt-1 ms-3"
          />
          <Filter
            placeholder={products.filter}
            options={categoriesOptions}
            name="Category"
            onChange={(value) => {
              setCategory(value);
            }}
            value={category}
            inputClassName="mt-1 ms-3"
          />
          <SearchInput
            name="search"
            value={searchQuery}
            onChange={(value) => {
              setSearchQuery(value);
            }}
            placeholder={products.search}
          />
        </div>
      </div>
      <div className="content-wrapper p-0">
        <AlertNote
          alertText={general.noteOfChangingProductsWillAffectOffers}
          wrapperClasses="w-60 pt-4 mx-3"
        />
      </div>

      {List()}
      <Modal
        open={openEdit}
        onClose={handleCloseEditModal}
        modalBody={
          <AddEditProduct
            onClose={handleCloseEditModal}
            productData={product}
            page={page}
            query={searchValue}
            product_category_id={category?.id}
          />
        }
        modalLabel={
          <span className="modalLabel">
            {products.productCard.modalLabel}
          </span>
        }
      />
    </>
  );
};

export default MyProducts;
