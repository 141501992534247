import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Swiper } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import { Block } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import messages from "../../assets/locale/messages";
import "./ProductCard.scss";

SwiperCore.use([Navigation]);

const ProductCardTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.tooltip.backgroundGrey,
    color: theme.tooltip.backgroundBlue,
    fontSize: "1rem"
  },
  arrow: {
    color: theme.tooltip.backgroundGrey,
    fontSize: "1rem"
  }
}))(Tooltip);

const ProductCard = ({
  prdImgs,
  prdNameParent,
  prdNameChild,
  prdCategory,
  prdDescription,
  prdSpecifications,
  actions,
  actionsElement,
  isOutOfStock,
  initialSlide,
  prdPrice,
  prdQuent,
  viewProduct,
  deactivated,
  hasOffer,
  handleViewProduct,
  insideWholesalerProducts,
  orderProductHasOffer,
  product
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { products, general } = messages[lang];

  const renderSpecs = (specsArray) =>
    specsArray?.map((spec, index) =>
      spec?.length ? (
        <span key={index} className="spec-label  mb-1 px-2 py-1">
          {spec}
        </span>
      ) : null
    );

  return (
    <div className="product-card pt-4">
      {viewProduct && (
        <div className="view-product">{viewProduct}</div>
      )}
      <div className="px-3 pb-4">
        <Swiper
          className="mb-2 item-swiper"
          navigation
          slidesPerView={1}
          initialSlide={initialSlide}
        >
          {prdImgs}
          {hasOffer && (
            <div className="offer-flag">{general.offers}</div>
          )}

          {orderProductHasOffer && (
            <div className="offer-flag">{general.offer}</div>
          )}
        </Swiper>
        <div className="text-dark-blue  fsize-14 item-name w-75 m-auto">
          <span
            className={`font-medium ${
              !viewProduct &&
              insideWholesalerProducts &&
              "product-title-style"
            }`}
            onClick={() => {
              !viewProduct &&
                insideWholesalerProducts &&
                handleViewProduct();
            }}
          >
            {prdNameParent.length > 20 ? (
              <ProductCardTooltip
                title={
                  <div className="Tooltip-desc">{prdNameParent}</div>
                }
                placement="left"
                arrow
              >
                <span>{prdNameParent.substring(0, 20) + "..."}</span>
              </ProductCardTooltip>
            ) : (
              prdNameParent
            )}
          </span>
        </div>
        <div className="text-dark-blue font-cairo-medium fsize-14 my-1 item-name row">
          {prdNameChild}
        </div>

        <div className="mt-2 row align-items-center">
          <div className=" fsize-10 col-3 ps-0 text-product-card-dark font-light">
            {prdDescription?.length < 40 ? (
              products.productCard.description
            ) : (
              <ProductCardTooltip
                title={
                  <div className="Tooltip-desc">{prdDescription}</div>
                }
                placement="left"
                arrow
              >
                <span>{products.productCard.description}</span>
              </ProductCardTooltip>
            )}
          </div>
          <div className=" fsize-12 col-9 pe-0 text-product-card font-light ">
            {!prdDescription || prdDescription?.length == 0
              ? products.productCard.noDescription
              : prdDescription?.length < 40
              ? prdDescription
              : prdDescription?.substring(0, 40) + "..."}
          </div>
        </div>

        {prdCategory && (
          <div className="mt-1 fsize-10  row font-cairo-regular">
            <div className="col-3 ps-0 text-product-card-dark font-light">
              {prdCategory?.length < 40 ? (
                products.productCard.Category
              ) : (
                <ProductCardTooltip
                  title={
                    <div className="Tooltip-desc">{prdCategory}</div>
                  }
                  placement="left"
                  arrow
                >
                  <span>{products.productCard.Category}</span>
                </ProductCardTooltip>
              )}
            </div>
            <div className=" col-9 pe-0 text-dark-blue">
              {prdCategory?.length < 40
                ? prdCategory
                : prdCategory?.substring(0, 40) + "..."}
            </div>
          </div>
        )}

        {prdPrice && (
          <div className="mt-2 font-cairo-regular d-flex justify-content-between align-items-center ps-3 text-product-card-dark">
            <div className=" fsize-14  ps-0 text-product-card-text font-light">
              {products.productCard.productPrice}
            </div>
            <div className="pe-0 d-flex err-asterisk fsize-14 price-dir font-bold">
              {orderProductHasOffer && (
                <div className="direction-ltr">
                  <span>{product.new_price_per_product}</span>
                  <span className="me-3"> le</span>
                </div>
              )}
              <div
                className={`${
                  orderProductHasOffer &&
                  "font-cairo-regular  old-price-style"
                }`}
              >
                <span>{prdPrice}</span>
              </div>
            </div>
          </div>
        )}
        {prdQuent && (
          <div className="mt-2 font-cairo-regular d-flex justify-content-between align-items-center ps-3 text-product-card-dark ">
            <div className=" fsize-14  ps-0 text-product-card-text font-light">
              {products.productCard.quantity}
            </div>
            <div className=" pe-0 product-card-text fsize-14 font-bold">
              {prdQuent}
            </div>
          </div>
        )}
        <div className="my-2  font-cairo-regular ">
          <div className=" ps-0 d-flex fsize-10  flex-wrap text-product-card">
            {renderSpecs(prdSpecifications)}
          </div>
        </div>
      </div>
      {actions && <div className="mt-2">{actionsElement}</div>}
      {deactivated && (
        <div className="deactivated-icon">
          <Block color="primary" fontSize="large" />
        </div>
      )}
      {isOutOfStock && (
        <div className="outOfStock font-bold ">
          {products.productCard.outOfStock}
        </div>
      )}
    </div>
  );
};

ProductCard.propTypes = {
  initialSlide: PropTypes.number,
  prdImgs: PropTypes.array,
  prdNameParent: PropTypes.string,
  prdNameChild: PropTypes.string,
  prdCategory: PropTypes.string,
  prdDescription: PropTypes.string,
  prdSpecifications: PropTypes.array,
  actions: PropTypes.bool,
  isOutOfStock: PropTypes.bool,
  actionsElement: PropTypes.element,
  prdPrice: PropTypes.string,
  prdQuent: PropTypes.string,
  viewProduct: PropTypes.element,
  deactivated: PropTypes.bool,
  hasOffer: PropTypes.bool,
  handleViewProduct: PropTypes.func,
  insideWholesalerProducts: PropTypes.bool,
  orderProductHasOffer: PropTypes.bool,
  product: PropTypes.object
};

export default ProductCard;
