import React from "react";

export const EgyptFlag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    fill="none"
    viewBox="0 0 36 36"
    {...props}
  >
    <g clipPath="url(#clip0_382_13235)">
      <path
        fill="#141414"
        d="M36 27a4 4 0 01-4 4H4a4 4 0 01-4-4V9a4 4 0 014-4h28a4 4 0 014 4v18z"
      ></path>
      <path fill="#EEE" d="M0 13h36v10H0V13z"></path>
      <path
        fill="#CE1225"
        d="M32 5H4a4 4 0 00-4 4v4h36V9a4 4 0 00-4-4z"
      ></path>
      <path
        fill="#BF9300"
        d="M14.75 21.562s.016.25.234.375c0 0-.062.188.172.297.234.109 1.078.28 2.547.28s2.297-.155 2.516-.265c.219-.11.234-.36.234-.36s.234-.124.219-.28c-.016-.156-.328-.328-.328-.328s-.078-.203-.297-.281c-.219-.078-.922.344-2.266.28-1.344-.061-2.109-.265-2.359-.25-.25.017-.359.267-.359.267s-.282.125-.313.265z"
      ></path>
      <path
        fill="#EEE"
        d="M14.922 21.547c-.013.093.188.297.188.297s0 .234.204.297c.203.063 1.03.22 2.375.203 1.343-.016 2.296-.094 2.405-.188.11-.094.203-.297.203-.297s.22-.156.203-.28c-.015-.126-.328-.204-.328-.204s-.088-.188-.192-.266c-.104-.078-.776.312-2.213.312-1.407 0-2.22-.344-2.36-.328-.14.016-.233.281-.233.281s-.236.064-.252.173z"
      ></path>
      <path
        fill="#BF9300"
        d="M20.858 15.484s-.03-.734-.703-.641c-.672.094-.719.422-1.438.5l-.148.014a3.194 3.194 0 01-.196-1.014c0-.47.141-.672-.344-.906-.484-.234-.578-.016-.578-.016s-.375-.188-.547-.047c-.172.14-.078.5 0 .39.078-.108.391.204.391.204.106.519-.195 1.08-.4 1.394-.072-.007-.129-.01-.209-.02-.719-.077-.766-.405-1.438-.5-.672-.093-.703.642-.703.642l-.266 5.672.547-.422.003-.067 1.138-1.011.19-.19-.47 1.377s-.72-.047-.517.53c0 0 .11-.327.297-.265.188.062.734.203.734.203l.188.297.234-.22.734-.03s.234.062.22.266a.446.446 0 00.036-.283l.221-.015a.445.445 0 00.034.299c-.016-.203.22-.266.22-.266l.733.03.235.22.188-.297s.546-.141.733-.203c.187-.062.297.266.297.266.203-.578-.516-.531-.516-.531l-.449-1.313.126.126 1.138 1.01.003.068.547.422-.265-5.673zm-3.107 3.438c-1.127-.696-1.22-2.453-1.22-2.453s.697-.036 1.203-.391c.456.405 1.234.359 1.234.359s-.053 1.764-1.217 2.485zm2.576-3.656l-.953.906-.16.064c-.164.006-.962.008-1.465-.44-.625.439-1.484.47-1.484.47l-.133-.053-1.055-.947-.277.114c.012-.113.074-.367.418-.317.564.08.797.39 1.391.453l.168.017-.059.076c.092 0 .228-.027.328-.05l.113.012-.05.178c.08-.024.195-.093.29-.154l.26.025.06.082.197-.1.133-.012c.115.067.28.127.28.127l.03-.156c.141.094.375.094.375.094-.027-.022-.054-.078-.082-.126l.144-.015c.594-.062.826-.372 1.391-.453.344-.05.406.204.418.317l-.278-.112z"
      ></path>
      <path
        fill="#EEE"
        d="M17.375 18.89l-.563 2.14-.937-.186.969-2.75.531.797zm.699 0l.562 2.14.938-.186-.969-2.75-.531.797z"
      ></path>
      <path
        fill="#BF9300"
        d="M16.766 16.64s.078.907.484 1.61v-1.813s-.266.156-.484.203zm1.953.032s-.078.905-.484 1.608v-1.812c-.001 0 .265.157.484.204z"
      ></path>
      <path
        fill="#EEE"
        d="M16.953 13.578s.11-.109.422.109c.203.142.383.25.383.25s.128-.135.316-.104c.188.031.083.292.114.838.031.547.25.781.25.781l-.266-.156.016.172-.297-.141-.14.203-.142-.219-.228.139-.069-.186-.266.141s.391-.484.422-1.016c.01-.159-.03-.516-.03-.516s-.25-.311-.485-.295z"
      ></path>
      <path
        fill="#BF9300"
        d="M15.547 21.656c-.179.107-.109.172 0 .219.11.047.765.126 2.094.156 1.36.03 2.203-.125 2.312-.188.11-.062.125-.172-.062-.203-.188-.031-1.125.125-2.266.125-1.406 0-2-.156-2.078-.11z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_382_13235">
        <path fill="#fff" d="M0 0H36V36H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
