import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import messages from "../../../../../assets/locale/messages";
import {
  offerActiveStatus,
  offerPendingStatus
} from "../../../../../utils/Constants";
import { formatDate } from "../../../../../utils/Helpers";
import "./AppliedOffersCard.scss";

const AppliedOffersCard = ({ offer }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { general, offers } = messages[lang];

  const checkOfferStatus = (status) => {
    if (offerActiveStatus.includes(status)) {
      return "active";
    } else if (offerPendingStatus.includes(status)) {
      return "pending";
    }
  };

  return (
    <div className="applied-offer-card-container">
      <div className="row mx-0">
        <div className="col-12 px-0">
          <div className="offer-card">
            <div className="d-flex flex-column justify-content-center align-items-start me-4">
              <div
                className={`status-${checkOfferStatus(
                  offer.status
                )} status-general px-3 py-2 border-radius-4 text-capitalize fsize-16 text-color-blue `}
              >
                {offerActiveStatus.includes(offer.status)
                  ? offers.active
                  : offers.pending}
              </div>
              {!offer.is_in_stock && (
                <div className="out-of-stock-label">
                  {general.outStock}
                </div>
              )}
            </div>
            <div className="d-flex flex-column me-5 offer-value-text">
              <span className="fsize-12 value-title">
                {general.offerValue}
              </span>
              <div className="fsize-30 value-number">
                <span className="mx-1">{offer.discount_value}</span>
                {offer.discount_type === "amount" ? general.le : "%"}
              </div>
            </div>
            <div className="d-flex flex-column mx-4 applied-offers-dates">
              <div className="d-flex justify-content-between align-items-baseline start-date mb-3">
                <span className="ms-3 fsize-12 date-text">
                  {general.startDate}
                </span>
                <span className="date-value">
                  {formatDate(
                    offer.start_datetime,
                    "DD/MM/YYYY",
                    "en"
                  )}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-baseline end-date">
                <span className="ms-3 fsize-12 date-text">
                  {general.endDate}
                </span>
                <span className="date-value">
                  {formatDate(offer.end_datetime, "DD/MM/YYYY", "en")}
                </span>
              </div>
            </div>

            <div className="d-flex flex-column me-4 applied-offers-dates">
              <div className="d-flex justify-content-between align-items-baseline start-date mb-3">
                <span className="ms-3 fsize-12 date-text">
                  {general.startTime}
                </span>
                <span className="date-value">
                  {formatDate(offer.start_datetime, "hh:mm A", "en")}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-baseline end-date">
                <span className="ms-3 fsize-12 date-text">
                  {general.endTime}
                </span>
                <span className="date-value">
                  {formatDate(offer.end_datetime, "hh:mm A", "en")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppliedOffersCard;

AppliedOffersCard.propTypes = {
  offer: PropTypes.object
};
