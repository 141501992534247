import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { browserName } from "react-device-detect";

import AuthLayout from "../../../components/AuthLayout";
import messages from "../../../assets/locale/messages";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import {
  setPasswordRequest,
  resendEmailVerification
} from "../../../store/Auth/actions";
import { generateDeviceId } from "./../../../utils/Helpers";
import { getFirebaseToken } from "../../../firebase/helpers";

const SetPassword = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { auth } = messages[lang];
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");

  const handleResendEmailVerification = () => {
    dispatch(resendEmailVerification({ email }));
  };
  useEffect(() => {
    // ====== this is the right code to be implemented after the fix from BE ======
    // const query = new URLSearchParams(location.search);
    // setEmail(query.get("email"));
    // setToken(query.get("token"));

    // ====== workaround till fixed from BE =======
    const query = location.search.split("?")[1].split("&");
    for (let i = 0; i < query.length; i++) {
      let pairs = query[i].split("=");
      if (pairs[0] === "email") {
        setEmail(pairs[1]);
      } else if (pairs[0] === "token") {
        setToken(pairs[1]);
      }
    }
  }, [location, setEmail, setToken]);

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(auth.validations.password)
        .matches(
          /^[a-zA-Z0-9!@#$&()\\-`.+,/]{8,}$/,
          auth.validations.passwordMinLength
        ),
      confirmPassword: Yup.string()
        .required(auth.validations.password)
        .matches(
          /^[a-zA-Z0-9!@#$&()\\-`.+,/]{8,}$/,
          auth.validations.passwordMinLength
        )
        .oneOf(
          [Yup.ref("password"), null],
          auth.validations.passwordMatch
        )
    }),
    onSubmit: ({ password }) => {
      const pagePath = location.pathname;
      dispatch(
        setPasswordRequest({
          password,
          email,
          [pagePath === "/password/set"
            ? "forgot_password_token"
            : "email_verification_token"]: token,
          device_id: `${browserName}-${generateDeviceId()}`,
          fcm_token: getFirebaseToken()
        })
      );
    }
  });

  return (
    <AuthLayout>
      <form className="form-auth" onSubmit={handleSubmit} noValidate>
        <Input
          id="password"
          label={auth.labels.newPassword}
          labelAdornment={
            <span className="me-2 text-gray-light fsize-12 font-regular">
              {auth.labels.newPasswordHint}
            </span>
          }
          name="password"
          type="password"
          placeholder={auth.placeholders.newPassword}
          onChange={(value) => {
            setFieldTouched("password");
            setFieldValue("password", value.trim());
          }}
          value={values["password"]}
          inputClass="mb-1"
          labelClassName="font-medium mb-2"
          inputWrapperClass="mt-4"
          isInputHasErr={
            !!(touched["password"] && errors["password"])
          }
          errMsg={errors["password"]}
        />
        <Input
          id="confirmPassword"
          label={auth.labels.confirmPassword}
          name="confirmPassword"
          type="password"
          placeholder={auth.placeholders.newPassword}
          onChange={(value) => {
            setFieldTouched("confirmPassword");
            setFieldValue("confirmPassword", value.trim());
          }}
          value={values["confirmPassword"]}
          inputClass="mb-1"
          labelClassName="font-medium mb-2"
          inputWrapperClass="mt-4 mb-5"
          isInputHasErr={
            !!(
              touched["confirmPassword"] && errors["confirmPassword"]
            )
          }
          errMsg={errors["confirmPassword"]}
        />

        <Button
          label={auth.labels.confirm}
          type="submit"
          labelClass="fsize-14 text-white py-1"
          className="mb-4"
          block
          disabled={!dirty || !isValid}
        />
      </form>

      {location.pathname == ROUTE_PATHS["verifyAccount"] && (
        <div className="d-flex align-items-center justify-content-between">
          <p>{auth.resendVerification}</p>
          <p
            className="resend-email pointer "
            onClick={() => {
              handleResendEmailVerification();
            }}
          >
            {auth.url}
          </p>
        </div>
      )}
    </AuthLayout>
  );
};

export default SetPassword;
