import { put, call, takeEvery } from "redux-saga/effects";

import * as apis from "../../network/apis/auth";
import * as types from "./types";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { loginResponse } from "./actions";
import { setWholesaler, clearWholesalerData } from "./helpers";
import history from "../../routes/History";
import { showHideSnackbar } from "./../Snackbar/actions";
import {
  clearFirebaseToken,
  generateFirebaseToken
} from "../../firebase/helpers";
import messages from "../../assets/locale/messages";

export function* loginSaga({ payload }) {
  try {
    const response = yield call(apis.login, payload.data);
    const device_id = payload.data.device_id;
    yield put(loginResponse(response.data.wholesaler));
    yield payload.rememberMe
      ? setWholesaler(response.data, "local")
      : setWholesaler(response.data, "session");
    yield localStorage.setItem("device_id", device_id);
    yield history.push(ROUTE_PATHS["warehouse"]);
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}
export function* logoutSaga({ payload }) {
  try {
    yield call(apis.logout, payload);
    yield put(loginResponse({}));
    yield clearWholesalerData();
    yield clearFirebaseToken();
    yield generateFirebaseToken();
    history.push(ROUTE_PATHS["login"]);
  } catch (err) {
    yield clearWholesalerData();
    history.push(ROUTE_PATHS["login"]);
  }
}

export function* forgotPasswordSaga({ payload }) {
  try {
    yield call(apis.forgotPassword, payload);
    history.push(ROUTE_PATHS["checkEmail"]);
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export function* setPasswordSaga({ payload }) {
  try {
    const response = yield call(apis.setPassword, payload);
    const device_id = payload.device_id;
    const lang = localStorage.getItem("lang");
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[lang].setPasswordSuccessMsg
      })
    );
    yield put(loginResponse(response.data.wholesaler));
    yield localStorage.setItem("device_id", device_id);
    yield setWholesaler(response.data, "local");
    yield history.push(ROUTE_PATHS["warehouse"]);
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export function* resendEmailVerifcation({ payload }) {
  try {
    const response = yield call(
      apis.resendEmailVerification,
      payload
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: response.data.message
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export default function* AuthSagas() {
  yield takeEvery(types.LOGIN_REQUEST, loginSaga);
  yield takeEvery(types.LOGOUT_REQUEST, logoutSaga);
  yield takeEvery(types.FORGOT_PASSWORD_REQUEST, forgotPasswordSaga);
  yield takeEvery(types.SET_PASSWORD_REQUEST, setPasswordSaga);
  yield takeEvery(
    types.RESEND_EMAIL_VERIFICATION_REQUEST,
    resendEmailVerifcation
  );
}
