export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_RESPONSE = "GET_ORDERS_RESPONSE";

export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_ORDER_DETAILS_RESPONSE =
  "GET_ORDER_DETAILS_RESPONSE";

export const GET_RETAILERS_REQUEST = "GET_RETAILERS_REQUEST";
export const GET_RETAILERS_RESPONSE = "GET_RETAILERS_RESPONSE";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";

export const GET_ORDER_PDF_REQUEST = "GET_ORDER_PDF_REQUEST";
export const GET_ORDER_PDF_RESPONSE = "GET_ORDER_PDF_RESPONSE";
