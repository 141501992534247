import { call, put, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/warehouse";
import * as actions from "./actions";
import { showHideSnackbar } from "../Snackbar/actions";
import {
  EDIT_WAREHOUSE_REQUEST,
  VIEW_WAREHOUSE_REQUEST
} from "./types";
import history from "../../routes/History";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import messages from "../../assets/locale/messages";

function* viewWarehouseRequest({ payload }) {
  try {
    const response = yield call(apis.viewWarehouse, payload);
    yield put(actions.viewWarehouseResponse(response.data.warehouse));
  } catch (error) {
    console.log("Error", error);
  }
}

function* editWarehouseRequest({ payload }) {
  try {
    yield call(apis.editWarehouse, payload);
    const lang = localStorage.getItem("lang") || "ar";
    yield put(
      showHideSnackbar({
        isOpen: true,
        message: messages[lang].warehouse.warehouseUpdatedSuccessMsg,
        type: "success"
      })
    );
    yield history.push(ROUTE_PATHS.warehouse);
  } catch (error) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        message: error.response?.data?.message,
        type: "error"
      })
    );
  }
}

export default function* WarehouseSagas() {
  yield takeEvery(VIEW_WAREHOUSE_REQUEST, viewWarehouseRequest);
  yield takeEvery(EDIT_WAREHOUSE_REQUEST, editWarehouseRequest);
}
