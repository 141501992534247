import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SwiperSlide } from "swiper/react";
import { Add } from "@material-ui/icons";
import debounce from "lodash.debounce";

import messages from "../../../assets/locale/messages";
import SearchInput from "../../../components/SearchInput";
import Filter from "../../../components/Filter";
import ProductCard from "../../../components/ProductCard";
import Button from "../../../components/Button";
import Pagination from "../../../components/Pagination";
import AddEditProduct from "../../../components/AddEditProduct";
import Modal from "../../../components/Modal";
import EmptyState from "../../../components/EmptyState";
import {
  getKuzloProductsRequest,
  getCategoriesRequest
} from "../../../store/KuzloProducts/actions";
import "./KuzloProducts.scss";

const KuzloProducts = () => {
  const lang = useSelector((state) => state.locale.lang);
  const list = useSelector((state) => state.kuzloProducts.list);
  const meta = useSelector((state) => state.kuzloProducts.meta);
  const categories = useSelector(
    (state) => state.kuzloProducts.categories
  );
  const dispatch = useDispatch();
  const { products } = messages[lang];
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [category, setCategory] = useState(null);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [productId, setProductId] = useState();
  const [page, setPage] = useState(1);

  const productImagesSlides = (imagesArray) => {
    let slides = [];
    if (imagesArray.length) {
      slides = imagesArray.map((image) => (
        <SwiperSlide key={image.id}>
          <div
            className="product-card-image-container"
            style={{ backgroundImage: `url(${image.url})` }}
          ></div>
        </SwiperSlide>
      ));
    } else {
      slides.push(
        <SwiperSlide key={1}>
          <div
            className="product-card-image-container product-card-image-container-empty"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_PRODUCTS_PLACEHOLDER})`
            }}
          ></div>
        </SwiperSlide>
      );
    }
    return slides;
  };

  const getKuzloProducts = (pageNumber, search, categotyId) => {
    dispatch(
      getKuzloProductsRequest({
        page: pageNumber,
        items: 12,
        name: search,
        product_category_id: categotyId || undefined
      })
    );
  };

  const handleAdd = (id) => {
    setProductId(id);
    setOpenAdd(true);
  };

  const handleCloseAddModal = () => {
    setOpenAdd(false);
  };

  const updateSearchQuery = () => {
    setSearchValue(searchQuery);
  };
  const delayedSearchQuery = useCallback(
    debounce(updateSearchQuery, 500),
    [searchQuery]
  );
  useEffect(() => {
    delayedSearchQuery();
    // Cancel the debounce on useEffect cleanup.
    return delayedSearchQuery.cancel;
  }, [searchQuery, delayedSearchQuery]);

  useEffect(() => {
    getKuzloProducts(page, searchValue, category?.id);
  }, [page]);

  useEffect(() => {
    if (meta) {
      setPage(meta.currentPage);
    }
  }, [meta]);

  useEffect(() => {
    if (category) {
      getKuzloProducts(1, searchValue, category?.id);
    } else {
      getKuzloProducts(1, searchValue);
    }
  }, [category]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      getKuzloProducts(1, searchValue, category?.id);
    }
  }, [searchValue]);

  useEffect(() => {
    dispatch(
      getCategoriesRequest({
        page: -1,
        items: 1
      })
    );
  }, []);

  useEffect(() => {
    const options = categories.map((category) => ({
      id: category.id,
      label: category.name
    }));
    setCategoriesOptions(options);
  }, [categories]);

  const renderContent = () => {
    if (list.length == 0 && (searchValue || category)) {
      return <EmptyState subTitle={products.emptySearch} />;
    } else {
      return (
        <>
          <div className="row">
            {list.map((product, index) => (
              <div className="col-3 mb-3" key={index}>
                <ProductCard
                  prdImgs={productImagesSlides(product.images)}
                  initialSlide={product.images?.findIndex(
                    (image) => image.is_default
                  )}
                  prdDescription={product.description}
                  prdCategory={
                    product.super_product.product_category.name
                  }
                  prdNameParent={product.concatenated_name}
                  prdSpecifications={product.specifications}
                  actions={true}
                  actionsElement={
                    <div className="add-btn-product">
                      <Button
                        label={
                          <span className="d-flex align-items-center">
                            <Add fontSize="medium" />
                            <span className="ps-1">
                              {products.productCard.label}
                            </span>
                          </span>
                        }
                        labelClass="fsize-14  py-1"
                        className="bg-white productCardBtn  "
                        block
                        outlined
                        onClick={() => {
                          handleAdd(product.id);
                        }}
                      />
                    </div>
                  }
                />
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center align-items-center pt-4 pb-3">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pe-4 pb-3">
        <div className="fsize-18 font-bold ">
          {products.serviceProviderProducts}
        </div>
        <div className="d-flex kuzlo-Products ">
          <Filter
            placeholder={products.filter}
            options={categoriesOptions}
            name="Category"
            onChange={(value) => {
              setCategory(value);
            }}
            value={category}
            inputClassName="mt-1 ms-3"
          />
          <SearchInput
            name="search"
            value={searchQuery}
            onChange={(value) => {
              setSearchQuery(value);
            }}
            placeholder={products.search}
          />
        </div>
      </div>
      <div className="content-wrapper">{renderContent()}</div>
      <Modal
        open={openAdd}
        onClose={handleCloseAddModal}
        modalBody={
          <AddEditProduct
            productId={productId}
            onClose={handleCloseAddModal}
            page={page}
            query={searchValue}
            product_category_id={category?.id}
          />
        }
        modalLabel={
          <span className="modalLabel fsize-16">
            {products.productCard.modalLabel}
          </span>
        }
      />
    </>
  );
};

export default KuzloProducts;
