import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import AuthLayout from "../../../components/AuthLayout";
import messages from "../../../assets/locale/messages";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { forgotPasswordRequest } from "./../../../store/Auth/actions";
import "./ForgotPassword.scss";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { auth } = messages[lang];

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(auth.validations.validEmail)
        .required(auth.validations.email)
    }),
    onSubmit: ({ email }) => {
      dispatch(forgotPasswordRequest({ email }));
    }
  });

  return (
    <AuthLayout>
      <p className="subTitle mb-3 fsize-14 font-medium">
        {auth.subTitle}
      </p>
      <div className="d-flex flex-column align-items-center">
        <form
          className="form-auth"
          onSubmit={handleSubmit}
          noValidate
        >
          <Input
            id="email"
            label={auth.labels.email}
            name="email"
            type="email"
            placeholder={auth.placeholders.email}
            onChange={(value) => {
              setFieldTouched("email");
              setFieldValue("email", value.trim());
            }}
            value={values["email"]}
            inputClass="mb-1"
            labelClassName="font-medium mb-2"
            inputWrapperClass="my-4"
            isInputHasErr={!!(touched["email"] && errors["email"])}
            errMsg={errors["email"]}
          />
          <Button
            label={auth.labels.confirm}
            type="submit"
            labelClass="fsize-14 text-white py-1"
            className="mb-4"
            block
            disabled={!dirty || !isValid}
          />
        </form>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
