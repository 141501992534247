import S3 from "react-aws-s3";
import store from "../store";
import { showHideLoader } from "../store/Loader/actions";

export let CONFIG = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: "",
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  s3Url: process.env.REACT_APP_S3URL
};

// add your S3 bucket directory name here
export const directories = {
  wholesaler_portal: "wholesaler_portal"
};

export const uploadToS3 = async (file, fileName, directoryName) => {
  store.dispatch(showHideLoader(true));
  CONFIG.dirName = directoryName;
  const S3Client = new S3(CONFIG);
  if (file) {
    return await S3Client.uploadFile(file, fileName)
      .then((data) => {
        store.dispatch(showHideLoader(false));
        return { link: data.location };
      })
      .catch((err) => {
        store.dispatch(showHideLoader(false));
        console.error(err);
      });
  }
};
