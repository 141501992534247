import {
  EDIT_PROFILE_REQUEST,
  VIEW_PROFILE_REQUEST,
  VIEW_PROFILE_RESPONSE,
  GET_DELIVERY_METHODS_REQUEST,
  GET_DELIVERY_METHODS_RESPONSE
} from "./types";

export const viewProfileRequest = (payload) => ({
  type: VIEW_PROFILE_REQUEST,
  payload
});

export const viewProfileResponse = (payload) => ({
  type: VIEW_PROFILE_RESPONSE,
  payload
});

export const editProfileRequest = (payload) => ({
  type: EDIT_PROFILE_REQUEST,
  payload
});

export const getDeliveryMethodsRequest = (payload) => ({
  type: GET_DELIVERY_METHODS_REQUEST,
  payload
});
export const getDeliveryMethodsResponse = (payload) => ({
  type: GET_DELIVERY_METHODS_RESPONSE,
  payload
});
