import * as types from "./types";

const INITIAL_STATE = {
  showToast: false,
  title: "",
  body: "",
  id: null,
  action_id: null,
  click_action: null,
  seen: false
};

const Toast = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SHOW_HIDE_TOAST:
      return {
        ...state,
        showToast: action.payload.isOpen,
        title: action.payload.title,
        body: action.payload.body,
        action_id: action.payload.action_id,
        click_action: action.payload.click_action,
        seen: action.payload.seen
      };
    default:
      return state;
  }
};

export default Toast;
