import React from "react";
import PropTypes from "prop-types";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

import { Info } from "../../utils/Icons/Info";
import "./AlertNote.scss";

const AlertNote = ({ alertText, wrapperClasses }) => {
  const [open, setOpen] = React.useState(true);

  return (
    <div
      className={`alert-note-wrapper ${open ? wrapperClasses : ""}`}
    >
      <Collapse in={open}>
        <Alert
          iconMapping={{
            success: <Info fontSize="inherit" />
          }}
          className="alert-class"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {alertText}
        </Alert>
      </Collapse>
    </div>
  );
};

export default AlertNote;

AlertNote.propTypes = {
  alertText: PropTypes.string,
  wrapperClasses: PropTypes.string
};
