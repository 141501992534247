import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

import messages from "../../assets/locale/messages";
import SearchInput from "../../components/SearchInput";
import "./SearchMap.scss";

const SearchMap = ({ onSearchMap }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { warehouse } = messages[lang];

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300
  });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleChange = (e) => {
    setValue(e);
  };

  const handleSelectSuggestion =
    ({ description }) =>
    () => {
      setValue(description, false);
      clearSuggestions();

      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          onSearchMap(lat, lng);
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={handleSelectSuggestion(suggestion)}
          className="pointer search-option py-2 px-3"
        >
          <span className="font-bold">{main_text},</span>
          <span className="mx-1">{secondary_text}</span>
        </li>
      );
    });

  return (
    <div ref={ref} className="pb-4 map-search-input-wrapper">
      <SearchInput
        name="search-map"
        value={value}
        disabled={!ready}
        onChange={handleChange}
        type="string"
        placeholder={warehouse.searchMapPlaceholder}
        inputClass="search-map-input mb-1"
      />

      {status === "OK" && (
        <div className="search-options-wrapper">
          <ul className="search-options-list">
            {renderSuggestions()}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchMap;

SearchMap.propTypes = {
  onSearchMap: PropTypes.func
};
