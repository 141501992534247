import React from "react";

export const ClockIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#AAA7A7"
      fillRule="evenodd"
      d="M9.99 0C4.47 0 0 4.48 0 10s4.47 10 9.99 10C15.52 20 20 15.52 20 10S15.52 0 9.99 0zM10 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-1-6.93l5.49 3.29 1.02-1.72L11 9.93V4.42H9v6.65z"
      clipRule="evenodd"
    ></path>
  </svg>
);
