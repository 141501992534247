export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_RESPONSE =
  "GET_NOTIFICATIONS_RESPONSE";

export const SET_NOTIFICATION_SEEN = "SET_NOTIFICATION_SEEN";

export const GET_ALL_NOTIFICATIONS_REQUEST =
  "GET_ALL_NOTIFICATIONS_REQUEST";
export const GET_ALL_NOTIFICATIONS_RESPONSE =
  "GET_ALL_NOTIFICATIONS_RESPONSE";

export const ADD_PUSH_NOTIFICATION = "ADD_PUSH_NOTIFICATION";
