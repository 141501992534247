import { notificationsService } from "../utils/NotificationsRxjs";
import { requestFirbaseNotificationPermission } from "./firebase";
import store from "../store";
import { addPushNotification } from "../store/Notifications/actions";
import { showHideToast } from "../store/ToastMessage/actions";

export const setFirebaseToken = (token) => {
  localStorage.setItem("fcmToken", token);
};

export const getFirebaseToken = () => {
  return localStorage.getItem("fcmToken");
};

export const clearFirebaseToken = () => {
  localStorage.removeItem("fcmToken");
};

export const generateFirebaseToken = () => {
  requestFirbaseNotificationPermission()
    .then((firebaseToken) => {
      setFirebaseToken(firebaseToken);
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const subscribeOnPushNotifications = (lastNotificationId) => {
  const subscribtion = notificationsService
    .getNotification()
    .subscribe((notification) => {
      if (notification) {
        const {
          data,
          notification: { body, title }
        } = notification;
        const created_at = new Date();
        store.dispatch(
          showHideToast({
            id: lastNotificationId + 1 || null,
            isOpen: true,
            title,
            body,
            action_id: data?.action_id,
            click_action: data?.click_action,
            seen: false
          })
        );
        store.dispatch(
          addPushNotification({
            id: lastNotificationId + 1 || null,
            title,
            body,
            click_action: data?.click_action,
            action_id: data?.action_id,
            created_at,
            seen: false
          })
        );
      }
    });
  return subscribtion;
};
