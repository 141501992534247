import React from "react";

export const OrdersIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        fillOpacity="0.5"
        d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83v10.33C3 20.26 4.77 22 7.81 22h8.381C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2z"
        opacity="0.4"
      ></path>
      <path
        fill="#fff"
        fillOpacity="0.5"
        fillRule="evenodd"
        d="M8.08 6.65v.01a.78.78 0 000 1.56h2.989c.431 0 .781-.35.781-.79a.781.781 0 00-.781-.78H8.08zm7.84 6.09H8.08a.78.78 0 010-1.56h7.84a.781.781 0 010 1.561zm0 4.57H8.08c-.3.04-.59-.11-.75-.36a.795.795 0 01.75-1.21h7.84c.399.04.7.38.7.79 0 .4-.301.74-.7.78z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
