const kuzloAr = {
  navbar: {
    notifications: "الاشعارات",
    profile: "الملف الشخصي",
    search: "ابحث عن شئ",
    seeAll: "  مشاهدة جميع الاشعارات",
    markRead: "تحديد كمقروء",
    noNotifications: "لا توجد اشعارات إلي الآن"
  },
  general: {
    invalidDate: "تاريخ غير صحيح",
    le: "جنيه",
    showMore: "مشاهدة المزيد",
    emptySearch: "عذرا لا يوجد نتائج مطابقة للبحث",
    offers: "خصومات",
    offer: "خصم",
    offerValue: "قيمة العرض",
    startDate: "تاريخ البداية",
    endDate: "تاريخ الانتهاء",
    startTime: "وقت البداية",
    endTime: "وقت الانتهاء",
    outStock: "نفذ من المخزن",
    noteOfChangingProductsWillAffectOffers:
      "برجاء الانتباه انه عند حذف المنتج او تغيير سعره سوف يتم الغاء العروض المتاحه عليه تلقائيا"
  },
  products: {
    title: "المنتجات",
    myProducts: "منتجاتي",
    emptySearch: "عذرا لا يوجد نتائج مطابقة للبحث",
    pound: "le",
    productStatus: "حالة المنتج",
    serviceProviderProducts: "منتجات  كوزلو",
    filter: "التصنيفات",
    search: "البحث عن اسم منتج",
    inStock: "متوفر",
    outOfStock: "غير متوفر",
    productCard: {
      productPrice: "سعر المنتج",
      description: "الوصف",
      Category: "التصنيف",
      Related: "ذات صلة",
      modalLabel: "اضافه منتج",
      price: "السعر",
      pound: "جنيه",
      outOfStock: "نفذ من المخزن ",
      quantity: "الكمية المطلوبة",
      minQuantity: "الحد الادني للطلب",
      maxQuantity: "الحد الاقصي للطلب",
      modalButton: "حفظ في منتجاتي",
      label: "اضافه لمنتجاتي",
      successMessage:
        "تم اضافه المنتج بنجاح الي قائمه المنتجات الخاصه بك",
      noDescription: "لا يوجد",
      priceErrorReq: "يجب تحديد سعر المنتج (سعر الوحده)",
      priceErrorMin: "هناك خطأ في السعر (يجب أن يكون أكثر من 0)",
      pricrNumber: "برجاء ادخال سعرا صحيحا ",
      quantityError: "هناك خطأ في الكمية (يجب أن تكون 0 أو أكثر)",
      minError: "هناك خطأ في الحد الأدنى (يجب أن يكون أكثر من 0)",
      maxError: "هناك خطأ في الحد الأقصى (يجب أن يكون أكثر من 0)",
      minMaxError:
        "هناك خطأ - الحد الأقصى يجب أن يكون أكبر من الحد الأدنى"
    },
    emptyState: {
      subtitle: "لا يوجد منتجات لعرضها الي الان",
      description: "ابدء الان لاضافه منتجات من مخزن كوزلو",
      buttonLabel: "اضافه منتجات"
    },
    deleteProduct: {
      message: "هل تريد مسح هذا المنتج بشكل نهائي من منتجاتك؟",
      delete: "مسح",
      cancel: "إلغاء"
    },
    viewProduct: {
      productDetails: "تفاصيل المنتج",
      inStock: "متوفر ",
      outStock: " غير متوفر ",
      deleteProduct: "حذف المنتج",
      delete: "حذف ",
      editProduct: "تعديل",
      category: "التصنيف",
      pound: "جنيه",
      description: "الوصف",
      price: "السعر",
      originalPrice: "السعر الأصلي",
      quantity: "الكميه",
      minQuantity: "الحد الادني للطلب",
      maxQuantity: "الحد الاقصي للطلب"
    },
    editMessege: "تم التعديل بنجاح ",
    quickEdit: {
      buttonText: "تعديل سريع",
      editPrice: "تعديل السعر",
      convertUnavailable: "تحويل لغير متوفرة",
      convertAvailable: "تحويل لمتوفرة",
      delete: "حذف",
      selectedProducts: "المنتجات المحددة",
      thisItemsWillBeDeleted: "( سيتم حذف هذه المنتجات )",
      thisItemsWillBeAvailable: "( سيتم وضع هذه المنتجات  ك متوفرة )",
      thisItemsWillBeNotAvailable:
        "( سيتم وضع هذه المنتجات  ك غير متوفرة )",
      noResultFound: "لا يوجد نتائج",
      deleteProducts: "حذف المنتجات",
      convertToAvailable: "تحويل المنتجات لمتوفرة",
      convertToNotAvailable: "تحويل المنتجات لغير متوفرة",
      deleteConfirmationMsg:
        "هل تريد حذف هذه المنتجات بشكل نهائي من منتجاتك؟",
      editPricesConfirmationMsg: "هل تريد تعديل أسعار هذه المنتجات؟",
      convertToAvailableConfirmationMessage:
        "هل تريد تحويل هذه المنتجات لمنتجات متوفرة؟",
      convertToUnavailableConfirmationMessage:
        "هل تريد تحويل هذه المنتجات لمنتجات غير متوفرة؟",
      edit: "تعديل",
      cancel: "إلغاء",
      convert: "تحويل",
      discardChanges: "تجاهل التغييرات",
      successDeleteMsg: "تم حذف المنتجات بنجاح",
      successEditPricesMsg: "تم تعديل الأسعار بنجاح",
      convertInStockSuccess: "تم تحويل المنتجات لمتوفرة بنجاح",
      le: "جنيه",
      piceErrorMsg: "يجب إدخال سعر أكبر من 0",
      saveChanges: "حفظ التغييرات",
      navigationModalMessage: "هل تريد تجاهل التغييرات؟",
      tableTitles: {
        productName: "اسم المنتج",
        productCategory: "التصنيف",
        price: "السعر"
      }
    },
    appliedOffers: "العروض على المنتج"
  },
  auth: {
    login: "تسجيل الدخول",
    logout: "تسجيل الخروج",
    resendVerification:
      " اذا انتهي الزمن الخاص برابط التفعيل اضغط علي",
    url: "ارسال رابط جديد",
    welcome: "مرحبًا في",
    subTitle: "ادخل بريدك الالكتروني، لتغيير كلمةالمرورالخاصة بك",
    checkEmail:
      "برجاء الذهاب الى بريدك الالكتروني لاستكمال تغيير كلمة المرور",
    setPasswordSuccessMsg: "تم حفظ كلمة المرور بنجاح",
    labels: {
      email: "الايميل",
      password: "كلمة المرور",
      remember: "تذكرني",
      signin: "تسجيل الدخول",
      forgotPassword: "نسيت كلمة المرور؟",
      confirm: "تأكيد",
      newPassword: "كلمة المرور الجديدة",
      confirmPassword: "تكرار كلمة المرور الجديدة",
      newPasswordHint: "يجب ان تكون 8 احرف او ارقام على الاقل"
    },
    placeholders: {
      email: "ادخل البريد الإلكتروني",
      password: "ادخل كلمة المرور",
      newPassword: "أدخل كلمة المرور الجديدة"
    },
    validations: {
      email: "البريد الإلكتروني مطلوب",
      validEmail:
        "يجب أن يكون البريد الإلكتروني عنوان بريد إلكتروني صالحًا",
      password: "كلمة المرور مطلوبة",
      passwordMatch:
        "كلمة المرور و تكرار كلمة المرور يجب أن يتطابقان",
      passwordMinLength:
        "كلمة المرور يجب ان تكون 8 احرف او ارقام على الاقل"
    }
  },
  profile: {
    profile: "الملف الشخصي",
    name: "الاسم",
    email: "الايميل",
    mobile: "رقم الموبايل",
    address: "العنوان",
    phone: "رقم الموبايل",
    minOrderPrice: "الحد الأدنى للطلب",
    logo: "الشعار",
    edit: "تعديل",
    status: "الحالة",
    bankInfo: "بيانات البنك",
    bankName: "اسم البنك",
    branchName: "اسم الفرع",
    accountNumber: "رقم الحساب",
    ibanNumber: "رقم IBAN",
    incontactInfo: "بيانات الموظف",
    deliveryMethods: "طرق التوصيل",
    serviceProvider: "كوزلو",
    pickup: "Pick Up",
    direct: "توصيل مباشر",
    paymentMethods: "طرق الدفع",
    cash: "دفع مباشر",
    online: "دفع اونلاين",
    notVerified: "غير مفعل",
    verified: "مفعل",
    active: "نشط",
    deactivated: "غير نشط",
    published: "منشور",
    joiningDate: "تاريخ الانضمام",
    enterPlaceholder: "ادخل {{field}}",
    validations: {
      required: "{{field}} مطلوب",
      validEmail: "برجاء ادخال بريد الكتروني صحيح",
      validPhone: "برجاء ادخال رقم موبايل صحيح",
      validIban: "عفوا رقم الحساب المصرفي الدولي (IBAN) غير صحيح",
      delivery: "برجاء تحديد طرق التوصيل المتاحة",
      payment: "برجاء تحديد طرق الدفع المتاحة",
      arabic: "برجاء اخال البيانات باللغة العربية"
    },
    saveChanges: "حفظ التغييرات",
    successMsg: "تم تعديل الملف الشخصي بنجاح",
    uploadLabel: "تحميل"
  },
  warehouse: {
    title: "المخزن",
    warehouseDetails: "بيانات المخزن",
    name: "الاسم",
    location: "العنوان",
    coverageArea: "مناطق التوصيل",
    viewMap: "اظهار الخريطة",
    from: "من",
    to: "الي",
    edit: "تعديل",
    minOrderPrice: " الحد الادني للطلب",
    searchMapPlaceholder: "ابحث عن الموقع",
    warehouseLocation: "موقع المخزن",
    nameRequired: "الاسم مطلوب",
    nameValidation: "برجاءادخال الإسم باللغة العربيه",
    locationRequired: "العنوان مطلوب",
    enterName: "ادخل الاسم",
    saveChanges: "حفظ التغييرات",
    saveAndContinue: "حفظ واستكمال",
    pickLocationTitle: "قم باختيار وتحديد موقع المنطقة الخاصة بمخزنك",
    warehouseUpdatedSuccessMsg: "تم تحديث المخزن بنجاح",
    noWarehouseTitle: "لا يوجد الى الان مخزن خاص بك",
    noWarehouseDescription:
      "تواصل مع إدارة كوزلو من أجل إضافة المخزن",
    noCoverageAreas: "لايوجد مناطق تغطية بعد"
  },
  orders: {
    title: "الطلبات",
    searchPlaceholder: "رقم الطلب او تليفون تاجر التجزئة",
    retailerPlaceholder: "اسم متجر تاجر التجزئة",
    orderStatusPlaceholder: "حالة الطلب",
    notes: "ملاحظات",
    emptySearch: "عذرا لا يوجد نتائج مطابقة للبحث",
    startDate: "من ",
    endDate: " الي",
    onGoing: "الطلبات الجارية",
    history: "السجل",
    orderNumber: "رقم الطلب",
    warehouseName: "اسم المتجر",
    checkoutDate: "تاريخ الدفع",
    details: "مشاهدة التفاصيل",
    emptyState: "لا يوجد طلبات لعرضها الان",
    exportPdf: "استخراج PDF",
    filterOrder: {
      pending: "تحت المراجعة",
      in_progress: "جاري التحضير",
      out_for_delivery: "جاري التوصيل",
      delivered: "تم التوصيل",
      cancelled: "الغاء",
      rejected: " رفض"
    },
    viewOrder: {
      listOrders: "قائمة الطلبات",
      details: "تفاصيل الطلب",
      wholsaalerDetails: "بيانات تاجر التجزئة",
      name: "الاسم",
      warehouse: "المتجر",
      phone: "رقم الموبايل",
      deliveryAdress: "عنوان التوصيل",
      order: "الطلب",
      orderNumber: "رقم الطلبيه",
      orderValue: "قيمة اجمالي الطلبية",
      orderValueAfterDiscount: "قيمة اجمالي الطلبية بعد الخصم",
      deliveryMethod: "طريقة التوصيل ",
      paymentMethod: "طريقة الدفع ",
      expectedDaysTooltip:
        " لتغيير وقت التوصيل المتوقع برجاء التواصل مع كوزلو ادمن من خلال جهات التواصل",
      date: "تاريخ الدفع",
      currentStatus: "حالة الطلب الحالية",
      expectedDelivery: "وقت التوصيل المتوقع",
      orderProducts: "منتجات الطلبية",
      viewProducts: "مشاهدة كل المنتجات",
      productList: "قائمة منتجات الطلبية",
      totalCard: "الاجمالي",
      productName: "اسم المنتج",
      price: "سعر المنتج",
      quentity: "الكمية المطلوبة",
      le: "le",
      leA: "ج.م",
      minErrorMsg: "'تاريخ 'من' يجب أن يكون أقل من 'إلى",
      maxErrorMsg: "'تاريخ 'إلى' يجب أن يكون أكبر من 'من",
      day: "يوم",
      rejected: "تم الرفض",
      expectedDayPending: "سيتم تحديده مع تأكيد الطلب",
      editSuccess: "تم تعديل حالة الاوردر بنجاح",
      exportPdf: "تنزيل PDF",
      number: "رقم الطلب",
      orderStatus: "حالة الطلبية",
      deliveryDate: "تاريخ التوصيل",
      orderDate: "تاريخ الطلب",
      totalProducts: "عدد منتجات الطلبية",
      product: "منتج",
      productPrice: "سعر الوحدة",
      deliveryDays: "وقت التوصيل المتوقع",
      export: "  استخراج كملف PDF",
      download: "تحميل PDF",
      retailerLocation: "الموقع",
      shareLocation: "نشر الموقع",
      linkCopied: "تم نسخ الرابط بنجاح",
      appliedPromoCode: "كود الخصم",
      promoCodeDiscount: "قيمة الخصم"
    }
  },
  offers: {
    sideMenuTitle: "العروض",
    filterStatus: {
      expired: "منتهي",
      pending: "قيد الانتظار",
      active: "جاري الان"
    },
    filterSubStatus: {
      enabled: "مفعل",
      disabled: "غير مفعل"
    },
    emptyState: "لا يوجد عروض لعرضها الي الان",
    filtersPlaceHolders: {
      status: "حالة العرض",
      subStatus: "الحالة الفرعية"
    },
    searchPlaceHolder: "إسم المنتج",
    table: {
      headlines: {
        status: "الحالة",
        title: "الإسم",
        date: "التاريخ",
        time: "الوقت",
        view: "مشاهدة التفاصيل",
        enabled: "مفعل"
      }
    },
    outOfStock: "نفذ من المخزن",
    start: "من",
    end: "إلي",
    pending: "قيد الانتظار",
    active: "جاري الان",
    expired: "منتهي",
    viewOffer: {
      product: "المنتج",
      discountVlaue: "قيمة الخصم",
      amount: "قيمة نقدية",
      percentage: "نسبة مئوية",
      le: "جنيه",
      startDateAndTime: "تاريخ ووقت البداية",
      startDate: "تاريخ البداية",
      startTime: "وقت البداية",
      endDateAndTime: "تاريخ ووقت النهاية",
      endDate: "تاريخ النهاية",
      endTime: "وقت النهاية",
      description: "الوصف",
      category: "التصنيف",
      noProducts: "لا يوجد",
      prevProductOffersQoute: "يوجد عروض بالفعل على هذا المنتج",
      viewDetails: "مشاهدة التفاصيل",
      start: "من",
      end: "إلي",
      originalPrice: "السعر الأصلي",
      currentPrice: "السعر الحالي",
      status: "الحالة",
      value: "القيمة",
      price: "السعر",
      expirationDate: "تاريخ إنتهاء صلاحية العرض",
      expirationReason: "سبب إنتهاء صلاحية العرض",
      enabled: "مفعل",
      disabled: "غير مفعل"
    }
  }
};

export default kuzloAr;
