import { createTheme } from "@material-ui/core/styles";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#e95b25",
      light: "#ecf1fb"
    },
    secondary: {
      main: "#003857"
    },
    text: {
      primary: "#171717",
      secondary: "#767676"
    },
    background: {
      default: "#eff0f6"
    },
    action: {
      disabledBackground: "#e95b25"
    },
    white: "#ffffff",
    black: {
      main: "#171717",
      light: "#3d3d3d"
    },
    divider: "#FFFFFF"
  },
  tooltip: {
    backgroundOrange: "#e95b25",
    backgroundGrey: "#f2f2f3",
    backgroundBlue: "#003857"
  },
  typography: {
    fontFamily: "Cairo-Regular",
    h1: {
      fontFamily: "Cairo-Bold"
    },
    h2: {
      fontFamily: "Cairo-Bold"
    },
    h3: {
      fontFamily: "Cairo-Bold"
    },
    h4: {
      fontFamily: "Cairo-Bold"
    },
    h5: {
      fontFamily: "Cairo-Bold"
    },
    h6: {
      fontFamily: "Cairo-Bold"
    }
  }
});

export default Theme;
