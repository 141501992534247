import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import MaterialTabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import "./Tabs.scss";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.element,
  index: PropTypes.any,
  value: PropTypes.any
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Tabs = ({
  tabs,
  content,
  tabsClassName,
  wrapperClassName,
  handleChangeTabs,
  activeTab,
  hasConfirmationMessage,
  allowStopNavigation, // send with true when no need to confirmation Modal
  handleNavigationModal, // no need to use this if we don't need navigation confirmation Modal
  handleNextTab // no need to use this if we don't need navigation confirmation Modal
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (allowStopNavigation) {
      // if  navigate = true Navigation will work Normally
      setValue(newValue);
      handleChangeTabs(newValue);
    } else {
      handleNavigationModal(true);
      handleNextTab(newValue);
    }
  };

  useEffect(() => {
    if (hasConfirmationMessage) {
      setValue(activeTab);
    } else {
      activeTab && setValue(activeTab);
    }
  }, [activeTab]);

  return (
    <div className={`${wrapperClassName} w-100 m-3 wrapper-tabs`}>
      <AppBar
        position="static"
        className={`${tabsClassName} tabs m-auto`}
      >
        <MaterialTabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {tabs?.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              {...a11yProps(index)}
              className={tab.className}
            />
          ))}
        </MaterialTabs>
      </AppBar>
      {content?.map((content, index) => (
        <TabPanel value={value} index={index} key={index}>
          {content}
        </TabPanel>
      ))}
    </div>
  );
};
export default Tabs;

Tabs.propTypes = {
  content: PropTypes.array,
  tabsClassName: PropTypes.string,
  tabs: PropTypes.array,
  wrapperClassName: PropTypes.string,
  handleChangeTabs: PropTypes.func,
  activeTab: PropTypes.number,
  allowStopNavigation: PropTypes.bool,
  hasConfirmationMessage: PropTypes.bool,
  handleNavigationModal: PropTypes.func,
  handleNextTab: PropTypes.func
};
