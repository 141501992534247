import * as Yup from "yup";

import messages from "../../../assets/locale/messages";
import {
  EGYPT_MOBILEPHONE_REGEX,
  IBAN_REGEX,
  ARABIC_REGEX
} from "../../../utils/Patterns";

const lang = localStorage.getItem("lang") || "ar";
const { profile } = messages[lang];

const requiredFieldMsg = (fieldName) =>
  profile.validations.required.replace("{{field}}", fieldName);

export const editProfileSchema = Yup.object({
  username: Yup.string()
    .required(requiredFieldMsg(profile.name))
    .matches(ARABIC_REGEX, profile.validations.arabic),
  email: Yup.string()
    .email(profile.validations.validEmail)
    .required(requiredFieldMsg(profile.email)),
  phone_number: Yup.string()
    .required(requiredFieldMsg(profile.phone))
    .matches(EGYPT_MOBILEPHONE_REGEX, profile.validations.validPhone),
  address: Yup.string()
    .required(requiredFieldMsg(profile.address))
    .matches(ARABIC_REGEX, profile.validations.arabic),
  logo: Yup.string(),
  bank_name: Yup.string().required(
    requiredFieldMsg(profile.bankName)
  ),
  bank_branch: Yup.string().required(
    requiredFieldMsg(profile.branchName)
  ),
  bank_account_number: Yup.string().required(
    requiredFieldMsg(profile.accountNumber)
  ),
  bank_iban: Yup.string().matches(
    IBAN_REGEX,
    profile.validations.validIban
  ),
  incontact_name: Yup.string()
    .required(requiredFieldMsg(profile.name))
    .matches(ARABIC_REGEX, profile.validations.arabic),
  incontact_email: Yup.string()
    .email(profile.validations.validEmail)
    .required(requiredFieldMsg(profile.email)),
  incontact_phone_number: Yup.string()
    .required(requiredFieldMsg(profile.phone))
    .matches(EGYPT_MOBILEPHONE_REGEX, profile.validations.validPhone),
  delivery_method_ids: Yup.array().min(
    1,
    profile.validations.delivery
  ),
  payment_method_ids: Yup.array().min(1, profile.validations.payment)
});

export const getProfileFormData = (user) => {
  return {
    username: user?.username || "",
    email: user?.email || "",
    phone_number: user?.phone_number || "",
    address: user?.address || "",
    logo: user?.logo || "",
    bank_name: user?.bank_name || "",
    bank_branch: user?.bank_branch || "",
    bank_account_number: user?.bank_account_number || "",
    bank_iban: user?.bank_iban || "",
    incontact_name: user?.incontact_name || "",
    incontact_email: user?.incontact_email || "",
    incontact_phone_number: user?.incontact_phone_number || "",
    delivery_method_ids: user?.delivery_method_ids || [1],
    payment_method_ids: user?.payment_method_ids || []
  };
};
