import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Breadcrumb from "../../../../components/Breadcrumb";
import Button from "../../../../components/Button";
import ProductImagesSwiper from "../../../../components/ProductImagesSwiper";
import Switch from "../../../../components/Switch";
import AddEditProduct from "../../../../components/AddEditProduct";
import Modal from "../../../../components/Modal";
import AlertNote from "../../../../components/AlertNote";
import DeleteIcon from "../../../../utils/Icons/Delete";
import EditIcon from "../../../../utils/Icons/Edit";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import messages from "../../../../assets/locale/messages";
import {
  deleteWholsalerProductRequest,
  getWholesalerProductRequest,
  editWholesalerProductRequest
} from "../../../../store/WholsalerProducts/actions";
import { getPreviousProductOffersRequest } from "../../../../store/Offers/actions";
import AppliedOffersCard from "./AppliedOffersCard";
import "./ViewProduct.scss";

const ViewProduct = () => {
  const lang = useSelector((state) => state.locale.lang);
  const product = useSelector(
    (state) => state.wholesLerProducts.product
  );
  const previousProductOffers = useSelector(
    (state) => state.offers.previousProductOffers
  );
  const { products, general } = messages[lang];
  const [openEdit, setOpenEdit] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const renderdSlides = () => {
    if (product?.product?.images) {
      if (product.product.images?.length == 0) {
        return (
          <div
            style={{
              backgroundImage: `url(${process.env.REACT_APP_PRODUCTS_PLACEHOLDER})`
            }}
            className="product-placeholder-image mt-3 "
          ></div>
        );
      } else if (product.product.images?.length <= 5) {
        return (
          <div className="d-flex justify-content-start mt-3">
            {product.product.images?.map((image, index) => (
              <div
                key={index}
                style={{
                  backgroundImage: `url(${image.url})`,
                  backgroundSize: "cover"
                }}
                className="product-image mx-2 "
              ></div>
            ))}
          </div>
        );
      } else {
        return (
          <ProductImagesSwiper slides={product?.product?.images} />
        );
      }
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(
        getWholesalerProductRequest({
          id,
          insideViewProductDetails: true
        })
      );
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      dispatch(
        getPreviousProductOffersRequest({
          wholesalers_product_id: id,
          applied_offers: true
        })
      );
    }
  }, [id]);

  const handleDelete = (id) => {
    dispatch(deleteWholsalerProductRequest({ productId: id }));
  };
  const handleCloseEditModal = () => {
    setOpenEdit(false);
  };
  const toggleStock = (id, inStock) => {
    dispatch(
      editWholesalerProductRequest({
        id: id,
        data: {
          is_in_stock: !inStock
        },
        insideWholesalerProductDetails: true
      })
    );
  };

  return (
    <div className="content-wrapper viewProduct">
      <div className="d-flex justify-content-between mb-4">
        <Breadcrumb
          linksArray={[
            {
              link: ROUTE_PATHS["myProducts"],
              name: products.myProducts
            },
            {
              name: products.viewProduct.productDetails
            }
          ]}
          className="mb-4"
        />
        <div className="d-flex  ">
          <div className="d-flex flex-column align-items-center">
            <Switch
              checked={product?.is_in_stock || false}
              name="inStock"
              onChange={() => {
                toggleStock(product.id, product?.is_in_stock);
              }}
            />
            <p className="toggle-text">
              {product?.is_in_stock
                ? products.viewProduct.inStock
                : products.viewProduct.outStock}
            </p>
          </div>
          <Button
            label={
              <div className="d-flex align-items-center">
                <DeleteIcon />
                <span className="pe-1">
                  {products.viewProduct.deleteProduct}
                </span>
              </div>
            }
            labelClass="fsize-16 py-1"
            className="mb-4  mx-3 "
            outlined
            onClick={() => {
              handleDelete(id);
            }}
          />
          <Button
            label={
              <div className="d-flex align-items-center ">
                <EditIcon />
                <span className="pe-1">
                  {products.viewProduct.editProduct}
                </span>
              </div>
            }
            labelClass="fsize-16 text-white text-center  px-4  py-1"
            className="mb-4 "
            onClick={() => {
              setOpenEdit(true);
            }}
          />
          <Modal
            open={openEdit}
            onClose={handleCloseEditModal}
            modalBody={
              <AddEditProduct
                onClose={handleCloseEditModal}
                productData={product}
              />
            }
            modalLabel={
              <span className="modalLabel">
                {products.productCard.modalLabel}
              </span>
            }
          />
        </div>
      </div>
      <div className="content-wrapper p-0">
        <AlertNote
          alertText={general.noteOfChangingProductsWillAffectOffers}
          wrapperClasses="w-60 pb-4 ms-3"
        />
      </div>
      <div className="w-75">
        <div className="row">
          <div className="col-6 text-dark-blue fsize-16 font-bold ">
            {product?.product?.concatenated_name}
          </div>
          <div className="col-6">
            <span className="text-product-card-dark font-light">
              {products.viewProduct.category} :
            </span>
            <span className="text-product-card-dark pe-1">
              {product?.product?.super_product?.product_category.name}
            </span>
          </div>
        </div>
        {renderdSlides()}
        {product?.product?.description && (
          <div className="pt-5 ">
            <span className="text-product-card-dark fsize-14 my-2 font-light">
              {products.viewProduct.description}:
            </span>
            <span className="p-3 fsize-16 text-product-card-dark my-2 pe-3">
              {product?.product?.description}
            </span>
          </div>
        )}
        <div className=" py-4 d-flex flex-wrap text-product-card">
          {product?.product?.specifications?.map((spec, index) => (
            <span
              key={index}
              className="view-spec-label specsColor  mb-1 px-4 py-1"
            >
              {spec}
            </span>
          ))}
        </div>
        <div className="row">
          <div className="col-3">
            <p className="label-color fsize-16 ">
              {products.viewProduct.originalPrice}
            </p>
            <div className="d-flex align-items-center">
              <p className="fsize-30 err-asterisk ">
                {product?.price}
              </p>
              <p className="text-dark-blue pe-2">
                {products.viewProduct.pound}
              </p>
            </div>
          </div>
          <div className="col-3">
            <p className="label-color fsize-16 ">
              {products.viewProduct.quantity}
            </p>
            <p className="fsize-30 err-asterisk">
              {product?.quantity}
            </p>
          </div>
          {product?.min_ordering_quantity && (
            <div className="col-3">
              <p className="label-color fsize-16 ">
                {products.viewProduct.minQuantity}
              </p>
              <p className="fsize-30 err-asterisk">
                {product?.min_ordering_quantity}
              </p>
            </div>
          )}
          {product?.max_ordering_quantity && (
            <div className="col-3">
              <p className="label-color fsize-16 ">
                {products.viewProduct.maxQuantity}
              </p>
              <p className="fsize-30 err-asterisk">
                {product?.max_ordering_quantity}
              </p>
            </div>
          )}
        </div>
      </div>
      {previousProductOffers.length > 0 && (
        <>
          <div className="mt-3 applied-offers-container">
            <p className=" mb-4 fsize-16 font-medium applied-offers-text">
              {products.appliedOffers}
            </p>

            <div className="applied-offers-cards">
              {previousProductOffers?.map((offer, index) => (
                <AppliedOffersCard
                  key={`offer-${index}`}
                  offer={offer}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewProduct;
