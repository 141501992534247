import { axiosInstance } from "./";

const login = async (payload) => {
  return await axiosInstance.post("sessions", payload);
};

const logout = async (payload) => {
  return await axiosInstance.delete("sessions", { data: payload });
};

const forgotPassword = async (payload) => {
  return await axiosInstance.post(
    "wholesalers/forgot_password",
    payload
  );
};

const setPassword = async (payload) => {
  return await axiosInstance.patch(
    "wholesalers/set_password",
    payload
  );
};

const resendEmailVerification = async (payload) =>
  await axiosInstance.post(
    "wholesalers/resend_email_verification",
    payload
  );

export {
  login,
  logout,
  forgotPassword,
  setPassword,
  resendEmailVerification
};
