export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RESPONSE = "LOGIN_RESPONSE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";

export const RESEND_EMAIL_VERIFICATION_REQUEST =
  "RESEND_EMAIL_VERIFICATION_REQUEST";
