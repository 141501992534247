import messages from "../assets/locale/messages";
import { ROUTE_PATHS } from "./RoutesPaths";

export const wholesalerDeliveryMethods = [
  { id: 1, name: "direct" },
  { id: 2, name: "pickup" },
  { id: 3, name: "serviceProvider" }
];

export const wholesalerPaymentMethods = [
  { id: 1, name: "cash" },
  { id: 2, name: "online" }
];

export const orderStatus = {
  pending: "pending",
  inProgress: "in_progress",
  outForDelivery: "out_for_delivery",
  delivered: "delivered",
  cancelled: "cancelled",
  rejected: "rejected"
};

export const orderStatusOptions = [
  {
    id: 1,
    label: messages["ar"].orders.filterOrder.pending,
    value: orderStatus.pending
  },
  {
    id: 2,
    label: messages["ar"].orders.filterOrder.in_progress,
    value: orderStatus.inProgress
  },
  {
    id: 3,
    label: messages["ar"].orders.filterOrder.out_for_delivery,
    value: orderStatus.outForDelivery
  },
  {
    id: 4,
    label: messages["ar"].orders.filterOrder.delivered,
    value: orderStatus.delivered
  },
  {
    id: 5,
    label: messages["ar"].orders.filterOrder.cancelled,
    value: orderStatus.cancelled
  },
  {
    id: 6,
    label: messages["ar"].orders.filterOrder.rejected,
    value: orderStatus.rejected
  }
];
export const productStatus = [
  {
    id: 1,
    label: messages["ar"].products.inStock,
    value: true
  },
  {
    id: 2,
    label: messages["ar"].products.outOfStock,
    value: false
  }
];
export const notificationsActionsList = [
  {
    action: "published",
    route: ROUTE_PATHS.viewProfile
  },
  {
    action: "open_order",
    route: ROUTE_PATHS.viewOrder
  },
  {
    action: "open_offer",
    route: ROUTE_PATHS.viewOffer
  }
];

export const offersStatus = [
  {
    id: 1,
    value: "active",
    label: messages["ar"].offers.filterStatus.active
  },
  {
    id: 2,
    value: "pending",
    label: messages["ar"].offers.filterStatus.pending
  },
  {
    id: 3,
    value: "expired",
    label: messages["ar"].offers.filterStatus.expired
  }
];

export const offersSubStatus = [
  {
    id: 1,
    value: true,
    label: messages["ar"].offers.filterSubStatus.enabled
  },
  {
    id: 2,
    value: false,
    label: messages["ar"].offers.filterSubStatus.disabled
  }
];

export const offerActiveStatus = ["active", "جاري الان"];

export const offerPendingStatus = ["pending", "قيد الانتظار"];

export const offerExpiredStatus = ["expired", "منتهي"];
