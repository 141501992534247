export const GET_KUZLO_PRODUCTS_REQUEST =
  "GET_KUZLO_PRODUCTS_REQUEST";
export const GET_KUZLO_PRODUCTS_RESPONSE =
  "GET_KUZLO_PRODUCTS_RESPONSE";

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_RESPONSE = "GET_CATEGORIES_RESPONSE";

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_RESPONSE = "GET_PRODUCT_RESPONSE";
