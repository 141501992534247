import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  product: null
};

const WholsalerProductsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_WHOLESALER_PRODUCTS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.GET_WHOLESALER_PRODUCT_RESPONSE: {
      return { ...state, product: action.payload };
    }

    default:
      return state;
  }
};

export default WholsalerProductsReducer;
