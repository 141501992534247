import React from "react";
import { useSelector } from "react-redux";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import AuthLayout from "../../../components/AuthLayout";
import messages from "../../../assets/locale/messages";

const CheckEmail = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { auth } = messages[lang];

  return (
    <AuthLayout showCard={false}>
      <h5 className="fsize-18">
        <span className="ms-2">
          <ErrorOutlineIcon />
        </span>
        {auth.checkEmail}
      </h5>
    </AuthLayout>
  );
};

export default CheckEmail;
