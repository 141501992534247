import React from "react";

export const EditIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="19"
      fill="none"
      viewBox="0 0 21 19"
      {...props}
    >
      <path
        fill="#828282"
        d="M6.936 13.124L5.825 12a.279.279 0 00-.476.2v5.112c0 .932.747 1.688 1.667 1.688h12.222c.92 0 1.667-.756 1.667-1.688V4.936c0-.931-.747-1.687-1.667-1.687H9.742c-.247 0-.372.302-.198.481l1.11 1.125c.053.053.122.081.199.081h8.385v12.375H7.016v-3.99a.279.279 0 00-.08-.197zM1.499 6.03l9.118 9.232 3.138.351c.91.102 1.684-.675 1.584-1.603l-.347-3.178L5.874 1.6a2.009 2.009 0 00-2.872 0l-1.5 1.519a2.078 2.078 0 00-.003 2.91zm3.43 1.086l2.017-2.043 6.452 6.536.253 2.296-2.267-.257-6.455-6.532zM2.68 4.314l1.5-1.519a.36.36 0 01.514 0l1.073 1.087-2.017 2.042-1.073-1.086a.378.378 0 01.003-.524z"
      ></path>
    </svg>
  );
};
