import * as React from "react";

export const LogoutIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.667 2.667h10.666A1.333 1.333 0 0 1 20.667 4v16a1.333 1.333 0 0 1-1.334 1.334H8.667A1.333 1.333 0 0 1 7.333 20v-4h6.247a.667.667 0 1 0 0-1.333H7.333V4a1.333 1.333 0 0 1 1.334-1.333Z"
      fill="#336079"
    />
    <path
      d="M5.227 11.52a.667.667 0 0 1 .94.94l-2.254 2.206h3.42V16h-3.42l2.254 2.306a.668.668 0 1 1-.94.94L1.333 15.38l3.894-3.86Z"
      fill="#336079"
    />
  </svg>
);
