import {
  SET_ORDERS_ROUTE,
  SET_WHOLESALER_PRODUCTS_ROUTE,
  SET_OFFERS_ROUTE
} from "./types";

const INITIAL_STATE = {
  orders: null,
  wholesalerProducts: null
};

const routing = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ORDERS_ROUTE:
      return {
        ...state,
        orders: action.payload
      };
    case SET_WHOLESALER_PRODUCTS_ROUTE:
      return {
        ...state,
        wholesalerProducts: action.payload
      };
    case SET_OFFERS_ROUTE:
      return {
        ...state,
        offers: action.payload
      };
    default:
      return state;
  }
};

export default routing;

// STATE SCHEMA

// orders: {
//     page: null,
//     search: null,
//     storeName: null,
//     orderStatus: null,
//     startDate: null,
//     endDate: null,
//     activeTab: null
// }

// wholesalerProducts: {
//     page: null,
//     search: null,
//     category: null
// }
