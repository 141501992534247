import React from "react";

const DeleteIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      fill="none"
      {...props}
    >
      <path
        fill="#E95B25"
        d="M6.14 2.672h-.202a.204.204 0 00.203-.203v.203h7.718v-.203c0 .111.092.203.204.203h-.204V4.5h1.829V2.469c0-.897-.73-1.625-1.626-1.625H5.938c-.897 0-1.625.728-1.625 1.625V4.5H6.14V2.672zM18.938 4.5H1.063a.812.812 0 00-.812.813v.812c0 .112.091.203.203.203h1.534l.627 13.28c.04.865.757 1.548 1.622 1.548h11.528c.868 0 1.582-.68 1.622-1.549l.627-13.279h1.534a.204.204 0 00.203-.203v-.813a.812.812 0 00-.813-.812zm-3.369 14.828H4.432l-.615-13h12.366l-.615 13z"
      ></path>
    </svg>
  );
};

export default DeleteIcon;
