import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import messages from "../../../assets/locale/messages";
import { setWarehouseLocation } from "../../../store/Warehouse/actions";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import GoogleMaps from "../../../components/GoogleMaps";
import Button from "../../../components/Button";
import "./PickLocation.scss";

const PickLocation = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lang = useSelector((state) => state.locale.lang);
  const warehouseLocation = useSelector(
    (state) => state.warehouse.location
  );
  const { warehouse } = messages[lang];

  const [locationCoords, setLocationCoords] = useState({
    coords: null
  });

  const getLocation = (newLocation) => {
    setLocationCoords(newLocation);
  };

  const handleSaveChanges = () => {
    dispatch(setWarehouseLocation(locationCoords));
    history.push(ROUTE_PATHS.editWarehouse);
  };

  return (
    <div className="content-wrapper">
      <div className="pick-location-page d-flex flex-column justify-content-between">
        <div className="map">
          <p className="font_medium fsize-16 fweight-600 mb-4">
            {warehouse.pickLocationTitle}
          </p>
          <GoogleMaps
            isMarker={true}
            getCoords={getLocation}
            savedCoords={
              warehouseLocation.coords
                ? warehouseLocation
                : location.state?.location
            }
            className="pick-location-map"
          />
        </div>
        <div className="action-wrapper text-center">
          <Button
            label={warehouse.saveAndContinue}
            className="text-white fsize-16 submit-btn"
            onClick={handleSaveChanges}
          />
        </div>
      </div>
    </div>
  );
};

export default PickLocation;

PickLocation.propTypes = {
  location: PropTypes.object
};
