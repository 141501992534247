import React from "react";

const ExportIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#E95B25"
        fillRule="evenodd"
        d="M13 5.828V17h-2V5.828L7.757 9.071 6.343 7.657 12 2l5.657 5.657-1.414 1.414L13 5.828z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#E95B25"
        fillRule="evenodd"
        d="M4 16h2v4h12v-4h2v4c0 1.1-.9 2-2 2H6c-1.1 0-2-.963-2-2v-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ExportIcon;
