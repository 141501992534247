import * as types from "./types";

export const getOrdersRequest = (data) => ({
  type: types.GET_ORDERS_REQUEST,
  payload: data
});

export const getOrdersResponse = (data) => ({
  type: types.GET_ORDERS_RESPONSE,
  payload: data
});

export const getRetailersRequest = () => ({
  type: types.GET_RETAILERS_REQUEST
});

export const getRetailersResponse = (payload) => ({
  type: types.GET_RETAILERS_RESPONSE,
  payload
});

export const getOrderDetailsRequest = (payload) => ({
  type: types.GET_ORDER_DETAILS_REQUEST,
  payload
});

export const getOrderDetailsResponse = (payload) => ({
  type: types.GET_ORDER_DETAILS_RESPONSE,
  payload
});

export const updateOrderRequest = (payload) => ({
  type: types.UPDATE_ORDER_REQUEST,
  payload
});

export const getOrderPDFRequest = (data) => ({
  type: types.GET_ORDER_PDF_REQUEST,
  payload: data
});

export const getOrderPDFResponse = (data) => ({
  type: types.GET_ORDER_PDF_RESPONSE,
  payload: data
});
