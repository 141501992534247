export const setWholesaler = (
  { wholesaler },
  storage = "session"
) => {
  if (storage === "session") {
    sessionStorage.setItem("wholesaler", JSON.stringify(wholesaler));
  } else {
    localStorage.setItem("wholesaler", JSON.stringify(wholesaler));
  }
};

export const clearWholesalerData = () => {
  localStorage.removeItem("device_id");
  localStorage.removeItem("wholesaler");
  sessionStorage.removeItem("wholesaler");
};
