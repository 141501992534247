import React from "react";
import PropTypes from "prop-types";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

import "./ProductImagesSwiper.scss";

SwiperCore.use([Navigation]);

const ProductImagesSwiper = ({ slides }) => {
  return (
    <div className="product-images-swiper-container px-5">
      <Swiper
        slidesPerView={4}
        centeredSlides={false}
        spaceBetween={10}
        grabCursor={true}
        navigation
        className="mySwiper"
      >
        {slides.map((image, index) => (
          <SwiperSlide key={index}>
            <div
              style={{ backgroundImage: `url(${image.url})` }}
              className="product-image  d-flex justify-content-start"
            ></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

ProductImagesSwiper.propTypes = {
  slides: PropTypes.array.isRequired
};

export default ProductImagesSwiper;
