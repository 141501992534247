import React from "react";
import PropTypes from "prop-types";
import MaterialButton from "@material-ui/core/Button";

import "./Button.scss";

const Button = ({
  className,
  label,
  labelClass,
  type,
  outlined,
  disabled,
  onClick,
  block,
  color
}) => {
  return (
    <MaterialButton
      className={`btn ${block ? "btn-block" : ""}  ${className}`}
      variant={outlined ? "outlined" : "contained"}
      type={type}
      disabled={disabled}
      onClick={onClick}
      color={color}
    >
      <span className={labelClass}>{label}</span>
    </MaterialButton>
  );
};

Button.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired
  ]),
  labelClass: PropTypes.string,
  type: PropTypes.string,
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  block: PropTypes.bool,
  color: PropTypes.string
};

Button.defaultProps = { type: "button", color: "primary" };

export default Button;
