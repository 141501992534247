import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Filter from "../../../components/Filter";
import SearchInput from "../../../components/SearchInput";
import messages from "../../../assets/locale/messages";

const OffersFiltersAndSearch = ({ status, subStatus, search }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { offers } = messages[lang];
  return (
    <div className="d-flex align-items-center justify-content-between px-4 filters-search-container">
      <SearchInput
        name="search"
        value={search.value}
        onChange={(value) => {
          search.onChangeHandler(value);
        }}
        placeholder={offers.searchPlaceHolder}
        inputWrapperClass="search  px-0 w-30 pb-3"
        inputClass="mt-2 "
      />
      <div className="offers-filters px-4 pb-2 d-flex align-items-center w-70">
        <Filter
          placeholder={offers.filtersPlaceHolders.status}
          options={status.options}
          value={status.value}
          name="offer status"
          onChange={(value) => {
            status.onChangeHandler(value);
          }}
          inputClassName=" mt-1 "
          wrapperClassName=" me-4 "
        />
        <Filter
          placeholder={offers.filtersPlaceHolders.subStatus}
          options={subStatus.options}
          value={subStatus.value}
          name="offer subStatus"
          onChange={(value) => {
            subStatus.onChangeHandler(value);
          }}
          inputClassName=" mt-1 "
          wrapperClassName=" me-4 "
        />
      </div>
    </div>
  );
};

export default OffersFiltersAndSearch;

OffersFiltersAndSearch.propTypes = {
  status: PropTypes.object,
  subStatus: PropTypes.object,
  search: PropTypes.object
};
