import { put, call, takeEvery } from "redux-saga/effects";
import amplitude from "amplitude-js";

import * as apis from "../../network/apis/WholsalerProducts";
import * as Types from "./types";
import messages from "../../assets/locale/messages";
import { showHideSnackbar } from "../Snackbar/actions";
import {
  getWholesalerProductsResponse,
  getWholesalerProductsRequest,
  getWholesalerProductResponse,
  getWholesalerProductRequest
} from "./actions";
import { getPreviousProductOffersRequest } from "..//Offers/actions";
import { getKuzloProductsRequest } from "../KuzloProducts/actions";
import history from "../../routes/History";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";

export function* addWholesalerProductSaga({ payload }) {
  const {
    page,
    query,
    product_category_id,
    product_id,
    quantity,
    price,
    max_ordering_quantity,
    min_ordering_quantity
  } = payload;
  try {
    yield call(apis.addWholesalerProduct, {
      product_id,
      quantity,
      price,
      max_ordering_quantity,
      min_ordering_quantity
    });
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["ar"].products.productCard.successMessage
      })
    );
    yield put(
      getKuzloProductsRequest({
        page: page,
        items: 12,
        name: query,
        product_category_id: product_category_id || undefined
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export function* getWholesalerProductsSaga({ payload }) {
  try {
    const response = yield call(apis.getWholesalerProducts, payload);
    yield put(
      getWholesalerProductsResponse({
        list: response.data.wholesalers_product,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );

    if (
      +response.headers["current-page"] >
      +response.headers["total-pages"]
    ) {
      yield put(
        getWholesalerProductsRequest({
          ...payload,
          page: +response.headers["total-pages"]
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* deleteWholsalerProductSaga({ payload }) {
  try {
    const { productId, searchValue, page, categoryId } = payload;
    const response = yield call(
      apis.deleteWholesalerProduct,
      productId
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: response.data.message
      })
    );

    history.push(ROUTE_PATHS["myProducts"]);

    yield put(
      getWholesalerProductsRequest({
        page,
        query: searchValue,
        product_category_id: categoryId || undefined,
        items: 12
      })
    );

    if (response.data.success === true) {
      amplitude
        .getInstance()
        .logEvent("wholesaler update: product deleted", {
          "product id": productId
        });
    }
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}
export function* getWholsalerProductSaga({ payload }) {
  try {
    const response = yield call(apis.getWholesalerProduct, payload);
    yield put(
      getWholesalerProductResponse(response.data.wholesalers_product)
    );

    const { insideViewProductDetails } = payload;
    if (insideViewProductDetails) {
      const eventProperties = {
        "product id": response.data.wholesalers_product.id,
        "product name":
          response.data.wholesalers_product.product.concatenated_name
      };

      amplitude
        .getInstance()
        .logEvent(
          "wholesaler browse: view product details",
          eventProperties
        );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* editWholsalerProduct({ payload }) {
  try {
    const response = yield call(apis.editWholesalerProduct, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["ar"].products.editMessege
      })
    );
    const {
      id,
      page,
      query,
      product_category_id,
      is_in_stock,
      insideWholesalerProductDetails
    } = payload;
    yield put(getWholesalerProductRequest({ id }));
    yield put(
      getWholesalerProductsRequest({
        page,
        items: 12,
        query,
        product_category_id,
        is_in_stock
      })
    );
    if (insideWholesalerProductDetails) {
      yield put(
        getPreviousProductOffersRequest({
          wholesalers_product_id: id,
          applied_offers: true
        })
      );
    }

    if (
      payload.data.is_in_stock === false &&
      response.data.success === true
    ) {
      const eventProperties = {
        "product id": response.data.wholesalers_product.id,
        "product name":
          response.data.wholesalers_product.product.concatenated_name,
        "wholesaler id":
          response.data.wholesalers_product.wholesaler.id,
        "wholesaler name":
          response.data.wholesalers_product.wholesaler.username
      };

      amplitude
        .getInstance()
        .logEvent(
          "wholesaler update: product out of stock",
          eventProperties
        );
    }
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export function* deleteWholsalerProductsSaga({ payload }) {
  try {
    yield call(apis.deleteWholesalerProducts, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["ar"].products.quickEdit.successDeleteMsg
      })
    );
    const { page, query, product_category_id } = payload;
    yield put(
      getWholesalerProductsRequest({
        page,
        items: 12,
        query,
        product_category_id
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* editWholsalerProductsPricesSaga({ payload }) {
  try {
    const response = yield call(
      apis.editWholesalerProductsPrices,
      payload
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: response.data?.message
      })
    );
    const { page, query, product_category_id } = payload;
    yield put(
      getWholesalerProductsRequest({
        page,
        items: 12,
        query,
        product_category_id,
        activated: true
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* editWholsalerProductsInOutStockSaga({ payload }) {
  try {
    const response = yield call(
      apis.editWholesalerProductsInOutStock,
      payload
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: response.data?.message
      })
    );
    const { page, query, product_category_id, is_in_stock } = payload;
    yield put(
      getWholesalerProductsRequest({
        page,
        items: 12,
        query,
        product_category_id,
        is_in_stock: !is_in_stock,
        activated: true
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export default function* productsSagas() {
  yield takeEvery(
    Types.ADD_WHOLESALER_PRODUCT,
    addWholesalerProductSaga
  );
  yield takeEvery(
    Types.GET_WHOLESALER_PRODUCTS_REQUEST,
    getWholesalerProductsSaga
  );
  yield takeEvery(
    Types.DELETE_WHOLESALER_PRODUCT_REQUEST,
    deleteWholsalerProductSaga
  );
  yield takeEvery(
    Types.GET_WHOLESALER_PRODUCT_REQUEST,
    getWholsalerProductSaga
  );
  yield takeEvery(
    Types.EDIT_WHOLESALER_PRODUCT_REQUEST,
    editWholsalerProduct
  );
  yield takeEvery(
    Types.DELETE_WHOLESALER_PRODUCTS_REQUEST,
    deleteWholsalerProductsSaga
  );

  yield takeEvery(
    Types.EDIT_WHOLESALER_PRODUCTS_PRICES_REQUEST,
    editWholsalerProductsPricesSaga
  );

  yield takeEvery(
    Types.EDIT_WHOLESALER_PRODUCTS_IN_OUT_STOCK_REQUEST,
    editWholsalerProductsInOutStockSaga
  );
}
