import React from "react";

const EditIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        d="M1.856 10.311l-.845 3.645a.916.916 0 00.89 1.11.939.939 0 00.19 0l3.667-.844 7.04-7.013-3.929-3.92-7.013 7.022zM15.012 3.699L12.39 1.076a.92.92 0 00-1.298 0L9.635 2.534l3.924 3.925L15.017 5a.92.92 0 00-.005-1.302z"
      ></path>
    </svg>
  );
};

export default EditIcon;
