import {
  SET_WAREHOUSE_LOCATION,
  VIEW_WAREHOUSE_RESPONSE
} from "./types";

const INITIAL_STATE = {
  details: {},
  location: {
    coords: null
  }
};

const warehouse = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VIEW_WAREHOUSE_RESPONSE:
      return { ...state, details: action.payload };
    case SET_WAREHOUSE_LOCATION:
      return { ...state, location: action.payload };
    default:
      return state;
  }
};

export default warehouse;
