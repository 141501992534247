import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import messages from "../../../assets/locale/messages";
import {
  viewWarehouseRequest,
  editWarehouseRequest
} from "../../../store/Warehouse/actions";
import { viewProfileRequest } from "../../../store/Profile/actions";
import { LocationIcon } from "../../../utils/Icons/LocationIcon";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { ARABIC_REGEX } from "../../../utils/Patterns";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import "./EditWarehouse.scss";

const EditWarehouse = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const wholesalerId = useSelector((state) => state.auth.login.id);
  const warehouseId = useSelector(
    (state) => state.profile.details?.warehouse_id
  );
  const warehouseDetails = useSelector(
    (state) => state.warehouse.details
  );
  const warehouseLocation = useSelector(
    (state) => state.warehouse.location
  );

  const { warehouse } = messages[lang];

  useEffect(() => {
    if (wholesalerId) {
      dispatch(viewProfileRequest(wholesalerId));
    }
  }, [wholesalerId]);

  useEffect(() => {
    if (warehouseId) {
      dispatch(viewWarehouseRequest(warehouseId));
    }
  }, [warehouseId]);

  useEffect(() => {
    setValues({
      name: warehouseDetails?.name,
      location: {
        coords: {
          lat: warehouseLocation.coords
            ? warehouseLocation.coords.lat
            : warehouseDetails?.lat,
          lng: warehouseLocation.coords
            ? warehouseLocation.coords.lng
            : warehouseDetails?.lng
        }
      }
    });
  }, [warehouseDetails]);

  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    setValues,
    handleSubmit,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      name: "",
      location: null
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(warehouse.nameRequired)
        .matches(ARABIC_REGEX, warehouse.nameValidation),
      location: Yup.object()
        .nullable()
        .shape({
          lng: Yup.string(),
          lat: Yup.string()
        })
        .required(warehouse.locationRequired)
    }),
    onSubmit: ({
      name,
      location: {
        coords: { lat, lng }
      }
    }) => {
      const data = {
        name,
        lat,
        lng
      };
      dispatch(
        editWarehouseRequest({
          id: warehouseDetails.id,
          data
        })
      );
    }
  });

  return (
    <div className="content-wrapper">
      {warehouseDetails && (
        <div className="edit-warehouse-page">
          <form noValidate onSubmit={handleSubmit}>
            <div className="row pb-5">
              <div className="col-12">
                <h5 className="fsize-18 fweight-600 text-dark mb-3">
                  {warehouse.warehouseDetails}
                </h5>
              </div>
              <div className="col-md-4 col-6 ms-5">
                <Input
                  type="text"
                  name="name"
                  label={warehouse.name}
                  labelClassName="pb-2"
                  placeholder={warehouse.enterName}
                  required
                  value={values["name"]}
                  onChange={(value) => {
                    setFieldTouched("name");
                    setFieldValue("name", value);
                  }}
                  isInputHasErr={
                    !!(touched["name"] && errors["name"])
                  }
                  errMsg={errors["name"]}
                />
              </div>
              <div className="col-md-4 col-6 me-5">
                <p className="pb-2">
                  <LocationIcon className="ms-2" />
                  <span className="text-dark">
                    {warehouse.location}
                  </span>
                </p>
                <Link
                  to={{
                    pathname: ROUTE_PATHS.editWarehouseLocation,
                    state: {
                      location: values["location"]
                    }
                  }}
                >
                  <p className="text-dark-blue fsize-14 fweight-500 pointer show-map-btn">
                    {warehouse.viewMap}
                  </p>
                </Link>
              </div>
            </div>
            <div className="text-center pt-5">
              <Button
                label={warehouse.saveChanges}
                type="submit"
                disabled={!dirty || !isValid}
                labelClass="fsize-14 text-white py-1"
                className="mb-4 submit-btn"
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditWarehouse;
