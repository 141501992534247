import React from "react";

export const Price = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="17"
      fill="none"
      viewBox="0 0 23 17"
      {...props}
    >
      <path
        fill="#AAA7A7"
        d="M16.855.693c-2.102.058-3.73.629-5.317 1.18-1.696.59-3.297 1.145-5.515 1.167-1.482.024-2.966-.233-4.425-.737L.562 1.945V15.29l.52.185c1.36.48 2.748.758 4.131.824.22.01.435.015.646.015 2.354 0 4.156-.624 5.903-1.228 1.843-.637 3.58-1.24 5.951-1.128 1.257.065 2.498.313 3.682.738l1.043.369V1.713l-.515-.188a13.89 13.89 0 00-5.068-.833zm.042 1.552c.266-.008.537-.008.82.003.292.013.584.04.875.074a1.949 1.949 0 002.283 1.45v7.111a1.95 1.95 0 00-2.31 1.582 13.762 13.762 0 00-.778-.057c-2.668-.118-4.635.553-6.537 1.213-1.845.639-3.592 1.243-5.962 1.13-.295-.015-.59-.041-.883-.078a1.949 1.949 0 00-2.28-1.446v-7.11a1.947 1.947 0 002.312-1.603c.535.056 1.07.088 1.604.08 2.472-.026 4.27-.651 6.01-1.256 1.532-.534 2.992-1.035 4.846-1.093zM11.5 4.593c-1.723 0-3.125 1.753-3.125 3.907s1.402 3.906 3.125 3.906 3.125-1.752 3.125-3.906-1.402-3.907-3.125-3.907zm0 1.563c.847 0 1.563 1.073 1.563 2.344 0 1.27-.716 2.343-1.563 2.343S9.937 9.77 9.937 8.5s.716-2.344 1.563-2.344zm5.86 0a1.172 1.172 0 100 2.344 1.172 1.172 0 000-2.344zM5.64 8.5a1.172 1.172 0 100 2.343 1.172 1.172 0 000-2.343z"
      ></path>
    </svg>
  );
};
