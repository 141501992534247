import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Swiper } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { SwiperSlide } from "swiper/react";

import { formatDate } from "../../../utils/Helpers";
import { UpToDownArrow } from "../../../utils/Icons/UpToDownArrow";
import { Calendar } from "../../../utils/Icons/Calendar";
import messages from "../../../assets/locale/messages";
import "./OfferProductCard.scss";

SwiperCore.use([Navigation]);

const ProductCardTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.tooltip.backgroundGrey,
    color: theme.tooltip.backgroundBlue,
    fontSize: "1rem"
  },
  arrow: {
    color: theme.tooltip.backgroundGrey,
    fontSize: "1rem"
  }
}))(Tooltip);

const OfferProductCard = ({ offerProduct }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { offers } = messages[lang];
  const productImagesSlides = (imagesArray) => {
    let slides = [];
    if (imagesArray?.length) {
      slides = imagesArray.map((image) => (
        <SwiperSlide key={image.id}>
          <div
            className="product-card-image-container "
            style={{
              backgroundImage: `url(${image.url})`
            }}
          ></div>
        </SwiperSlide>
      ));
    } else {
      slides.push(
        <SwiperSlide key={1}>
          <div
            className="product-card-image-container product-card-image-container-empty "
            style={{
              backgroundImage: `url(${process.env.REACT_APP_OFFERS_PRODUCTS_PLACEHOLDER})`
            }}
          ></div>
        </SwiperSlide>
      );
    }
    return slides;
  };
  return (
    <div className="product-card py-4">
      {!offerProduct.is_in_stock && (
        <div className="out-of-stock-label">{offers.outOfStock}</div>
      )}

      <div>
        <div className="px-4">
          <Swiper
            className="mb-2 item-swiper "
            navigation={
              productImagesSlides(
                offerProduct?.wholesalers_product?.product?.images
              )?.length > 1
            }
            slidesPerView={1}
            initialSlide={offerProduct?.wholesalers_product?.product?.images?.findIndex(
              (image) => image.is_default
            )}
          >
            {productImagesSlides(
              offerProduct.wholesalers_product?.product?.images
            )}
          </Swiper>
        </div>
        {offerProduct.wholesalers_product.product.concatenated_name
          ?.length > 20 ? (
          <ProductCardTooltip
            title={
              <div>
                {
                  offerProduct.wholesalers_product.product
                    .concatenated_name
                }
              </div>
            }
            placement="left"
            arrow
          >
            <div className="text-dark-blue font-cairo-semibold fsize-14 item-parent-name px-4">
              <span>
                {offerProduct.wholesalers_product.product.concatenated_name?.substring(
                  0,
                  20
                ) + "..."}
              </span>
            </div>
          </ProductCardTooltip>
        ) : (
          <div className="text-dark-blue font-cairo-semibold fsize-14 item-parent-name px-4">
            <span>
              {
                offerProduct.wholesalers_product.product
                  .concatenated_name
              }
            </span>
          </div>
        )}
      </div>
      <div className="details-container mt-2 px-4 pb-2">
        <div className="mt-2 font-cairo-regular row">
          <div className=" fsize-12 col-3 pt-02 text-product-card-dark ps-0">
            {offerProduct.wholesalers_product.product.description &&
            offerProduct.wholesalers_product.product.description
              ?.length > 40 ? (
              <ProductCardTooltip
                title={
                  <div className="Tooltip-desc">
                    {
                      offerProduct.wholesalers_product.product
                        .description
                    }
                  </div>
                }
                placement="left"
                arrow
              >
                <span>{offers.viewOffer.description}</span>
              </ProductCardTooltip>
            ) : (
              <span>{offers.viewOffer.description}</span>
            )}
          </div>
          <div className=" fsize-12 col-9 pe-0 text-product-card-dark  ">
            {!offerProduct.wholesalers_product.product.description ||
            offerProduct.wholesalers_product.product.description
              ?.length == 0
              ? offers.viewOffer.noProducts
              : offerProduct.wholesalers_product.product.description
                  ?.length < 40
              ? offerProduct.wholesalers_product.product.description
              : offerProduct.wholesalers_product.product.description.substring(
                  0,
                  40
                ) + "..."}
          </div>
        </div>

        {offerProduct.wholesalers_product.product.super_product
          .product_category.name && (
          <div className="mt-2 font-cairo-regular row">
            <div className="row align-items-center">
              <div className="col-3 fsize-12  ps-0 text-product-card-text">
                {offerProduct.wholesalers_product.product
                  .super_product.product_category.name &&
                offerProduct.wholesalers_product.product.super_product
                  .product_category.name?.length > 40 ? (
                  <ProductCardTooltip
                    title={
                      <div className="Tooltip-desc">
                        {
                          offerProduct.wholesalers_product.product
                            .super_product.product_category.name
                        }
                      </div>
                    }
                    placement="left"
                    arrow
                  >
                    <span>{offers.viewOffer.category}</span>
                  </ProductCardTooltip>
                ) : (
                  <span>{offers.viewOffer.category}</span>
                )}
              </div>
              <div className="text-product-card-blue col-9 fsize-12">
                {offerProduct.wholesalers_product.product
                  .super_product.product_category.name?.length < 40
                  ? offerProduct.wholesalers_product.product
                      .super_product.product_category.name
                  : offerProduct.wholesalers_product.product.super_product.product_category.name.substring(
                      0,
                      40
                    ) + "..."}
              </div>
            </div>
          </div>
        )}

        <div className="mt-4 position-relative prices-container">
          <div className="font-cairo-regular row">
            <div className="row align-items-center">
              <div className="col-5 fsize-12  ps-0 text-product-card-text">
                <span>{offers.viewOffer.originalPrice}</span>
              </div>
              <div className="text-product-card-blue col-7 fsize-12 px-0">
                <span className="ms-2 ">
                  {offerProduct.wholesalers_product.price}
                </span>
                <span>{offers.viewOffer.le}</span>
              </div>
            </div>
          </div>

          <div className="mt-2 font-cairo-regular row">
            <div className="row align-items-center">
              <div className="col-5 fsize-12  ps-0 text-product-card-text">
                <span>{offers.viewOffer.currentPrice}</span>
              </div>
              <div className="text-product-card-blue col-7 fsize-14 font-cairo-semibold px-0">
                <span className="ms-2 ">
                  {offerProduct.new_price.toFixed(2)}
                </span>
                <span>{offers.viewOffer.le}</span>
              </div>
            </div>
          </div>

          {offerProduct.discount_type === "amount" && (
            <div className="font-cairo-regular fsize-14 px-1 offer-value">
              <span className="mx-1">
                {offerProduct.discount_value}
              </span>
              <span className="ms-2">{offers.viewOffer.le}</span>
            </div>
          )}
          {offerProduct.discount_type === "percentage" && (
            <div className="font-cairo-regular fsize-14 offer-value">
              <span>%</span>
              <span className="mx-1">
                {offerProduct.discount_value}
              </span>
            </div>
          )}
        </div>
        <div className="mt-4 Dates-container position-relative">
          <div className="d-flex justify-content-start pe-3 mt-2 fsize-14">
            <span className="me-3">
              {formatDate(
                offerProduct.start_datetime,
                "hh:mm A",
                "en"
              )}
            </span>
            <span className="">
              {formatDate(
                offerProduct.start_datetime,
                "DD/MM/YYYY",
                "en"
              )}
            </span>

            <span className="px-2">
              <Calendar />
            </span>
          </div>

          <div className="d-flex justify-content-start pe-3 mt-2 fsize-14">
            <span className="me-3">
              {formatDate(offerProduct.end_datetime, "hh:mm A", "en")}
            </span>
            <span className="">
              {formatDate(
                offerProduct.end_datetime,
                "DD/MM/YYYY",
                "en"
              )}
            </span>

            <span className="px-2">
              <Calendar />
            </span>
          </div>

          <div className="date-arrow-container">
            <UpToDownArrow />
          </div>
        </div>
      </div>
    </div>
  );
};

OfferProductCard.propTypes = {
  offerProduct: PropTypes.object
};

export default OfferProductCard;
