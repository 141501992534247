import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Person } from "@material-ui/icons";
// import { withStyles } from "@material-ui/core/styles";
// import { Tooltip } from "@material-ui/core";

import messages from "../../assets/locale/messages";
// import { EmptyNotificationsIcon } from "../../utils/Icons/EmptyNotifications";
// import { NotificationBg } from "../../utils/Icons/NotificationBg";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
// import {
//   formatDate,
//   handleNotificationsClickActions,
//   setNotificationSeen
// } from "../../utils/Helpers";
// import { getAllNotificationsRequest } from "../../store/Notifications/actions";
import { viewProfileRequest } from "../../store/Profile/actions";
// import Menu from "../Menu";
import "./NavBar.scss";

// const NotifcationTooltip = withStyles((theme) => ({
//   tooltip: {
//     color: theme.tooltip.backgroundOrange,
//     fontSize: "1rem"
//   },
//   arrow: {
//     fontSize: "1rem"
//   }
// }))(Tooltip);

const NavBar = () => {
  const dispatch = useDispatch();
  let location = useLocation();

  const lang = useSelector((state) => state.locale.lang);
  // const notificationsList = useSelector(
  //   (state) => state.notifications.fullList
  // );
  const wholesalerId = useSelector((state) => state.auth.login.id);
  const wholesalerLogo = useSelector(
    (state) => state.profile.details?.logo
  );
  const dir = lang === "ar" ? "rtl" : "ltr";
  const { navbar } = messages[lang];

  // const [unseenNotificationsCount, setUnseenNotificationsCount] =
  //   useState(0);
  // const [renderedNotifications, setRenderedNotification] = useState(
  //   []
  // );
  const [isProfileSelected, setIsProfileSelected] = useState(false);

  // useEffect(() => {
  //   dispatch(getAllNotificationsRequest());
  // }, []);

  useEffect(() => {
    setIsProfileSelected(location.pathname.includes("profile"));
  }, [location]);

  useEffect(() => {
    dispatch(viewProfileRequest(wholesalerId));
  }, [wholesalerId]);

  // useEffect(() => {
  //   // Get unseen notifications count
  //   let count = 0;
  //   notificationsList?.forEach((elt) => {
  //     if (!elt.seen) {
  //       count++;
  //     }
  //   });
  //   setUnseenNotificationsCount(count);

  //   splice the notifications to show only 4
  //   const notificationsCopy = [...notificationsList];
  //   setRenderedNotification(notificationsCopy.splice(0, 4));
  // }, [notificationsList]);

  // const handleGetNotifications = () => {
  //   dispatch(getAllNotificationsRequest());
  // };

  // const renderSingleNotification = (title, body, date) => (
  //   <div className="d-flex">
  //     <span>
  //       <NotificationBg />
  //     </span>
  //     <div className="me-3">
  //       <span className="mb-1 d-block notification-title font-bold fsize-15 fweight-700">
  //         {title}
  //       </span>
  //       <span className="mb-3 d-block notification-body fsize-15 fweight-500 font-medium">
  //         {body.length > 50 ? <>{body.substring(0, 50)} ...</> : body}
  //       </span>
  //       <span className="notification-date d-block fsize-14 fsize-14 fweight-500 font-medium">
  //         {formatDate(date, "DD MMM YYYY")}
  //       </span>
  //     </div>
  //   </div>
  // );

  // const getMenuItems = () =>
  //   renderedNotifications.map(
  //     ({ id, title, body, created_at, seen }) => ({
  //       id,
  //       content: renderSingleNotification(title, body, created_at),
  //       className: seen ? "seen-notification" : "unseen-notification",
  //       adorment: !seen ? (
  //         <NotifcationTooltip
  //           title={<div>{navbar.markRead}</div>}
  //           placement="left"
  //           arrow
  //         >
  //           <FiberManualRecord
  //             className="seen-adorment ms-3"
  //             onClick={(e) => handleNotificationSeen(e, id)}
  //           />
  //         </NotifcationTooltip>
  //       ) : null
  //     })
  //   );

  // const renderMenuController = () => (
  //   <div
  //     className="d-flex flex-column align-items-center ms-3 pointer"
  //     onClick={handleGetNotifications}
  //   >
  //     <div className="bg-gray-mid hei-40 wid-40 rounded-circle d-flex justify-content-center align-items-center notification-icon-wrapper">
  //       {unseenNotificationsCount ? (
  //         <div
  //           className={`notifications-count text-center ${unseenNotificationsCount >= 100 &&
  //             "fsize-11 large-count"
  //             }`}
  //         >
  //           {unseenNotificationsCount >= 100
  //             ? "+99"
  //             : unseenNotificationsCount}
  //         </div>
  //       ) : null}
  //       <EmptyNotificationsIcon />
  //     </div>
  //     <p className="fsize-10 text-gray font-regular">
  //       {navbar.notifications}
  //     </p>
  //   </div>
  // );

  // const renderMenuFooter = () => (
  //   <div className="py-4  text-center">
  //     <Link to={ROUTE_PATHS.notifications}>
  //       <p className="see-all-btn fsize-14 pointer mb-0">
  //         {navbar.seeAll}
  //       </p>
  //     </Link>
  //   </div>
  // );

  // const handleSelectNotification = (notificationId) => {
  //   const selectedNotification = notificationsList.find(
  //     (item) => item.id === notificationId
  //   );

  //   handleNotificationsClickActions(selectedNotification);
  // };

  // const handleNotificationSeen = (e, id) => {
  //   e.stopPropagation();
  //   setNotificationSeen(id);
  // };

  return (
    <div
      className="Navbar-container bg-white d-flex justify-content-between align-items-center"
      dir={dir}
    >
      <div className="d-flex">
        {/* <Menu
          menuController={renderMenuController()}
          menuItems={getMenuItems()}
          handleSelectItem={handleSelectNotification}
          menuFooter={renderMenuFooter()}
          menuFooterClass="notifications-Footer"
          menuPaperClass="notifications-list-wrapper"
          menuListClass="notifications-list"
        /> */}
        <Link to={ROUTE_PATHS.viewProfile}>
          <div className="d-flex flex-column align-items-center pointer">
            <div
              className={`bg-gray-mid hei-40 wid-40 rounded-circle d-flex justify-content-center align-items-center ${
                isProfileSelected ? "border border-secondary" : ""
              }`}
            >
              {wholesalerLogo ? (
                <div
                  style={{
                    backgroundImage: `url(${wholesalerLogo})`
                  }}
                  className="wholesaler-logo"
                ></div>
              ) : (
                <Person />
              )}
            </div>
            <p className="fsize-10 text-gray font-regular">
              {navbar.profile}
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
