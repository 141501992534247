import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import arLocale from "date-fns/locale/ar-SA";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import {
  InputLabel,
  IconButton,
  FormHelperText
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Clear } from "@material-ui/icons";

import messages from "../../assets/locale/messages";
import "./DatePicker.scss";

const DatePicker = ({
  isInlineLabel,
  dateLabel,
  timeLabel,
  id,
  name,
  labelClassName,
  required,
  inputWrapperClass,
  value,
  handleDateChange,
  disabled,
  disableFuture,
  disablePast,
  maxDate,
  minDate,
  isInputHasErr,
  helperTextClass,
  errMsg,
  helperText,
  inputClass,
  viewedDateFormat,
  fullWidth,
  placeholder,
  hasTimeInput
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { general } = messages[lang];

  const [selectedDate, setSelectedDate] = useState(null);
  const [invalidDate, setInvalidDate] = useState(false);

  // To handle any formatting on date
  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const onDatechange = (date) => {
    // handle date format if needed
    if (date == "Invalid Date") {
      setInvalidDate(true);
      setSelectedDate(date);
    } else {
      setInvalidDate(false);
      handleDateChange(date);
    }
  };

  const handleClear = () => {
    setSelectedDate(null);
    handleDateChange(null);
    setInvalidDate(false);
  };

  const dateInputLabel = (
    <>
      {dateLabel}
      {required ? <span className="err-asterisk">*</span> : ""}
    </>
  );

  const timeInputLabel = (
    <>
      {timeLabel}
      {required ? <span className="err-asterisk">*</span> : ""}
    </>
  );

  return (
    <div className={`date-picker-wrapper ${inputWrapperClass}`}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={arLocale}>
        <div className="d-flex justify-content-between">
          <div className="mx-3">
            <span>
              {!isInlineLabel && (
                <InputLabel
                  className={labelClassName}
                  htmlFor={id || name}
                >
                  {dateInputLabel}
                </InputLabel>
              )}
            </span>
            <KeyboardDatePicker
              id={id || name}
              name={name}
              required={required}
              autoOk
              variant="inline"
              inputVariant="filled"
              label={isInlineLabel && dateInputLabel}
              format={viewedDateFormat}
              value={selectedDate}
              onChange={onDatechange}
              allowKeyboardControl={false}
              disabled={disabled}
              disableFuture={disableFuture}
              disablePast={disablePast}
              disableToolbar={true}
              maxDate={maxDate || undefined}
              minDate={minDate || undefined}
              InputProps={{
                startAdornment: selectedDate ? (
                  <IconButton
                    className={`close-btn ${disabled ? "pe-4" : ""}`}
                    onClick={handleClear}
                  >
                    <Clear className={disabled ? "d-none" : ""} />
                  </IconButton>
                ) : null,
                readOnly: true
              }}
              error={invalidDate || isInputHasErr}
              fullWidth={fullWidth}
              className={`${inputClass} ${
                invalidDate || isInputHasErr ? "error-border" : ""
              }`}
              placeholder={placeholder}
              autoComplete="off"
            />
          </div>
          {hasTimeInput && (
            <div className="mx-3">
              <span>
                {!isInlineLabel && (
                  <InputLabel
                    className={labelClassName}
                    htmlFor={id || `${name}-time`}
                  >
                    {timeInputLabel}
                  </InputLabel>
                )}
              </span>
              <KeyboardTimePicker
                id="time-picker"
                label={isInlineLabel && timeInputLabel}
                value={selectedDate}
                disabled={disabled}
                onChange={onDatechange}
                KeyboardButtonProps={{
                  "aria-label": "Change Time"
                }}
                InputProps={{ readOnly: true }}
                keyboardIcon={<AccessTimeIcon />}
                allowKeyboardControl={false}
                inputVariant="filled"
                error={invalidDate || isInputHasErr}
                className={`${inputClass} ${
                  invalidDate || isInputHasErr ? "error-border" : ""
                }`}
              />
            </div>
          )}
        </div>
        <FormHelperText
          error={invalidDate || isInputHasErr}
          className={`${helperTextClass} font-medium fsize-12 text-end`}
        >
          {invalidDate
            ? general.invalidDate
            : isInputHasErr
            ? errMsg
            : helperText}
        </FormHelperText>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePicker;

DatePicker.propTypes = {
  dateLabel: PropTypes.string.isRequired,
  timeLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  handleDateChange: PropTypes.func.isRequired,
  isInlineLabel: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  isInputHasErr: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hasTimeInput: PropTypes.bool,
  id: PropTypes.string,
  labelClassName: PropTypes.string,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  inputClass: PropTypes.string,
  placeholder: PropTypes.string,
  errMsg: PropTypes.string,
  helperTextClass: PropTypes.string,
  helperText: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  viewedDateFormat: PropTypes.string
};

DatePicker.defaultProps = {
  value: null,
  viewedDateFormat: "dd/MM/yyyy"
};
