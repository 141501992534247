import { combineReducers } from "redux";
import locale from "./Lang/reducer";
import snackbar from "./Snackbar/reducers";
import loader from "./Loader/reducers";
import auth from "./Auth/reducers";
import warehouse from "./Warehouse/reducers";
import kuzloProducts from "./KuzloProducts/reducers";
import notifications from "./Notifications/reducers";
import wholesLerProducts from "./WholsalerProducts/reducers";
import profile from "./Profile/reducers";
import orders from "./Orders/reducer";
import routing from "./Routing/reducer";
import ToastMessage from "./ToastMessage/reducers";
import offers from "./Offers/reducers";

export default combineReducers({
  locale,
  snackbar,
  loader,
  auth,
  notifications,
  wholesLerProducts,
  profile,
  warehouse,
  kuzloProducts,
  orders,
  routing,
  ToastMessage,
  offers
});
