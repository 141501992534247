import * as types from "./types";

export const addWholesalerProductRequest = (data) => ({
  type: types.ADD_WHOLESALER_PRODUCT,
  payload: data
});

export const getWholesalerProductsRequest = (data) => ({
  type: types.GET_WHOLESALER_PRODUCTS_REQUEST,
  payload: data
});

export const getWholesalerProductsResponse = (data) => ({
  type: types.GET_WHOLESALER_PRODUCTS_RESPONSE,
  payload: data
});

export const deleteWholsalerProductRequest = (payload) => ({
  type: types.DELETE_WHOLESALER_PRODUCT_REQUEST,
  payload
});

export const getWholesalerProductRequest = (payload) => ({
  type: types.GET_WHOLESALER_PRODUCT_REQUEST,
  payload
});

export const getWholesalerProductResponse = (payload) => ({
  type: types.GET_WHOLESALER_PRODUCT_RESPONSE,
  payload
});

export const editWholesalerProductRequest = (payload) => ({
  type: types.EDIT_WHOLESALER_PRODUCT_REQUEST,
  payload
});

export const deleteWholsalerProductsRequest = (payload) => ({
  type: types.DELETE_WHOLESALER_PRODUCTS_REQUEST,
  payload
});

export const editWholsalerProductsPricesRequest = (payload) => ({
  type: types.EDIT_WHOLESALER_PRODUCTS_PRICES_REQUEST,
  payload
});

export const editWholsalerProductsInOutStockRequest = (payload) => ({
  type: types.EDIT_WHOLESALER_PRODUCTS_IN_OUT_STOCK_REQUEST,
  payload
});
