import { put, call, takeEvery } from "redux-saga/effects";
import * as types from "./types";
import * as apis from "../../network/apis/orders";
import {
  getOrderDetailsRequest,
  getOrderDetailsResponse,
  getOrderPDFResponse,
  getOrdersResponse,
  getRetailersResponse
} from "./actions";
import { showHideSnackbar } from "../Snackbar/actions";
import messages from "../../assets/locale/messages";

export function* getOrdersSaga({ payload }) {
  try {
    const response = yield call(apis.getOrders, payload);
    yield put(
      getOrdersResponse({
        list: response.data.orders,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* getOrderDetailsSaga({ payload }) {
  try {
    const response = yield call(apis.getOrderDetails, payload);
    yield put(getOrderDetailsResponse(response.data.order));
  } catch (err) {
    console.log(err);
  }
}

export function* getRetailersSaga() {
  try {
    const response = yield call(apis.getRetailers);
    yield put(getRetailersResponse(response.data.storenames));
  } catch (err) {
    console.log("err", err);
  }
}

export function* updateOrderRequest({ payload }) {
  try {
    yield call(apis.updateOrder, payload);
    yield put(getOrderDetailsRequest(payload.id));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["ar"].orders.viewOrder.editSuccess
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}
export function* getOrderPDFSaga({ payload }) {
  try {
    const response = yield call(apis.getOrderPDF, payload);
    yield put(getOrderPDFResponse(response.data.pdf_url));
  } catch (err) {
    console.log(err);
  }
}

export default function* OrdersSagas() {
  yield takeEvery(types.GET_ORDERS_REQUEST, getOrdersSaga);
  yield takeEvery(types.GET_RETAILERS_REQUEST, getRetailersSaga);
  yield takeEvery(
    types.GET_ORDER_DETAILS_REQUEST,
    getOrderDetailsSaga
  );
  yield takeEvery(types.UPDATE_ORDER_REQUEST, updateOrderRequest);
  yield takeEvery(types.GET_ORDER_PDF_REQUEST, getOrderPDFSaga);
}
