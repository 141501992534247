export const ADD_WHOLESALER_PRODUCT = "ADD_WHOLSALER_PRODUCT";

export const GET_WHOLESALER_PRODUCTS_REQUEST =
  "GET_WHOLSALER_PRODUCTS_REQUEST";
export const GET_WHOLESALER_PRODUCTS_RESPONSE =
  "GET_WHOLSALER_PRODUCTS_RESPONSE";

export const GET_WHOLESALER_PRODUCT_REQUEST =
  "GET_WHOLESALER_PRODUCT_REQUEST";
export const GET_WHOLESALER_PRODUCT_RESPONSE =
  "GET_WHOLESALER_PRODUCT_RESPONSE";

export const DELETE_WHOLESALER_PRODUCT_REQUEST =
  "DELETE_WHOLESALER_PRODUCT_REQUEST ";

export const EDIT_WHOLESALER_PRODUCT_REQUEST =
  "EDIT_WHOLESALER_PRODUCT_REQUEST";

export const DELETE_WHOLESALER_PRODUCTS_REQUEST =
  "DELETE_WHOLESALER_PRODUCTS_REQUEST";

export const EDIT_WHOLESALER_PRODUCTS_PRICES_REQUEST =
  "EDIT_WHOLESALER_PRODUCTS_PRICES_REQUEST";

export const EDIT_WHOLESALER_PRODUCTS_IN_OUT_STOCK_REQUEST =
  "EDIT_WHOLESALER_PRODUCTS_IN_OUT_STOCK_REQUEST";
