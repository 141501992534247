import React from "react";
import PropTypes from "prop-types";
import MaterialModal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";

import GoogleMaps from "../GoogleMaps";
import "./MapModal.scss";

const MapModal = ({
  open,
  handleClose,
  savedCoords,
  savedPolygon,
  modalHeaderLabel
}) => {
  return (
    <div>
      <MaterialModal open={open} onClose={handleClose}>
        <div className="map-location-wrapper">
          <div className="d-flex justify-content-between modal-header py-2 px-3">
            <p className="text-dark-blue mb-0 fsize-16 font_meduim ">
              {modalHeaderLabel}
            </p>
            <CloseIcon
              className="me-2 close-icon"
              onClick={handleClose}
            />
          </div>
          <GoogleMaps
            hasSearch={false}
            savedCoords={savedCoords}
            savedPolygon={savedPolygon}
            className="googleMap"
            viewOnly={true}
            isMarker={!!savedCoords.coords}
            isPolygon={!!savedPolygon.length}
          />
        </div>
      </MaterialModal>
    </div>
  );
};

export default MapModal;

MapModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  modalBody: PropTypes.element,
  savedCoords: PropTypes.object,
  savedPolygon: PropTypes.array,
  modalHeaderLabel: PropTypes.string
};
