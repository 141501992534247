import { put, call, takeEvery } from "redux-saga/effects";

import * as apis from "../../network/apis/KuzloProducts";
import {
  getCategoriesResponse,
  getKuzloProductsResponse,
  getProductResponse
} from "./actions";
import * as KuzloProductsTypes from "./types";

export function* getKuzloProductsSaga({ payload }) {
  try {
    const response = yield call(apis.getKuzloProducts, payload);
    yield put(
      getKuzloProductsResponse({
        list: response.data.products,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* getCategories({ payload }) {
  try {
    const response = yield call(apis.getCategories, payload);
    yield put(
      getCategoriesResponse(response.data.product_categories)
    );
  } catch (err) {
    console.log(err);
  }
}

export function* getProductSaga({ payload }) {
  try {
    const response = yield call(apis.getProduct, payload);
    yield put(getProductResponse(response.data.product));
  } catch (err) {
    console.log(err);
  }
}

export default function* KuzloProductsSagas() {
  yield takeEvery(
    KuzloProductsTypes.GET_KUZLO_PRODUCTS_REQUEST,
    getKuzloProductsSaga
  );
  yield takeEvery(
    KuzloProductsTypes.GET_CATEGORIES_REQUEST,
    getCategories
  );
  yield takeEvery(
    KuzloProductsTypes.GET_PRODUCT_REQUEST,
    getProductSaga
  );
}
