import React from "react";

export const Calendar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
    >
      <path
        fill="#ABABAB"
        d="M10.625 1.5h-1.25V.875a.625.625 0 00-1.25 0V1.5h-3.75V.875a.625.625 0 00-1.25 0V1.5h-1.25A1.875 1.875 0 000 3.375v7.5a1.875 1.875 0 001.875 1.875h8.75a1.875 1.875 0 001.875-1.875v-7.5A1.875 1.875 0 0010.625 1.5zm.625 9.375a.624.624 0 01-.625.625h-8.75a.625.625 0 01-.625-.625V6.5h10v4.375zm0-5.625h-10V3.375a.625.625 0 01.625-.625h1.25v.625a.625.625 0 001.25 0V2.75h3.75v.625a.625.625 0 001.25 0V2.75h1.25a.625.625 0 01.625.625V5.25z"
      ></path>
    </svg>
  );
};
