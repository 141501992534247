import { all } from "redux-saga/effects";
import AuthSagas from "./Auth/sagas";
import ProfileSagas from "./Profile/sagas";
import WarehouseSagas from "./Warehouse/sagas";
import KuzloProductsSagas from "./KuzloProducts/sagas";
import wholesLerProducts from "./WholsalerProducts/sagas";
import OrdersSagas from "./Orders/sagas";
import NotificationsSagas from "./Notifications/sagas";
import OffersSagas from "./Offers/sagas";

export default function* rootSaga() {
  yield all([
    AuthSagas(),
    ProfileSagas(),
    WarehouseSagas(),
    KuzloProductsSagas(),
    wholesLerProducts(),
    OrdersSagas(),
    NotificationsSagas(),
    OffersSagas()
  ]);
}
