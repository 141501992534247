import React from "react";
import PropTypes from "prop-types";
import {
  Table as MTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";

import "./Table.scss";

const Table = ({ headlines, rows, hasActions }) => {
  return (
    <TableContainer component={Paper} className="table-container">
      <MTable>
        <TableHead>
          <TableRow>
            {headlines.map((head) => (
              <TableCell
                className=" font-reguler fsize-14 w-20"
                key={head}
              >
                {head}
              </TableCell>
            ))}
            {hasActions && (
              <TableCell className=" font-reguler fsize-14 w-20"></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </MTable>
    </TableContainer>
  );
};

Table.propTypes = {
  headlines: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.node,
  hasActions: PropTypes.bool
};

Table.defaultProps = {
  headlines: [],
  hasActions: false
};

export default Table;
