import { axiosInstance } from "./";

const getKuzloProducts = async (params) => {
  return await axiosInstance.get("products", { params });
};

const getCategories = async (params) => {
  return await axiosInstance.get(`product_categories`, { params });
};

const getProduct = async (id) =>
  await axiosInstance.get(`products/${id}`);

export { getKuzloProducts, getCategories, getProduct };
