import React from "react";

export const Info = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#AC850C"
        d="M9 .25a8.75 8.75 0 100 17.5A8.75 8.75 0 009 .25zM9 4a.937.937 0 110 1.875A.937.937 0 019 4zm2.5 10.078h-5v-1.406h1.797V9.078H7.125V7.672h2.578v5H11.5v1.406z"
      ></path>
    </svg>
  );
};
