import {
  VIEW_PROFILE_RESPONSE,
  GET_DELIVERY_METHODS_RESPONSE
} from "./types";

const INITIAL_STATE = {
  details: null,
  deliveryMethodsList: []
};

const profile = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VIEW_PROFILE_RESPONSE:
      return { ...state, details: action.payload };
    case GET_DELIVERY_METHODS_RESPONSE:
      return {
        ...state,
        deliveryMethodsList: action.payload
      };
    default:
      return state;
  }
};

export default profile;
