import {
  ADD_PUSH_NOTIFICATION,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_RESPONSE,
  SET_NOTIFICATION_SEEN,
  GET_ALL_NOTIFICATIONS_REQUEST,
  GET_ALL_NOTIFICATIONS_RESPONSE
} from "./types";

export const getNotificationsRequest = (payload) => ({
  type: GET_NOTIFICATIONS_REQUEST,
  payload
});

export const getNotificationsResponse = (payload) => ({
  type: GET_NOTIFICATIONS_RESPONSE,
  payload
});

export const setNotificationSeenRequest = (payload) => ({
  type: SET_NOTIFICATION_SEEN,
  payload
});

export const getAllNotificationsRequest = () => ({
  type: GET_ALL_NOTIFICATIONS_REQUEST
});

export const getAllNotificationsResponse = (payload) => ({
  type: GET_ALL_NOTIFICATIONS_RESPONSE,
  payload
});

export const addPushNotification = (payload) => ({
  type: ADD_PUSH_NOTIFICATION,
  payload
});
