import * as types from "./types";

const INITIAL_STATE = {
  login:
    JSON.parse(localStorage.getItem("wholesaler")) ||
    JSON.parse(sessionStorage.getItem("wholesaler")) ||
    {}
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGIN_RESPONSE:
      return { ...state, login: action.payload };
    default:
      return state;
  }
};

export default authReducer;
