import { call, put, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/profile";
import * as actions from "./actions";
import { showHideSnackbar } from "../Snackbar/actions";
import { getDeliveryMethodsResponse } from "./actions";
import {
  EDIT_PROFILE_REQUEST,
  VIEW_PROFILE_REQUEST,
  GET_DELIVERY_METHODS_REQUEST
} from "./types";
import history from "../../routes/History";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import messages from "../../assets/locale/messages";

function* viewProfile({ payload }) {
  try {
    const response = yield call(apis.viewProfile, payload);
    yield put(actions.viewProfileResponse(response.data.wholesaler));
  } catch (error) {
    console.log("error", error);
  }
}

function* editProfile({ payload }) {
  try {
    yield call(apis.editProfile, payload);
    const lang = localStorage.getItem("lang") || "ar";
    yield put(
      showHideSnackbar({
        isOpen: true,
        message: messages[lang].profile.successMsg,
        type: "success"
      })
    );
    history.push(ROUTE_PATHS.viewProfile);
  } catch (error) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        message: error.response?.data?.message,
        type: "error"
      })
    );
  }
}

export function* getDeliveryMethodsSaga({ payload }) {
  try {
    const response = yield call(apis.getDeliveryMethods, payload);
    yield put(
      getDeliveryMethodsResponse(response.data.delivery_methods)
    );
  } catch (err) {
    console.log(err);
  }
}

export default function* ProfileSagas() {
  yield takeEvery(VIEW_PROFILE_REQUEST, viewProfile);
  yield takeEvery(EDIT_PROFILE_REQUEST, editProfile);
  yield takeEvery(
    GET_DELIVERY_METHODS_REQUEST,
    getDeliveryMethodsSaga
  );
}
