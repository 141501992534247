import React, { useState } from "react";
import { useSelector } from "react-redux";

import messages from "../../../../assets/locale/messages";
import Breadcrumb from "../../../../components/Breadcrumb";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import Tabs from "../../../../components/Tabs";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import EditPriceTabContent from "./EditPriceTabContent";
import ConvertAvailableTabCotnent from "./ConvertAvailableTabCotnent";
import ConvertUnavailableTabCotnent from "./ConvertUnavailableTabCotnent";
import DeleteTabContent from "./DeleteTabContent";
import { Available } from "../../../../utils/Icons/Available";
import { NonAvailable } from "../../../../utils/Icons/NonAvailable";
import { SolidDelete } from "../../../../utils/Icons/SolidDelete";
import "./QuickEdit.scss";

const QuickEdit = () => {
  const lang = useSelector((state) => state.locale.lang);

  const { products } = messages[lang];

  const [activeTab, setActiveTab] = useState(0);
  const [allowStopNavigation, setAllowStopNavigation] =
    useState(true);
  const [openNavigateConfirm, setOpenNavigateConfirm] =
    useState(false);
  const [nextTab, setNextTab] = useState();

  const tabs = [
    {
      label: products.quickEdit.editPrice,
      className: "tab1 fsize-15 font-semibold"
    },
    {
      label: (
        <div className="d-flex">
          {products.quickEdit.convertUnavailable}
          <NonAvailable className="me-3" />
        </div>
      ),
      className: "tabs2 fsize-15 font-semibold"
    },
    {
      label: (
        <div className="d-flex">
          {products.quickEdit.convertAvailable}
          <Available className="me-3" />
        </div>
      ),
      className: "tabs3 fsize-15 font-semibold"
    },
    {
      label: (
        <div className="d-flex">
          {products.quickEdit.delete} <SolidDelete className="me-3" />
        </div>
      ),
      className: "tabs4 fsize-15 font-semibold"
    }
  ];

  const handleCloseNavigateConfirmModal = () => {
    setOpenNavigateConfirm(false);
  };

  return (
    <div className="quick-edit-page-container position-relative">
      <div className="top-container d-flex justify-content-between">
        <Breadcrumb
          linksArray={[
            {
              link: ROUTE_PATHS.myProducts,
              name: products.myProducts
            },
            {
              name: products.quickEdit.buttonText
            }
          ]}
        />
      </div>

      <div className="quick-edit-content-container mt-5">
        <Tabs
          tabs={tabs}
          content={[
            <EditPriceTabContent
              key="1"
              handleNavigation={(value) => {
                setAllowStopNavigation(value);
              }}
            />,
            <ConvertUnavailableTabCotnent
              key="2"
              handleNavigation={(value) => {
                setAllowStopNavigation(value);
              }}
            />,
            <ConvertAvailableTabCotnent
              key="3"
              handleNavigation={(value) => {
                setAllowStopNavigation(value);
              }}
            />,
            <DeleteTabContent
              key="4"
              handleNavigation={(value) => {
                setAllowStopNavigation(value);
              }}
            />
          ]}
          handleChangeTabs={(tab) => {
            setActiveTab(tab);
          }}
          handleNavigationModal={(value) =>
            setOpenNavigateConfirm(value)
          }
          handleNextTab={(value) => {
            setNextTab(value);
          }}
          activeTab={activeTab}
          allowStopNavigation={allowStopNavigation}
          hasConfirmationMessage={true}
        />
        <Modal
          open={openNavigateConfirm}
          onClose={handleCloseNavigateConfirmModal}
          className="bulk-confirmation-modal"
          modalBody={
            <>
              <p>{products.quickEdit.navigationModalMessage}</p>
            </>
          }
          actionBtns={
            <div className="container px-4">
              <div className="row">
                <div className="col-md-6">
                  <div className=" mx-2 d-flex justify-content-center align-items-center">
                    <Button
                      label={products.quickEdit.discardChanges}
                      type="button"
                      labelClass="fsize-14 text-white"
                      onClick={() => {
                        setAllowStopNavigation(true);
                        setOpenNavigateConfirm(false);
                        setActiveTab(nextTab);
                      }}
                      block={true}
                      className="py-2"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="mx-2 d-flex justify-content-center align-items-center pointer"
                    onClick={handleCloseNavigateConfirmModal}
                  >
                    <div>
                      <p className="fsize-14 text-orange py-2">
                        {products.quickEdit.cancel}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default QuickEdit;
