import React from "react";

export const CustomPrevOffersClock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        stroke="#ABABAB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.7"
        d="M15 29c7.732 0 14-6.268 14-14S22.732 1 15 1 1 7.268 1 15s6.268 14 14 14z"
      ></path>
      <path
        stroke="#ABABAB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 7v8l4 4"
      ></path>
    </svg>
  );
};
