import React, { useState, useEffect, useCallback } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Visibility, FiberManualRecord } from "@material-ui/icons";
import debounce from "lodash.debounce";

import Filter from "../../components/Filter";
import SearchInput from "../../components/SearchInput";
import DatePicker from "../../components/DatePicker";
import EmptyState from "../../components/EmptyState";
import Table from "../../components/Table";
import Tabs from "../../components/Tabs";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import messages from "../../assets/locale/messages";
import { formatDate } from "../../utils/Helpers";
import { orderStatusOptions } from "../../utils/Constants";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import Export from "../../utils/Icons/Export";
import {
  getOrderPDFRequest,
  getOrderPDFResponse,
  getOrdersRequest,
  getRetailersRequest
} from "../../store/Orders/actions";
import { setOrdersRoute } from "../../store/Routing/actions";
import "./Orders.scss";

const Orders = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lang = useSelector((state) => state.locale.lang);
  const { orders } = messages[lang];
  const retailersNames = useSelector(
    (state) => state.orders.retailers
  );
  const list = useSelector((state) => state.orders.list);
  const meta = useSelector((state) => state.orders.meta);
  const ordersRoutingData = useSelector(
    (state) => state.routing.orders
  );
  const pdfUrl = useSelector((state) => state.orders.pdfUrl);
  const [page, setPage] = useState(ordersRoutingData?.page || 1);
  const [searchValue, setSearchValue] = useState(
    ordersRoutingData?.search || ""
  );
  const [searchQuery, setSearchQuery] = useState(
    ordersRoutingData?.search || ""
  );
  const [filterRetailer, setFilterRetailer] = useState(
    ordersRoutingData?.storeName || null
  );
  const [retailersOptions, setRetailersOptions] = useState([]);
  const [filterOrderStatus, setFilterOrderStatus] = useState(
    ordersRoutingData?.orderStatus || null
  );

  const [startDate, setStartDate] = useState(
    ordersRoutingData?.startDate || null
  );
  const [endDate, setEndDate] = useState(
    ordersRoutingData?.endDate || null
  );
  const [startDateErr, setStartDateErr] = useState(null);
  const [endDateErr, setEndDateErr] = useState(null);
  const [activeTab, setActiveTab] = useState(
    ordersRoutingData?.activeTab || 0
  );

  useEffect(() => {
    if (pdfUrl) {
      window.open(pdfUrl);
    }
    return () => {
      dispatch(getOrderPDFResponse(null));
    };
  }, [pdfUrl]);

  const checkEndAfterStart = () => {
    if (
      startDate &&
      endDate &&
      startDate.getTime() > endDate.getTime()
    ) {
      setStartDateErr(orders.viewOrder.minErrorMsg);
      setEndDateErr(orders.viewOrder.maxErrorMsg);
    } else {
      setStartDateErr(null);
      setEndDateErr(null);
    }
  };

  const updateSearchQuery = () => {
    setSearchValue(searchQuery);
  };
  const delayedSearchQuery = useCallback(
    debounce(updateSearchQuery, 500),
    [searchQuery]
  );
  useEffect(() => {
    delayedSearchQuery();
    // Cancel the debounce on useEffect cleanup.
    return delayedSearchQuery.cancel;
  }, [searchQuery, delayedSearchQuery]);

  useEffect(() => {
    dispatch(getRetailersRequest());
  }, []);

  useEffect(() => {
    checkEndAfterStart();
  }, [startDate, endDate]);

  useEffect(() => {
    const options = retailersNames.map((retailer, index) => ({
      id: index,
      label: retailer
    }));
    setRetailersOptions(options);
  }, [retailersNames]);

  useEffect(() => {
    getOrders(
      page,
      activeTab,
      filterOrderStatus?.value,
      filterRetailer?.label,
      searchValue,
      startDate,
      endDate
    );
  }, [page]);

  useEffect(() => {
    setSearchValue(ordersRoutingData?.search || "");
    setFilterOrderStatus(ordersRoutingData?.orderStatus || null);

    setFilterRetailer(ordersRoutingData?.storeName || null);
    setStartDate(ordersRoutingData?.startDate || null);
    setEndDate(ordersRoutingData?.endDate || null);
    setPage(ordersRoutingData?.page || 1);
    ordersRoutingData
      ? getOrders(
          ordersRoutingData.page,
          ordersRoutingData.activeTab,
          ordersRoutingData.orderStatus,
          ordersRoutingData.storeName,
          ordersRoutingData.search,
          ordersRoutingData.startDate,
          ordersRoutingData.endDate
        )
      : getOrders(1, activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      getOrders(
        ordersRoutingData?.page || 1,
        activeTab,
        filterOrderStatus?.value,
        filterRetailer?.label,
        searchValue,
        startDate,
        endDate
      );
      setPage(ordersRoutingData?.page || 1);
    }
  }, [searchValue]);

  useEffect(() => {
    getOrders(
      ordersRoutingData?.page || 1,
      activeTab,
      filterOrderStatus?.value,
      filterRetailer?.label,
      searchValue,
      startDate,
      endDate
    );
    setPage(ordersRoutingData?.page || 1);
  }, [filterOrderStatus, filterRetailer, startDate, endDate]);

  useEffect(() => {
    if (ordersRoutingData) {
      dispatch(setOrdersRoute(null));
    }
  }, [ordersRoutingData]);

  const getOrders = (
    pageNumber,
    isHistory,
    status,
    retailerName,
    search,
    from,
    to
  ) => {
    dispatch(
      getOrdersRequest({
        page: pageNumber,
        items: 10,
        is_history: !!isHistory,
        status,
        retailer_storename: retailerName,
        search_parameters: search,
        from_date: from?.toString(),
        to_date: to?.toString()
      })
    );
  };

  const tabs = [
    {
      label: orders.onGoing,
      className: "tab1 fsize-15 font-semibold"
    },
    {
      label: orders.history,
      className: "tabs2 fsize-15 font-semibold"
    }
  ];

  const renderTable = () => {
    if (
      list.length == 0 &&
      !searchValue &&
      !filterRetailer &&
      !filterOrderStatus &&
      !startDate &&
      !endDate
    ) {
      return (
        <div className="my-5">
          <EmptyState
            subTitle={orders.emptyState}
            showActionButton={false}
          />
        </div>
      );
    } else if (
      list.length == 0 &&
      (searchValue ||
        filterRetailer ||
        filterOrderStatus ||
        startDate ||
        endDate)
    ) {
      return (
        <div className="my-5">
          <EmptyState subTitle={orders.emptySearch} />;
        </div>
      );
    } else {
      return (
        <>
          <Table
            headlines={[
              orders.orderNumber,
              orders.warehouseName,
              orders.checkoutDate,
              orders.orderStatusPlaceholder
            ]}
            hasActions={true}
            rows={list.map((row, index) => (
              <TableRow key={index}>
                <TableCell className="font-reguler text-dark-blue fsize-14">
                  <span
                    className={`${
                      row.status == "pending" ? "pendingLabel" : ""
                    }`}
                  >
                    {row.order_number}
                  </span>
                </TableCell>
                <TableCell className="font-reguler text-dark-blue fsize-14 ">
                  {row.retailer_storename}
                </TableCell>

                <TableCell className="font-reguler text-dark-blue fsize-14 ">
                  {formatDate(row.created_at, "DD/MM/YYYY")}
                </TableCell>
                <TableCell className="font-reguler text-dark-blue fsize-14  ">
                  <span className={`${row.status} `}>
                    <FiberManualRecord className="fsize-12" />
                  </span>
                  {orders.filterOrder[row.status]}
                </TableCell>
                <TableCell className="font-reguler text-dark-blue d-flex  justify-content-start">
                  <div className="pt-2 d-flex flex-column align-items-center mx-3 ">
                    <Visibility
                      color="secondary"
                      fontSize="large"
                      className="opacity_7 pointer"
                      onClick={() => {
                        viewDetails(row.id);
                      }}
                    />
                    <span className="text-gray">
                      {orders.details}
                    </span>
                  </div>
                  <div className="pt-2 px-2 ms-3 d-flex flex-column align-items-center  ">
                    <Export
                      color="secondary"
                      fontSize="large"
                      className="opacity_7 pointer "
                      onClick={() => {
                        dispatch(getOrderPDFRequest(row.id));
                      }}
                    />
                    <span className="text-gray pt-2">
                      {orders.exportPdf}
                    </span>
                  </div>
                  {row?.pdf_url && (
                    <a
                      href={row?.pdf_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        outlined
                        label={orders.viewOrder.download}
                        labelClass="fsize-14 px-3"
                        className="mx-3 p-0 pdf mt-3"
                      />
                    </a>
                  )}
                </TableCell>
              </TableRow>
            ))}
          />
          <div className="d-flex justify-content-center align-items-center pt-4 pb-3">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
        </>
      );
    }
  };

  const viewDetails = (id) => {
    dispatch(
      setOrdersRoute({
        page,
        search: searchValue,
        storeName: filterRetailer,
        orderStatus: filterOrderStatus,
        startDate,
        endDate,
        activeTab
      })
    );
    history.push(ROUTE_PATHS.viewOrder.replace(":id", id));
  };
  return (
    <>
      <div className="filters-bar row mb-2">
        <div className="col-8">
          <div className="row">
            <div className="col-6">
              <SearchInput
                name="search"
                value={searchQuery}
                onChange={(value) => {
                  setSearchQuery(value);
                }}
                placeholder={orders.searchPlaceholder}
                inputWrapperClass="search me-2 px-0"
                inputClass="mt-2  "
              />
            </div>
            <div className="col-6 d-flex justify-content-between">
              <Filter
                placeholder={orders.retailerPlaceholder}
                options={retailersOptions}
                value={filterRetailer}
                name="retailer options"
                onChange={(value) => {
                  setFilterRetailer(value);
                }}
                inputClassName=" mt-1  ms-3"
                wrapperClassName="retailer-options"
              />
              <Filter
                placeholder={orders.orderStatusPlaceholder}
                options={orderStatusOptions}
                value={filterOrderStatus}
                name="order status"
                onChange={(value) => {
                  setFilterOrderStatus(value);
                }}
                inputClassName="mt-1 ms-2"
                wrapperClassName="w-50 order-status"
              />
            </div>
          </div>
        </div>
        <div className="col-4 d-flex justify-content-between date-picker">
          <DatePicker
            dateLabel={orders.startDate}
            inputWrapperClass="me-2"
            name="start Date"
            value={startDate}
            isInlineLabel
            handleDateChange={(value) => {
              setStartDate(value);
            }}
            maxDate={endDate}
            placeholder={formatDate(new Date(), "DD/MM/yyyy", "en")}
            isInputHasErr={!!startDateErr}
            errMsg={startDateErr}
          />
          <DatePicker
            dateLabel={orders.endDate}
            inputWrapperClass="me-2"
            name="end Date"
            value={endDate}
            isInlineLabel
            handleDateChange={(value) => {
              setEndDate(value);
            }}
            minDate={startDate}
            placeholder={formatDate(new Date(), "DD/MM/yyyy", "en")}
            isInputHasErr={!!startDateErr}
            errMsg={endDateErr}
          />
        </div>
      </div>
      <div className="content-wrapper orders-page">
        <Tabs
          tabs={tabs}
          content={[renderTable(), renderTable()]}
          handleChangeTabs={(tab) => {
            setActiveTab(tab);
          }}
          activeTab={ordersRoutingData?.activeTab}
          allowStopNavigation={true}
        />
      </div>
    </>
  );
};

export default Orders;
