import React from "react";

export const ImageOutlined = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    fill="none"
    viewBox="0 0 34 34"
    {...props}
  >
    <g
      stroke="#95AAD3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      opacity="0.5"
    >
      <path d="M26.917 4.25H7.083A2.833 2.833 0 004.25 7.083v19.834a2.833 2.833 0 002.833 2.833h19.834a2.833 2.833 0 002.833-2.833V7.083a2.833 2.833 0 00-2.833-2.833z"></path>
      <path d="M12.042 14.167a2.125 2.125 0 100-4.25 2.125 2.125 0 000 4.25zM29.75 21.25l-7.084-7.083L7.083 29.75"></path>
    </g>
  </svg>
);
