import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { TableCell, TableRow } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import debounce from "lodash.debounce";

import { getOffersRequest } from "../../store/Offers/actions";
import { setOffersRoute } from "../../store/Routing/actions";
import messages from "../../assets/locale/messages";
import OffersFiltersAndSearch from "./OffersFiltersAndSearch";
import EmptyState from "../../components/EmptyState";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import Switch from "../../components/Switch";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { formatDate } from "../../utils/Helpers";
import {
  offersStatus,
  offersSubStatus,
  offerActiveStatus,
  offerPendingStatus,
  offerExpiredStatus
} from "../../utils/Constants";
import "./Offers.scss";

const Offers = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader);
  const list = useSelector((state) => state.offers.list);
  const meta = useSelector((state) => state.offers.meta);
  const offersRoutingData = useSelector(
    (state) => state.routing.offers
  );
  const { offers, general } = messages[lang];
  const [filterStatus, setFilterStatus] = useState(
    offersRoutingData?.filterStatus || null
  );
  const [filterSubStatus, setFilterSubStatus] = useState(
    offersRoutingData?.filterSubStatus || null
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(offersRoutingData?.page || 1);

  const getOffers = (pageNumber, status, is_enabled, search) => {
    dispatch(
      getOffersRequest({
        page: pageNumber,
        items: 8,
        status,
        is_enabled,
        product_name: search
      })
    );
  };

  const updateSearchQuery = () => {
    setSearchValue(searchQuery);
  };
  const delayedSearchQuery = useCallback(
    debounce(updateSearchQuery, 500),
    [searchQuery]
  );
  useEffect(() => {
    delayedSearchQuery();
    // Cancel the debounce on useEffect cleanup.
    return delayedSearchQuery.cancel;
  }, [searchQuery, delayedSearchQuery]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      getOffers(
        page,
        filterStatus?.value,
        filterSubStatus?.value,
        searchValue
      );
    }
  }, [page, filterStatus, filterSubStatus, searchValue]);

  const checkOfferStatus = (status) => {
    if (offerActiveStatus.includes(status)) {
      return "active";
    } else if (offerPendingStatus.includes(status)) {
      return "pending";
    } else if (offerExpiredStatus.includes(status)) {
      return "expired";
    }
  };

  const handleViewClick = (id) => {
    dispatch(
      setOffersRoute({
        page,
        filterStatus,
        filterSubStatus,
        searchValue
      })
    );
    history.push(ROUTE_PATHS["viewOffer"].replace(":id", id));
  };

  if (
    !isLoading &&
    list.length == 0 &&
    !searchValue &&
    !filterStatus &&
    !filterSubStatus
  ) {
    return (
      <>
        <OffersFiltersAndSearch
          status={{
            options: offersStatus,
            value: filterStatus,
            onChangeHandler: (value) => {
              setFilterStatus(value);
              setPage(1);
            }
          }}
          subStatus={{
            options: offersSubStatus,
            value: filterSubStatus,
            onChangeHandler: (value) => {
              setFilterSubStatus(value);
              setPage(1);
            }
          }}
          search={{
            value: searchQuery,
            onChangeHandler: (value) => {
              setPage(1);
              setSearchQuery(value);
            }
          }}
        />

        <div className=" bg-white offers-page d-flex justify-content-center align-items-center flex-column m-4">
          <EmptyState
            subTitle={offers.emptyState}
            showActionButton={false}
          />
        </div>
      </>
    );
  } else if (
    list.length == 0 &&
    (searchValue || filterStatus || filterSubStatus)
  ) {
    return (
      <>
        <OffersFiltersAndSearch
          status={{
            options: offersStatus,
            value: filterStatus,
            onChangeHandler: (value) => {
              setFilterStatus(value);
              setPage(1);
            }
          }}
          subStatus={{
            options: offersSubStatus,
            value: filterSubStatus,
            onChangeHandler: (value) => {
              setFilterSubStatus(value);
              setPage(1);
            }
          }}
          search={{
            value: searchQuery,
            onChangeHandler: (value) => {
              setPage(1);
              setSearchQuery(value);
            }
          }}
        />
        <div className=" bg-white offers-page d-flex justify-content-center align-items-center flex-column m-4">
          <EmptyState
            subTitle={general.emptySearch}
            showActionButton={false}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <OffersFiltersAndSearch
          status={{
            options: offersStatus,
            value: filterStatus,
            onChangeHandler: (value) => {
              setFilterStatus(value);
              setPage(1);
            }
          }}
          subStatus={{
            options: offersSubStatus,
            value: filterSubStatus,
            onChangeHandler: (value) => {
              setFilterSubStatus(value);
              setPage(1);
            }
          }}
          search={{
            value: searchQuery,
            onChangeHandler: (value) => {
              setPage(1);
              setSearchQuery(value);
            }
          }}
        />
        <div className=" bg-white offers-page d-flex justify-content-between align-items-center flex-column mt-0 pt-4">
          <Table
            headlines={[
              offers.table.headlines.status,
              offers.table.headlines.title,
              offers.table.headlines.date,
              offers.table.headlines.time,
              offers.table.headlines.view,
              offers.table.headlines.enabled
            ]}
            hasActions={false}
            notHasSizeLimitaions={true}
            rows={list.map((row, index) => (
              <TableRow key={index}>
                <TableCell className="font-reguler text-dark-blue fsize-14">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div
                      className={`status-${checkOfferStatus(
                        row.status
                      )} px-3 py-2 border-radius-4`}
                    >
                      {offerActiveStatus.includes(row.status)
                        ? offers.active
                        : offerPendingStatus.includes(row.status)
                        ? offers.pending
                        : offers.expired}
                    </div>
                    {!row.is_in_stock && (
                      <div className="out-of-stock-label">
                        {offers.outOfStock}
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell className="font-cairo-medium text-orange fsize-14  ">
                  {row.name}
                </TableCell>
                <TableCell className="font-reguler gray-shade fsize-14  ">
                  <div className="d-flex flex-column dates-container mx-auto">
                    <p className="d-flex justify-content-between">
                      <span className="ms-3 gray-text ">
                        {offers.start}
                      </span>
                      <span className="font-light gray-dates">
                        {formatDate(
                          row.start_datetime,
                          "DD/MM/YYYY",
                          "en"
                        )}
                      </span>
                    </p>
                    <p className="mt-3 d-flex justify-content-between">
                      <span className="ms-3 gray-text ">
                        {offers.end}
                      </span>
                      <span className="font-light gray-dates">
                        {row?.expiration_date &&
                        row?.expiration_date !== row?.end_datetime
                          ? formatDate(
                              row.expiration_date,
                              "DD/MM/YYYY",
                              "en"
                            )
                          : formatDate(
                              row.end_datetime,
                              "DD/MM/YYYY",
                              "en"
                            )}
                      </span>
                    </p>
                  </div>
                </TableCell>
                <TableCell className="font-reguler gray-shade fsize-14  ">
                  <p>
                    <span className="font-light white-space-nowrap gray-dates">
                      {formatDate(
                        row.start_datetime,
                        "hh:mm A",
                        "en"
                      )}
                    </span>
                  </p>
                  <p className="mt-3">
                    <span className="font-light white-space-nowrap gray-dates">
                      {row?.expiration_date &&
                      row?.expiration_date !== row?.end_datetime
                        ? formatDate(
                            row.expiration_date,
                            "hh:mm A",
                            "en"
                          )
                        : formatDate(
                            row.end_datetime,
                            "hh:mm A",
                            "en"
                          )}
                    </span>
                  </p>
                </TableCell>
                <TableCell className="px-0 pt-2">
                  <div className="d-flex justify-content-center">
                    <Visibility
                      color="secondary"
                      fontSize="large"
                      className="opacity_7 pointer"
                      onClick={() => {
                        handleViewClick(row.id);
                      }}
                    />
                  </div>
                </TableCell>
                <TableCell className="px-0 pt-2">
                  <div className="d-flex justify-content-center switch-container">
                    <Switch
                      checked={row.is_enabled}
                      name="enabled"
                      onChange={() => {}}
                      disabled
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          />

          <div className="d-flex justify-content-center align-items-center pt-4 pb-3">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
        </div>
      </>
    );
  }
};

export default Offers;
