import React from "react";
import PropTypes from "prop-types";
import MaterialModal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";

import "./Modal.scss";

const Modal = ({
  open,
  onClose,
  modalBody,
  actionBtns,
  modalLabel,
  className
}) => {
  return (
    <MaterialModal open={open} onClose={onClose}>
      <div className={`ModalContainer ${className}`}>
        <CloseIcon
          className="closeIcon"
          fontSize="medium"
          onClick={onClose}
        />
        <span>{modalLabel}</span>
        <div className="body-container " id="modal">
          {modalBody}
        </div>
        {actionBtns && (
          <div className="mb-3 px-4 pt-3 text-center">
            {actionBtns}
          </div>
        )}
      </div>
    </MaterialModal>
  );
};

export default Modal;

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  modalBody: PropTypes.element,
  actionBtns: PropTypes.element,
  modalLabel: PropTypes.element,
  className: PropTypes.string
};
