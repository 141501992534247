import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import messages from "../../assets/locale/messages";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import "./AuthLayout.scss";

const AuthLayout = ({ children, showWelcomeTo, showCard }) => {
  const history = useHistory();
  const lang = useSelector((state) => state.locale.lang);
  const { auth } = messages[lang];

  return (
    <div
      className={`container d-flex justify-content-center ${
        showCard ? "align-items-center vh-100" : "pt-5"
      }`}
    >
      <img
        src={process.env.REACT_APP_AUTH_SCREEN_SIDE_LEFT_IMAGE}
        alt="provider-logo"
        className="k-logo"
      />
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex">
          {showWelcomeTo && (
            <h3 className="title fsize-36 ms-3 d-flex align-items-center">
              {auth.welcome}
            </h3>
          )}
          <img
            src={process.env.REACT_APP_AUTH_SCREEN_LOGO}
            alt="provider-logo"
            className="kuzlo-logo pointer"
            onClick={() => {
              history.push(ROUTE_PATHS.login);
            }}
          />
        </div>
        {showCard ? (
          <div className="auth-card">{children}</div>
        ) : (
          children
        )}
      </div>
      <img
        src={process.env.REACT_APP_AUTH_SCREEN_SIDE_RIGHT_IMAGE}
        alt="provider-logo"
        className="z-logo"
      />
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
  showWelcomeTo: PropTypes.bool,
  showCard: PropTypes.bool
};

AuthLayout.defaultProps = {
  showWelcomeTo: false,
  showCard: true
};
export default AuthLayout;
