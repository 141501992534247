import { axiosInstance } from "./";

const getOrders = async (params) =>
  await axiosInstance.get(`orders`, { params });

const getOrderDetails = async (id) =>
  await axiosInstance.get(`orders/${id}`);

const getRetailers = async () => axiosInstance.get(`retailers`);

const updateOrder = async ({ data, id }) =>
  await axiosInstance.patch(`orders/${id}`, data);

const getOrderPDF = async (id) =>
  await axiosInstance.get(`orders/${id}/export_pdf`);

export {
  getOrders,
  getRetailers,
  getOrderDetails,
  updateOrder,
  getOrderPDF
};
