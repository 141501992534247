import React from "react";
import PropTypes from "prop-types";
import Pagination from "@material-ui/lab/Pagination";

import "./Pagination.scss";
const PaginationComponent = ({
  count,
  defaultPage,
  handleChange,
  page
}) => {
  return (
    <div className="pagination-wrapper">
      <Pagination
        hidePrevButton
        hideNextButton
        onChange={handleChange}
        count={count}
        defaultPage={defaultPage}
        page={page}
      />
    </div>
  );
};

PaginationComponent.propTypes = {
  count: PropTypes.number,
  defaultPage: PropTypes.number,
  page: PropTypes.number,
  handleChange: PropTypes.func
};

PaginationComponent.defaultProps = {
  count: 0,
  defaultPage: 1,
  page: 1,
  handleChange: () => {}
};
export default PaginationComponent;
