import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";

import { getWholesalerProductsRequest } from "../../../../../store/WholsalerProducts/actions";
import { getCategoriesRequest } from "../../../../../store/KuzloProducts/actions";
import { editWholsalerProductsInOutStockRequest } from "../../../../../store/WholsalerProducts/actions";
import messages from "../../../../../assets/locale/messages";
import Filter from "../../../../../components/Filter";
import SearchInput from "../../../../../components/SearchInput";
import Pagination from "../../../../../components/Pagination";
import Table from "../../../../../components/Table";
import Checkbox from "../../../../../components/Checkbox";
import EmptyState from "../../../../../components/EmptyState";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";
import "../QuickEdit.scss";

const ConvertUnavailableTabCotnent = ({ handleNavigation }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const list = useSelector((state) => state.wholesLerProducts.list);
  const meta = useSelector((state) => state.wholesLerProducts.meta);
  const categories = useSelector(
    (state) => state.kuzloProducts.categories
  );
  const productsRoutingData = useSelector(
    (state) => state.routing.wholesalerProducts
  );

  const { products } = messages[lang];

  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [searchValue, setSearchValue] = useState(
    productsRoutingData?.search || ""
  );

  const [category, setCategory] = useState(
    productsRoutingData?.category || null
  );

  const [page, setPage] = useState(productsRoutingData?.page || 1);

  const [productsArr, setProductsArr] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [openConvertConfirm, setOpenConvertConfirm] = useState(false);

  const getWholsalerProducts = (pageNumber, search, categotyId) => {
    dispatch(
      getWholesalerProductsRequest({
        page: pageNumber,
        items: 12,
        query: search,
        product_category_id: categotyId || undefined,
        is_in_stock: true,
        activated: true
      })
    );
  };

  useEffect(() => {
    getWholsalerProducts(page, searchValue, category?.id);
  }, [page]);

  useEffect(() => {
    const options = categories.map((category) => ({
      id: category.id,
      label: category.name
    }));
    setCategoriesOptions(options);
  }, [categories]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      getWholsalerProducts(
        productsRoutingData?.page || 1,
        searchValue,
        category?.id
      );
      setPage(productsRoutingData?.page || 1);
    }
  }, [searchValue, category]);

  useEffect(() => {
    dispatch(
      getCategoriesRequest({
        page: -1,
        items: 1
      })
    );
  }, []);

  useEffect(() => {
    if (list) {
      let checkedArray = list?.map((product) => {
        return { ...product, checked: false };
      });
      if (selectedProduct?.length > 0 && checkedArray?.length > 0) {
        selectedProduct?.forEach((pr) => {
          const index = checkedArray.findIndex((pro) => pro.id == pr);
          if (index > -1) {
            checkedArray[index].checked = true;
          }
        });
      }
      setProductsArr(checkedArray);
    }
  }, [list]);

  useEffect(() => {
    if (selectedProduct.length > 0) {
      handleNavigation(false);
    } else {
      handleNavigation(true);
    }
  }, [selectedProduct]);

  const selectConvertStockProduct = (id, checked) => {
    const index = productsArr.findIndex(
      (product) => product.id === id
    );
    productsArr[index].checked = checked;
    setProductsArr([...productsArr]);
    if (checked) {
      selectedProduct.push(id);
      setSelectedProduct([...selectedProduct]);
    } else {
      const unCheckedProductIndex = selectedProduct.findIndex(
        (product) => product.id === id
      );
      selectedProduct.splice(unCheckedProductIndex, 1);
      setSelectedProduct([...selectedProduct]);
    }
  };

  const handleConvertButtonClick = () => {
    setOpenConvertConfirm(true);
  };

  const handleCloseConvertConfirmModal = () => {
    setOpenConvertConfirm(false);
  };

  const convertNotAvailable = () => {
    dispatch(
      editWholsalerProductsInOutStockRequest({
        wholesalers_product_ids: selectedProduct,
        is_in_stock: false,
        page: page,
        query: searchValue,
        product_category_id: category?.id
      })
    );
    setSelectedProduct([]);
    handleNavigation(true);
    setOpenConvertConfirm(false);
  };

  return (
    <div className="content-wrapper min-height-tab pt-5 pb-3 px-4">
      <div className="tabs-search-and-filter-container d-flex justify-content-end position-absolute">
        <Filter
          placeholder={products.filter}
          options={categoriesOptions}
          name="Category"
          onChange={(value) => {
            setCategory(value);
            setPage(1);
          }}
          value={category}
          inputClassName="mt-1 ms-3"
        />
        <SearchInput
          name="search"
          value={searchValue}
          onChange={(value) => {
            setSearchValue(value);
          }}
          placeholder={products.search}
        />
      </div>
      {list?.length > 0 ? (
        <>
          <div className="top-counter d-flex fsize-16">
            <span className="gray-text-color">
              {products.quickEdit.selectedProducts}
            </span>
            <span className="mx-3">{selectedProduct?.length}</span>
            <span className="text-red-color">
              {products.quickEdit.thisItemsWillBeNotAvailable}
            </span>
          </div>
          <div className="bulk-convert-products-table mt-4 w-65 mb-3 min-height-tab">
            <Table
              headlines={[
                "",
                products.quickEdit.tableTitles.productName,
                products.quickEdit.tableTitles.productCategory,
                products.quickEdit.tableTitles.price
              ]}
              rows={productsArr?.map((row, index) => (
                <TableRow key={`"delete-table-row${index}`}>
                  <TableCell className="font-medium  text-dark-blue fsize-14   d-flex">
                    <Checkbox
                      color="secondary"
                      id={`selectedProductToBeDeleted${row.id}`}
                      name={`selectedProductToBeDeletedName${row.id}`}
                      checked={row.checked || false}
                      onChange={(checked) => {
                        selectConvertStockProduct(row.id, checked);
                      }}
                    />
                  </TableCell>
                  <TableCell className="font-medium  text-dark-blue fsize-14 ">
                    {row.product.concatenated_name}
                  </TableCell>
                  <TableCell className="font-medium  text-dark-blue fsize-14 ">
                    {row.product.super_product.product_category.name}
                  </TableCell>
                  <TableCell className="font-medium  fsize-14 text-orange">
                    {row.price}
                    <span className="px-2">
                      {products.quickEdit.le}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center pt-4 pb-3 pagination-wrapper">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
          <div className="d-flex justify-content-center mt-2 mb-4">
            <Button
              label={
                <span className="d-flex align-items-center">
                  <span className="fsize-16">
                    {products.quickEdit.convertToNotAvailable}
                  </span>
                </span>
              }
              className="text-white py-2 px-5 w-25 background-orange"
              disabled={selectedProduct?.length === 0}
              onClick={() => {
                handleConvertButtonClick();
              }}
            />
            <Modal
              open={openConvertConfirm}
              onClose={handleCloseConvertConfirmModal}
              className="bulk-confirmation-modal"
              modalBody={
                <p>
                  {
                    products.quickEdit
                      .convertToUnavailableConfirmationMessage
                  }
                </p>
              }
              actionBtns={
                <div className="container px-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className=" mx-2 d-flex justify-content-center align-items-center">
                        <Button
                          label={products.quickEdit.convert}
                          type="button"
                          labelClass="fsize-14 text-white"
                          onClick={convertNotAvailable}
                          block={true}
                          className="py-2"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="mx-2 d-flex justify-content-center align-items-center pointer"
                        onClick={handleCloseConvertConfirmModal}
                      >
                        <div>
                          <p className="fsize-14 text-orange py-2">
                            {products.quickEdit.cancel}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </>
      ) : (
        <div className=" bg-white  d-flex justify-content-center align-items-center flex-column m-4">
          <EmptyState
            subTitle={products.quickEdit.noResultFound}
            showActionButton={false}
          />
        </div>
      )}
    </div>
  );
};

export default ConvertUnavailableTabCotnent;

ConvertUnavailableTabCotnent.propTypes = {
  handleNavigation: PropTypes.func
};
