import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  orderDetails: {},
  retailers: [],
  pdfUrl: null
};

const ordersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_ORDERS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };

    case types.GET_ORDER_DETAILS_RESPONSE:
      return { ...state, orderDetails: action.payload };

    case types.GET_RETAILERS_RESPONSE:
      return { ...state, retailers: action.payload };
    case types.GET_ORDER_PDF_RESPONSE:
      return { ...state, pdfUrl: action.payload };
    default:
      return state;
  }
};

export default ordersReducer;
