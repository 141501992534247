import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import messages from "../../../assets/locale/messages";
import Modal from "../../../components/Modal";
import DatePicker from "../../../components/DatePicker";
import Switch from "../../../components/Switch";
import OfferProductCard from "../OfferProductCard";
import PreviousOffersCard from "../PreviousOffers/Card";
import PreviousOffersModal from "../PreviousOffers/Modal";
import {
  offerActiveStatus,
  offerPendingStatus,
  offerExpiredStatus
} from "../../../utils/Constants";
import { formatDate } from "../../../utils/Helpers";
import {
  getPreviousProductOffersRequest,
  getOfferRequest,
  getOfferResponse
} from "../../../store/Offers/actions";
import "./ViewOffer.scss";

const ViewOffer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const offer = useSelector((state) => state.offers.offer);
  const { offers } = messages[lang];
  const [openPreviousOfferModal, setOpenPreviousOfferModal] =
    useState(false);
  const [previousOffer, setPreviousOffer] = useState(null);
  const previousProductOffers = useSelector(
    (state) => state.offers.previousProductOffers
  );

  useEffect(() => {
    if (id) {
      dispatch(getOfferRequest(id));
      dispatch(
        getPreviousProductOffersRequest({
          offer_id: id
        })
      );
    }
    return () => {
      dispatch(getOfferResponse(null));
    };
  }, [id]);

  const handleClosePreviousOfferModal = () => {
    setOpenPreviousOfferModal(false);
  };

  const checkOfferStatus = (status) => {
    if (offerActiveStatus.includes(status)) {
      return "active";
    } else if (offerPendingStatus.includes(status)) {
      return "pending";
    } else if (offerExpiredStatus.includes(status)) {
      return "expired";
    }
  };

  const handleViewPreviousOrderClick = (prevOffer) => {
    setPreviousOffer(prevOffer);
    setOpenPreviousOfferModal(true);
  };

  return (
    <div className="view-offer-page">
      <div className="bg-white content-container">
        <div className="row mx-0">
          <div className="col-8 px-0">
            <div className="row  view-top-container">
              <div className="col-8 px-0">
                <div className="d-flex justify-content-start align-items-center top-container-content">
                  <div className="d-flex justify-content-start">
                    <div className="position-relative">
                      <div
                        className={`status-general status-${checkOfferStatus(
                          offer?.status
                        )} border-radius-4`}
                      >
                        {offerActiveStatus.includes(offer?.status)
                          ? offers.active
                          : offerPendingStatus.includes(offer?.status)
                          ? offers.pending
                          : offers.expired}
                      </div>
                      {!offer?.wholesalers_product?.is_in_stock && (
                        <div className="ms-2 out-of-stock-label">
                          {offers.outOfStock}
                        </div>
                      )}
                    </div>
                  </div>
                  <p className="my-0 ms-4 fweight-700 fsize-24">
                    {offer?.name}
                  </p>
                </div>
              </div>
            </div>
            <div className=" d-flex ms-1 align-items-center flex-nowrap">
              <div className="lables-style ps-0  pt-2 px-0">
                {offers.viewOffer.product}
              </div>
              <div className="pt-2">
                <div className="product-name-inside-offer-view">
                  <p className=" mb-0 product-name">{offer?.name}</p>
                </div>
              </div>
            </div>
            <div className="d-flex ms-1 align-items-start flex-nowrap mt-4 pt-3">
              <div className="lables-style ps-0 pt-2 px-0">
                {offers.viewOffer.discountVlaue}
              </div>
              <div className="">
                <div className="ms-4 pt-1 d-flex flex-column justif-content-start align-items-start offer-value-mega-container ">
                  <p className="fsize-16 text-dark-blue mb-0 pe-2">
                    {offer?.discount_type === "percentage"
                      ? offers.viewOffer.percentage
                      : offers.viewOffer.amount}
                  </p>
                  <div className="mt-2 w-100 d-flex justify-content-center align-items-center offer-value-container ">
                    <div className="w-90 d-flex justify-content-center align-items-center me-1 fsize-16 offer-value-content ">
                      {offer?.discount_value}
                    </div>
                    <span>
                      {offer?.discount_type === "percentage"
                        ? "%"
                        : offers.viewOffer.le}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="dividerLine my-4"></div>
            <div className="d-flex ms-1 align-items-start flex-nowrap mt-5 mb-4 start-date-container">
              <div className="lables-style ps-0 pt-13px px-0">
                {offers.viewOffer.startDateAndTime}
              </div>
              <div className="date-and-time-picker-container">
                <DatePicker
                  dateLabel={offers.viewOffer.startDate}
                  timeLabel={offers.viewOffer.startTime}
                  isInlineLabel
                  inputWrapperClass="me-3 date offer-date-time-picker"
                  name="start_datetime"
                  value={offer?.start_datetime}
                  disabled={true}
                  hasTimeInput
                />
              </div>
            </div>
            <div className="d-flex ms-1 align-items-start flex-nowrap mt-5 mb-5 end-date-container">
              <div className="lables-style ps-0 pt-13px px-0">
                {offers.viewOffer.endDateAndTime}
              </div>
              <div className="date-and-time-picker-container">
                <DatePicker
                  dateLabel={offers.viewOffer.endDate}
                  timeLabel={offers.viewOffer.endTime}
                  isInlineLabel
                  inputWrapperClass="me-3 date offer-date-time-picker"
                  name="end_datetime"
                  value={offer?.end_datetime}
                  disabled={true}
                  hasTimeInput
                />
              </div>
            </div>
            <div className="dividerLine my-4"></div>
            {offer?.expiration_date &&
              offer?.expiration_reason &&
              offer?.expiration_date !== offer?.end_datetime && (
                <div className="expiration-date-reason-container">
                  <div className="d-flex ms-1 align-items-start flex-nowrap pt-4 align-items-center">
                    <div className="text-gray-title ps-0 ms-5">
                      {offers.viewOffer.expirationDate}
                    </div>
                    <div className="text-gray-title ps-3 fsize-16 direction-ltr">
                      {formatDate(
                        offer?.expiration_date,
                        "DD/MM/YYYY hh:mm A",
                        "en"
                      )}
                    </div>
                  </div>
                  <div className="d-flex ms-1 mb-5 mt-4 align-items-start flex-nowrap pt-3 align-items-center">
                    <div className="text-gray-title ps-0 ms-5">
                      {offers.viewOffer.expirationReason}
                    </div>
                    <div className="text-gray-title ps-3 fsize-16">
                      {offer?.expiration_reason}
                    </div>
                  </div>
                  <div className="dividerLine my-4"></div>
                </div>
              )}
          </div>
          <div className="col-4">
            {!offerExpiredStatus.includes(offer?.status) && (
              <div className="px-5 pt-4 pb-3 swich-container">
                <div className="d-flex flex-column align-items-center me-3">
                  <Switch
                    checked={offer?.is_enabled === true}
                    name="enabled"
                    onChange={() => {}}
                  />
                  <span className="text-gray mt-1 mx-2">
                    {offer?.is_enabled
                      ? offers.viewOffer.enabled
                      : offers.viewOffer.disabled}
                  </span>
                </div>
              </div>
            )}
            <div className="d-flex pt-5 pb-3 justify-content-start flex-column align-items-center product-preview ">
              {offer && <OfferProductCard offerProduct={offer} />}

              {previousProductOffers.length > 0 && (
                <div className="prev-product-offers mt-5 mb-4">
                  <p className="prev-product-offers-qoute text-product-card-blue fsize-14 mb-3">
                    {offers.viewOffer.prevProductOffersQoute}
                  </p>
                  {previousProductOffers?.map((offer, index) => (
                    <div key={`prev-offer-${index}`}>
                      <PreviousOffersCard
                        prevOffer={offer}
                        handleViewDetails={() => {
                          handleViewPreviousOrderClick(offer);
                        }}
                      />
                    </div>
                  ))}
                  <Modal
                    open={openPreviousOfferModal}
                    onClose={handleClosePreviousOfferModal}
                    className="prevoffer-modal-view-offer-page"
                    modalBody={
                      <PreviousOffersModal
                        prevOffer={previousOffer}
                        closeModal={handleClosePreviousOfferModal}
                      />
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOffer;
