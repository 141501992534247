import * as React from "react";

export const WarehouseIcon = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.856 18.782v-3.067c0-.777-.632-1.408-1.414-1.413H8.567c-.786 0-1.423.633-1.423 1.413v3.058c0 .674-.548 1.222-1.227 1.227h-1.96a3.46 3.46 0 0 1-2.444-1A3.41 3.41 0 0 1 .5 16.579V7.866c0-.735.328-1.431.895-1.902L8.057.673a3.115 3.115 0 0 1 3.958.071l6.518 5.219c.594.457.95 1.156.967 1.902v8.703c0 1.895-1.547 3.431-3.456 3.431h-1.916a1.231 1.231 0 0 1-.873-.354 1.213 1.213 0 0 1-.363-.864h-.036Z"
      fill="#fff"
    />
  </svg>
);
