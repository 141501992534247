import React from "react";

export const QuickEdit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="24"
    fill="none"
    viewBox="0 0 18 24"
  >
    <path
      fill="#fff"
      d="M16.567 20.933H1.255c-.29 0-.569.122-.774.341-.205.22-.32.516-.32.825 0 .31.115.607.32.825.205.219.484.342.774.342h15.312c.29 0 .568-.123.773-.342.206-.218.32-.515.32-.825 0-.31-.114-.606-.32-.825a1.06 1.06 0 00-.773-.341zM1.255 18.599h.098l4.561-.443c.5-.053.967-.288 1.323-.665l9.844-10.5c.382-.43.589-1.005.574-1.598a2.31 2.31 0 00-.65-1.564L14.008.633A2.119 2.119 0 0012.568 0a2.105 2.105 0 00-1.47.55l-9.843 10.5C.9 11.43.68 11.93.63 12.463l-.47 4.865c-.015.17.006.343.06.504.056.161.143.308.257.429.102.108.223.194.357.252.133.058.276.087.42.086zM12.487 2.266l2.986 3.185-2.187 2.275-2.931-3.127 2.132-2.333z"
    ></path>
  </svg>
);
