import React from "react";

export const Offers = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
    >
      <path
        fill="#fff"
        d="M1.09 9.58l9-9c.36-.36.86-.58 1.41-.58h7c1.1 0 2 .9 2 2v7c0 .55-.22 1.05-.59 1.42l-9 9c-.36.36-.86.58-1.41.58-.55 0-1.05-.22-1.41-.59l-7-7C.72 12.05.5 11.55.5 11c0-.55.23-1.06.59-1.42zM17 5c.83 0 1.5-.67 1.5-1.5S17.83 2 17 2s-1.5.67-1.5 1.5S16.17 5 17 5z"
      ></path>
    </svg>
  );
};
