import {
  ADD_PUSH_NOTIFICATION,
  GET_NOTIFICATIONS_RESPONSE,
  GET_ALL_NOTIFICATIONS_RESPONSE
} from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  fullList: []
};

const notifications = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_RESPONSE: {
      const notificationsList = [...state.list];
      action.payload.list.forEach((elt) => {
        if (
          notificationsList.findIndex((el) => el.id === elt.id) === -1
        ) {
          notificationsList.push(elt);
        }
      });
      return {
        ...state,
        list: action.payload.clear ? [] : [...notificationsList],
        meta: action.payload.meta
      };
    }
    case GET_ALL_NOTIFICATIONS_RESPONSE:
      return {
        ...state,
        fullList: [...action.payload.list]
      };
    case ADD_PUSH_NOTIFICATION:
      return {
        ...state,
        fullList: [
          { ...action.payload, id: state.fullList[0]?.id + 1 },
          ...state.fullList
        ],
        list: [
          { ...action.payload, id: state.list[0]?.id + 1 },
          ...state.list
        ]
      };
    default:
      return state;
  }
};

export default notifications;
