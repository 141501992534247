import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { FormHelperText } from "@material-ui/core";

import messages from "../../../assets/locale/messages";
import {
  editProfileRequest,
  viewProfileRequest,
  getDeliveryMethodsRequest
} from "../../../store/Profile/actions";
import { wholesalerPaymentMethods } from "../../../utils/Constants";
import { EgyptFlag } from "../../../utils/Icons/EgyptFlag";
import { ImageOutlined } from "../../../utils/Icons/ImageOutlined";
import { directories, uploadToS3 } from "../../../utils/S3";
import { PRICE_REGEX } from "../../../utils/Patterns";
import Input from "../../../components/Input";
import Checkbox from "../../../components/Checkbox";
import Button from "../../../components/Button";
import Upload from "../../../components/Upload";
import { editProfileSchema, getProfileFormData } from "./validations";
import "./EditProfile.scss";

const EditProfile = () => {
  const dispatch = useDispatch();

  const profileDetails = useSelector(
    (state) => state.profile.details
  );
  const wholesalerId = useSelector((state) => state.auth.login.id);
  const lang = useSelector((state) => state.locale.lang);
  const deliveryMethodsList = useSelector(
    (state) => state.profile.deliveryMethodsList
  );
  const { profile, general } = messages[lang];

  const [rawImg, setRawImg] = useState();
  // flag to upload image in edit mode if image changed only
  const [imgChanged, setImgChanged] = useState(false);

  useEffect(() => {
    dispatch(viewProfileRequest(wholesalerId));
    dispatch(
      getDeliveryMethodsRequest({
        page: -1
      })
    );
  }, []);

  useEffect(() => {
    if (profileDetails) {
      const { delivery_methods, payment_methods } = profileDetails;
      setValues(
        getProfileFormData({
          ...profileDetails,
          delivery_method_ids: delivery_methods.map(
            (method) => method.id
          ),
          payment_method_ids: payment_methods.map(
            (method) => method.id
          )
        })
      );
    }
  }, [profileDetails]);

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    setValues
  } = useFormik({
    initialValues: getProfileFormData(),
    validationSchema: editProfileSchema,
    onSubmit: async (values) => {
      let userData = getProfileFormData(values);
      if (values.logo) {
        if (imgChanged) {
          const data = await uploadToS3(
            values.logo,
            `${values.logo.name
              .replace(/\s/g, "")
              .replace(/(\(|\))/g, "")}_${new Date().getTime()}`,
            directories.wholesaler_portal
          );
          userData.logo = data?.link;
        } else {
          userData.logo = values.logo;
        }
      }
      dispatch(
        editProfileRequest({ id: wholesalerId, data: userData })
      );
    }
  });
  const renderTextInput = (
    name,
    localKey,
    type = "text",
    isRequired = true,
    isPhone
  ) => (
    <div
      className={`${
        name === "min_delivery_order_price" &&
        "d-flex align-items-center"
      }`}
    >
      <Input
        name={name}
        label={profile[localKey]}
        type={type}
        disabled={name == "email" || name == "incontact_email"}
        required={isRequired}
        placeholder={
          name !== "min_delivery_order_price" &&
          profile.enterPlaceholder.replace(
            "{{field}}",
            profile[localKey]
          )
        }
        onChange={(value) => {
          setFieldTouched(name);
          if (isPhone) {
            if (value.split("").every((num) => !isNaN(num))) {
              setFieldValue(name, value);
            }
          } else if (name === "min_delivery_order_price") {
            if (value.match(PRICE_REGEX) || value === "") {
              setFieldValue(name, value);
            }
          } else {
            setFieldValue(name, value);
          }
        }}
        value={values[name]}
        inputWrapperClass={`mb-4 ${
          name === "min_delivery_order_price" && "price-input"
        }`}
        labelClassName="text-dark font-medium mb-2"
        isInputHasErr={!!(touched[name] && errors[name])}
        errMsg={errors[name]}
        endAdornment={
          isPhone ? (
            <div className="d-flex align-items-center me-1">
              <span
                className="ms-2 fsize-16 text-dark-blue"
                dir="ltr"
              >
                +20
              </span>
              <div className="flag-container bg-gray-flag px-2 py-2">
                <EgyptFlag />
              </div>
            </div>
          ) : null
        }
      />
      {name === "min_delivery_order_price" && (
        <p className="price-label mb-0 me-3">{general.le}</p>
      )}
    </div>
  );

  const renderPaymentMethodsInputs = (options, valuesKey) => (
    <div>
      <div className="d-flex justify-content-between">
        {options.map(({ id, name }) => (
          <Checkbox
            name={name}
            key={id}
            color="secondary"
            label={profile[name]}
            labelClass="font-reguler text-dark fsize-16 checkbox-edit-profile"
            checked={values[valuesKey].includes(id)}
            onChange={(checked) => {
              setFieldTouched(valuesKey);
              const newArr = JSON.parse(
                JSON.stringify(values[valuesKey])
              );
              if (checked) {
                setFieldValue(valuesKey, [...newArr, id]);
              } else {
                setFieldValue(
                  valuesKey,
                  newArr.filter((elt) => elt !== id)
                );
              }
            }}
            disabled={name === "direct"}
          />
        ))}
      </div>
      {!!(touched[valuesKey] && errors[valuesKey]) && (
        <FormHelperText
          error={!!(touched[valuesKey] && errors[valuesKey])}
          className="font-medium fsize-12 text-end"
        >
          {errors[valuesKey]}
        </FormHelperText>
      )}
    </div>
  );

  const renderDeliveryMethodsInputs = (options, valuesKey) => (
    <div>
      <div className="d-flex justify-content-between">
        {options.map(({ id, name }) => (
          <Checkbox
            name={`method-${id}`}
            key={id}
            color="secondary"
            label={name}
            labelClass="font-reguler text-dark fsize-16 checkbox-edit-profile"
            checked={values[valuesKey].includes(id)}
            onChange={(checked) => {
              setFieldTouched(valuesKey);
              const newArr = JSON.parse(
                JSON.stringify(values[valuesKey])
              );
              if (checked) {
                setFieldValue(valuesKey, [...newArr, id]);
              } else {
                setFieldValue(
                  valuesKey,
                  newArr.filter((elt) => elt !== id)
                );
              }
            }}
            disabled={name === "بواسطة التاجر"}
          />
        ))}
      </div>
      {!!(touched[valuesKey] && errors[valuesKey]) && (
        <FormHelperText
          error={!!(touched[valuesKey] && errors[valuesKey])}
          className="font-medium fsize-12 text-end"
        >
          {errors[valuesKey]}
        </FormHelperText>
      )}
    </div>
  );

  return (
    <div className="content-wrapper">
      <div className="edit-profile-page">
        <form noValidate onSubmit={handleSubmit}>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="section-title fweight-600 fsize-18 text-dark mb-3">
                {profile.profile}
              </h5>
            </div>
            <div className="col-md-4 col-6 mb-3">
              {renderTextInput("username", "name")}
            </div>
            <div className="col-md-4 col-6 mb-3">
              {renderTextInput("email", "email", "email")}
            </div>
            <div className="col-md-4 col-6 mb-3">
              {renderTextInput(
                "phone_number",
                "mobile",
                "text",
                true,
                true
              )}
            </div>
            <div className=" col-8">
              {renderTextInput("address", "address")}
            </div>
            <div className=" col-4">
              <div className="d-flex">
                <p className="text-dark ms-2">{profile.logo}</p>
                <Upload
                  label={
                    <div className="pt-1 d-flex flex-column align-items-center">
                      <ImageOutlined />
                      <span>{profile.uploadLabel}</span>
                    </div>
                  }
                  onChange={(img, value) => {
                    setRawImg(img);
                    setFieldTouched("logo");
                    setFieldValue("logo", value);
                    setImgChanged(true);
                  }}
                  img={rawImg || values["logo"]}
                  name="logo"
                />
              </div>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="section-title fweight-600 fsize-18 text-dark mb-3">
                {profile.bankInfo}
              </h5>
            </div>
            <div className="col-md-4 col-6 mb-3">
              {renderTextInput("bank_name", "bankName")}
            </div>
            <div className="col-md-4 col-6 mb-3">
              {renderTextInput("bank_branch", "branchName")}
            </div>
            <div className="col-12 mb-3">
              {renderTextInput(
                "bank_account_number",
                "accountNumber"
              )}
            </div>
            <div className="col-12">
              {renderTextInput(
                "bank_iban",
                "ibanNumber",
                "text",
                false
              )}
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="section-title fweight-600 fsize-18 text-dark mb-3">
                {profile.incontactInfo}
              </h5>
            </div>
            <div className="col-md-4 col-6">
              {renderTextInput("incontact_name", "name")}
            </div>
            <div className="col-md-4 col-6">
              {renderTextInput("incontact_email", "email", "email")}
            </div>
            <div className="col-md-4 col-6">
              {renderTextInput(
                "incontact_phone_number",
                "mobile",
                "text",
                true,
                true
              )}
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-12">
              <h5 className="section-title fweight-600 fsize-18 text-dark mb-2">
                {profile.deliveryMethods}
                <span className="err-asterisk">*</span>
              </h5>
            </div>
            <div className="col-md-6 col-12">
              {renderDeliveryMethodsInputs(
                deliveryMethodsList,
                "delivery_method_ids"
              )}
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="section-title fweight-600 fsize-18 text-dark mb-2">
                {profile.paymentMethods}
                <span className="err-asterisk">*</span>
              </h5>
            </div>
            <div className="col-md-4 col-12">
              {renderPaymentMethodsInputs(
                wholesalerPaymentMethods,
                "payment_method_ids"
              )}
            </div>
          </div>
          <div className="text-center pt-5">
            <Button
              label={profile.saveChanges}
              type="submit"
              disabled={!dirty || !isValid}
              labelClass="fsize-14 text-white py-1"
              className="mb-4 submit-btn"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
