import * as types from "./types";

export const getKuzloProductsRequest = (data) => ({
  type: types.GET_KUZLO_PRODUCTS_REQUEST,
  payload: data
});

export const getKuzloProductsResponse = (data) => ({
  type: types.GET_KUZLO_PRODUCTS_RESPONSE,
  payload: data
});

export const getCategoriesRequest = (data) => ({
  type: types.GET_CATEGORIES_REQUEST,
  payload: data
});

export const getCategoriesResponse = (data) => ({
  type: types.GET_CATEGORIES_RESPONSE,
  payload: data
});

export const getProductRequest = (data) => ({
  type: types.GET_PRODUCT_REQUEST,
  payload: data
});

export const getProductResponse = (data) => ({
  type: types.GET_PRODUCT_RESPONSE,
  payload: data
});
