import React from "react";

export const Available = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="23"
    fill="none"
    viewBox="0 0 37 23"
    {...props}
  >
    <path
      fill="#36D71C"
      fillOpacity="0.7"
      d="M10.64.956a10.544 10.544 0 100 21.088h15.816a10.545 10.545 0 000-21.088H10.64zm15.816 15.816a5.272 5.272 0 110-10.544 5.272 5.272 0 010 10.544z"
    ></path>
  </svg>
);
