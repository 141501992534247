import React from "react";

export const UpToDownArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="31"
      fill="none"
      viewBox="0 0 12 31"
    >
      <path
        stroke="#ABABAB"
        strokeLinecap="round"
        strokeWidth="0.8"
        d="M9 2H5a4 4 0 00-4 4v18a4 4 0 004 4h4"
      ></path>
      <circle cx="9" cy="2" r="2" fill="#ABABAB"></circle>
      <path
        stroke="#ABABAB"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 25l2.5 2.5L9 30"
      ></path>
    </svg>
  );
};
