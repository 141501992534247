import { v4 as uuid } from "uuid";

import moment from "moment";
import "moment/locale/ar";

import { notificationsActionsList } from "./Constants";
import history from "../routes/History";
import store from "../store";
import {
  getNotificationsResponse,
  setNotificationSeenRequest,
  getAllNotificationsResponse
} from "../store/Notifications/actions";

export const generateDeviceId = () => {
  return uuid();
};

export const getDeviceId = () => {
  return localStorage.getItem("device_id");
};

const lang = localStorage.getItem("lang") || "ar";
moment.locale(lang);

export const formatDate = (date, format = "DD-MM-YYYY", language) => {
  if (language) {
    moment.locale(language);
  }
  return moment(date).format(format);
};

export const getTwentyFourHrFormat = (time, isAm) => {
  const newTime = moment(`${time} ${isAm ? "AM" : "PM"}`, [
    "h:m A"
  ]).format("HH:mm");
  return newTime;
};

export const getTwelveHrFormat = (time) => {
  const newTime = moment(time, "HH:mm").format("hh:mm A");
  return newTime;
};

export const handleNotificationsClickActions = ({
  id,
  click_action,
  action_id,
  seen,
  isToastMsg
}) => {
  const notificationAction = notificationsActionsList.find(
    (elt) => elt.action === click_action
  );
  !seen && !isToastMsg && setNotificationSeen(id);

  click_action &&
    notificationAction &&
    history.push(notificationAction.route?.replace(":id", action_id));
};

export const setNotificationSeen = (id) => {
  store.dispatch(setNotificationSeenRequest(id));
};

export const replaceSeenNotificationInList = (notification) => {
  const notificationsList = store.getState().notifications.list;
  const fullNotificationsList =
    store.getState().notifications.fullList;
  const notificationsMeta = store.getState().notifications.meta;
  const notificationIndex = notificationsList.findIndex(
    (elt) => elt.id === notification.id
  );
  const fullNotificationIndex = fullNotificationsList.findIndex(
    (elt) => elt.id === notification.id
  );

  notificationsList.splice(notificationIndex, 1, notification);
  fullNotificationsList.splice(
    fullNotificationIndex,
    1,
    notification
  );

  store.dispatch(
    getNotificationsResponse({
      list: notificationsList,
      meta: notificationsMeta
    })
  );
  store.dispatch(
    getAllNotificationsResponse({
      list: fullNotificationsList
    })
  );
};
