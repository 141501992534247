import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import SideMenu from "../../components/SideMenu";
import NavBar from "./../../components/NavBar";
import "./Layout.scss";

const Layout = ({ children }) => {
  const lang = useSelector((state) => state.locale.lang);
  const isAuth = useSelector((state) => state.auth.login?.auth_token);
  const dir = lang === "ar" ? "rtl" : "ltr";

  return isAuth ? (
    <div className="row layout" dir={dir}>
      <div className="col-2 sideMenuBar">
        <SideMenu />
      </div>
      <div className="col-10 pages-container">
        <NavBar />
        <div className="children-container">{children}</div>
      </div>
    </div>
  ) : (
    children
  );
};

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
