import * as types from "./types";

export const getOffersRequest = (data) => ({
  type: types.GET_OFFERS_REQUEST,
  payload: data
});

export const getOffersResponse = (data) => ({
  type: types.GET_OFFERS_RESPONSE,
  payload: data
});

export const getOfferRequest = (data) => ({
  type: types.GET_OFFER_REQUEST,
  payload: data
});

export const getOfferResponse = (data) => ({
  type: types.GET_OFFER_RESPONSE,
  payload: data
});

export const getPreviousProductOffersRequest = (data) => ({
  type: types.GET_PREVIOUS_PRODUCT_OFFERS_REQUEST,
  payload: data
});

export const getPreviousProductOffersResponse = (data) => ({
  type: types.GET_PREVIOUS_PRODUCT_OFFERS_RESPONSE,
  payload: data
});
