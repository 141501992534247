import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  categories: [],
  product: {}
};

const kuzloProductsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_KUZLO_PRODUCTS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };

    case types.GET_CATEGORIES_RESPONSE:
      return { ...state, categories: action.payload };
    case types.GET_PRODUCT_RESPONSE:
      return { ...state, product: action.payload };
    default:
      return state;
  }
};

export default kuzloProductsReducer;
