import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  Polygon
} from "google-maps-react";

import SearchMap from "../SearchMap";

const GoogleMaps = ({
  className,
  viewOnly,
  hasSearch,
  isMarker,
  getCoords,
  savedCoords,
  isPolygon,
  getPolygon,
  savedPolygon
}) => {
  // the user positin in ordinary map
  const [position, setPosition] = useState({
    coords: { lat: 0, lng: 0 }
  });

  // the user initial positin and after search in polygon
  const [initialPolygonPath, setInitialPolygonPath] = useState([]);

  // after the user selects the area
  // this one can't be provided to the Polygon props, it gets the updated value from the click handler
  const [updatedPolygonPath, setUpdatedPolygonPath] = useState([]);

  useEffect(() => {
    if (isMarker && savedCoords.coords) {
      // to set the user updated position in edit mode
      setPosition(savedCoords);
    } else if (isPolygon && savedPolygon && savedPolygon.length) {
      // to set user updated area in edit mode
      // and also set position with on of the polygon values
      // to set map center to show the selected area
      setPosition({
        coords: {
          lat: savedPolygon[0].lat,
          lng: savedPolygon[0].lng
        }
      });
      setInitialPolygonPath(savedPolygon);
    } else {
      // to set the initial user position
      navigator.geolocation.getCurrentPosition((userPosition) => {
        const {
          coords: { longitude, latitude }
        } = userPosition;
        setPosition({
          coords: {
            lat: latitude,
            lng: longitude
          }
        });
        if (isPolygon) {
          // to set user initial are in case of polygon
          setInitialPolygonPath([
            {
              lat: latitude,
              lng: longitude
            },
            {
              lat: latitude + 0.02,
              lng: longitude
            },
            {
              lat: latitude + 0.02,
              lng: longitude + 0.02
            },
            {
              lat: latitude,
              lng: longitude
            }
          ]);
        }
      });
    }
  }, []);

  // updating coords in parent in case of marker map
  useEffect(() => {
    if (!viewOnly && isMarker) {
      getCoords(position);
    }
  }, [position.coords]);

  // updating coords in parent in case of polygon map
  useEffect(() => {
    if (!viewOnly && isPolygon) {
      if (updatedPolygonPath.length) {
        getPolygon(updatedPolygonPath);
      } else {
        getPolygon(initialPolygonPath);
      }
    }
  }, [updatedPolygonPath, initialPolygonPath]);

  const onMoveMarker = (props, marker) => {
    // DO NOT move the props param even if it's not used
    setPosition({
      coords: {
        lat: marker.position.lat(),
        lng: marker.position.lng()
      }
    });
  };

  const onSearchMap = (SearchLat, SearchLng) => {
    setPosition({
      coords: {
        lat: SearchLat,
        lng: SearchLng
      }
    });
    if (isPolygon) {
      const newPolygonPath = [
        {
          lng: SearchLng,
          lat: SearchLat
        },
        {
          lng: SearchLng,
          lat: SearchLat + 0.02
        },
        {
          lng: SearchLng + 0.02,
          lat: SearchLat + 0.02
        },
        {
          lng: SearchLng,
          lat: SearchLat
        }
      ];
      setInitialPolygonPath(newPolygonPath);

      getPolygon(newPolygonPath);
    }
  };

  const handlePolygonChange = (props, polygon) => {
    // DO NOT move the props param even if it's not used
    let geoJSON = {
      type: "Polygon",
      coordinates: []
    };

    const paths = polygon.getPaths().getArray();

    for (let path of paths) {
      let pathArray = [];
      let points = path.getArray();
      let firstPoint = false;

      for (let point of points) {
        if (firstPoint === false) {
          firstPoint = point;
        }
        pathArray.push([point.lng(), point.lat()]);
      }

      pathArray.push([firstPoint.lng(), firstPoint.lat()]);
      geoJSON.coordinates.push(pathArray);
    }
    const newCoords = geoJSON.coordinates[0].map((elt) => ({
      lat: elt[0],
      lng: elt[1]
    }));
    setPosition({
      coords: {
        lng: newCoords[0].lng,
        lat: newCoords[0].lat
      }
    });
    setUpdatedPolygonPath(newCoords);
    return geoJSON;
  };

  return (
    <div>
      {hasSearch && <SearchMap onSearchMap={onSearchMap} />}
      <Map
        google={window.google}
        zoom={14}
        center={{
          lat: position.coords.lat,
          lng: position.coords.lng
        }}
        className={className}
        streetViewControl={false}
      >
        {isMarker && (
          <Marker
            name="Current-location"
            draggable={!viewOnly}
            onDragend={onMoveMarker}
            position={{
              lat: position.coords.lat,
              lng: position.coords.lng
            }}
          />
        )}
        {isPolygon && (
          <Polygon
            paths={initialPolygonPath}
            strokeColor="#e95b25"
            strokeOpacity={0.8}
            strokeWeight={4}
            fillColor="#e95b25"
            fillOpacity={0.35}
            onClick={handlePolygonChange}
            draggable={!viewOnly}
            editable={!viewOnly}
          />
        )}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(GoogleMaps);

GoogleMaps.propTypes = {
  className: PropTypes.string,
  viewOnly: PropTypes.bool,
  hasSearch: PropTypes.bool,
  isMarker: PropTypes.bool,
  getCoords: PropTypes.func,
  savedCoords: PropTypes.object,
  isPolygon: PropTypes.bool,
  getPolygon: PropTypes.func,
  savedPolygon: PropTypes.arrayOf(
    PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number })
  )
};

GoogleMaps.defaultProps = {
  hasSearch: true,
  viewOnly: false
};
