import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MaterialStepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepConnector from "@material-ui/core/StepConnector";

import "./Stepper.scss";

const Stepper = ({
  steps,
  dynamicStepsDisabling,
  handleSelectStep,
  currentStep,
  dynamicChange
}) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (currentStep) {
      const step = steps.findIndex(
        (elt) => elt.value === currentStep
      );
      setActiveStep(step);
    }
  }, [currentStep]);

  const handleStepClick = (stepIndex) => () => {
    if (dynamicChange) {
      setActiveStep(stepIndex);
    }
    handleSelectStep(steps[stepIndex]);
  };

  const handleStepDisbaling = ({ disabled, index }) => {
    if (
      dynamicStepsDisabling !== undefined &&
      disabled === undefined
    ) {
      return (
        index > activeStep + 1 ||
        index < activeStep ||
        index === activeStep
      );
    } else {
      return disabled;
    }
  };

  return (
    <MaterialStepper
      alternativeLabel
      nonLinear
      activeStep={activeStep}
      className="stepper-container"
    >
      {steps.map(({ label, disabled }, index) => {
        return (
          <Step
            key={label}
            disabled={handleStepDisbaling({ disabled, index })}
            className={`step ${
              activeStep === index ? "active-step" : "inactive-step"
            }`}
          >
            {index < steps.length - 1 && (
              <StepConnector
                className={`step-connector ${index} ${
                  index === activeStep && "short-end"
                } ${index === activeStep - 1 && "short-start"}`}
              />
            )}
            <StepButton
              onClick={handleStepClick(index)}
              className={"step-button"}
            >
              {label}
            </StepButton>
          </Step>
        );
      })}
    </MaterialStepper>
  );
};

Stepper.propTypes = {
  steps: PropTypes.array,
  dynamicStepsDisabling: PropTypes.bool,
  handleSelectStep: PropTypes.func,
  currentStep: PropTypes.string,
  dynamicChange: PropTypes.bool
};

Stepper.defaultProps = {
  dynamicChange: true
};

export default Stepper;
