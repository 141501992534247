import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Edit } from "@material-ui/icons";

import messages from "../../assets/locale/messages";
import { EgyptFlag } from "../../utils/Icons/EgyptFlag";
import { ImageOutlined } from "../../utils/Icons/ImageOutlined";
import { formatDate } from "../../utils/Helpers";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { viewProfileRequest } from "../../store/Profile/actions";
import Button from "../../components/Button";
import "./Profile.scss";

const ViewProfile = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const wholesalerId = useSelector((state) => state.auth.login.id);
  const profileDetails = useSelector(
    (state) => state.profile.details
  );

  const { profile } = messages[lang];

  useEffect(() => {
    dispatch(viewProfileRequest(wholesalerId));
  }, [wholesalerId]);

  const renderSingleDetail = (title, value, isPhone, largeTitle) => (
    <>
      {largeTitle ? (
        <h5 className="section-title fweight-600 fsize-18 text-dark mb-2">
          {title}
        </h5>
      ) : (
        <p className="text-dark">{title}</p>
      )}
      {isPhone ? (
        <p
          className="text-end text-dark-blue fsize-16 font-bold"
          dir="ltr"
        >
          {isPhone ? (
            <span className="flag-wrapper">
              <EgyptFlag />
            </span>
          ) : (
            ""
          )}
          {value}
        </p>
      ) : (
        <p className="text-dark-blue fsize-16 font-bold">{value}</p>
      )}
    </>
  );

  const renderMethodsDetail = (title, values) => (
    <>
      <h5 className="section-title fweight-600 fsize-18 text-dark mb-2">
        {title}
      </h5>
      <div className="d-flex justify-content-start">
        {values.map((value) => (
          <span
            className="text-dark-blue fsize-16 font-bold single-method"
            key={value.id}
          >
            {value.name}
          </span>
        ))}
      </div>
    </>
  );

  return (
    <div className="content-wrapper">
      {profileDetails && (
        <div className="view-profile-page">
          <div className="row border-bottom border-gray-hr-light pb-4">
            <div className="col-6">
              <h5 className="section-title fweight-600 fsize-18 text-dark mb-3">
                {profile.profile}
              </h5>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <Link to={ROUTE_PATHS.editProfile}>
                <Button
                  label={
                    <span className="d-flex align-items-center">
                      <Edit />
                      <span className="fsize-16">{profile.edit}</span>
                    </span>
                  }
                  className="py-2 px-5 text-white"
                />
              </Link>
            </div>
            <div className="col-md-4 col-6 mb-5">
              {renderSingleDetail(
                profile.name,
                profileDetails.username
              )}
            </div>
            <div className="col-md-4 col-6 mb-5">
              {renderSingleDetail(
                profile.email,
                profileDetails.email
              )}
            </div>
            <div className="col-md-4 col-6 mb-5">
              {renderSingleDetail(
                profile.mobile,
                `+20${profileDetails.phone_number}`,
                true
              )}
            </div>
            <div className=" col-8">
              {renderSingleDetail(
                profile.address,
                profileDetails.address
              )}
            </div>
            <div className=" col-4">
              <div className="d-flex">
                <p className="text-dark ms-2">{profile.logo}</p>
                {profileDetails.logo ? (
                  <div
                    style={{
                      backgroundImage: `url(${profileDetails.logo})`
                    }}
                    className="logo-wrapper"
                  ></div>
                ) : (
                  <div className="empty-logo-wrapper">
                    <ImageOutlined width="40" height="40" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row border-bottom border-gray-hr-light py-4">
            <div className="col-12">
              <h5 className="section-title fweight-600 fsize-18 text-dark mb-3">
                {profile.bankInfo}
              </h5>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-md-4 col-6 mb-5">
                  {renderSingleDetail(
                    profile.bankName,
                    profileDetails.bank_name
                  )}
                </div>
                <div className="col-md-4 col-6 mb-5">
                  {renderSingleDetail(
                    profile.branchName,
                    profileDetails.bank_branch
                  )}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-md-4 col-6">
                  {renderSingleDetail(
                    profile.accountNumber,
                    profileDetails.bank_account_number
                  )}
                </div>
                <div className="col-md-4 col-6">
                  {renderSingleDetail(
                    profile.ibanNumber,
                    profileDetails.bank_iban
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom border-gray-hr-light py-4">
            <div className="col-12">
              <h5 className="section-title fweight-600 fsize-18 text-dark mb-3">
                {profile.incontactInfo}
              </h5>
            </div>
            <div className="col-md-4 col-6">
              {renderSingleDetail(
                profile.name,
                profileDetails.incontact_name
              )}
            </div>
            <div className="col-md-4 col-6">
              {renderSingleDetail(
                profile.email,
                profileDetails.incontact_email
              )}
            </div>
            <div className="col-md-4 col-6">
              {renderSingleDetail(
                profile.mobile,
                `+20${profileDetails.incontact_phone_number}`,
                true
              )}
            </div>
          </div>
          <div className="row border-bottom border-gray-hr-light py-4">
            <div className="col-md-4 col-6">
              {renderMethodsDetail(
                profile.deliveryMethods,
                profileDetails.delivery_methods
              )}
            </div>
            <div className="col-md-4 col-6">
              {renderMethodsDetail(
                profile.paymentMethods,
                profileDetails.payment_methods
              )}
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-md-4 col-6">
              {renderSingleDetail(
                profile.status,
                !profileDetails.is_email_verified
                  ? profile.notVerified
                  : !profileDetails.is_published
                  ? profile.verified
                  : profileDetails.is_activated
                  ? profile.active
                  : profile.deactivated,
                false,
                true
              )}
            </div>
            <div className="col-md-4 col-6">
              {renderSingleDetail(
                profile.joiningDate,
                formatDate(profileDetails.created_at),
                false,
                true
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewProfile;
