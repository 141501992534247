import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Edit } from "@material-ui/icons";

import messages from "../../assets/locale/messages";
import {
  viewWarehouseRequest,
  viewWarehouseResponse
} from "../../store/Warehouse/actions";
import { viewProfileRequest } from "../../store/Profile/actions";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { getTwelveHrFormat } from "../../utils/Helpers";
import { LocationIcon } from "../../utils/Icons/LocationIcon";
import { ClockIcon } from "../../utils/Icons/ClockIcon";
import { Price } from "../../utils/Icons/Price";
import Button from "../../components/Button";
import MapModal from "../../components/MapModal";
import EmptyState from "../../components/EmptyState";
import "./Warehouse.scss";

const Warehouse = () => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [savedCoords, setSavedCoords] = useState({ coords: null });
  const [savedPolygon, setSavedPolygon] = useState([]);
  const [modalHeader, setModalHeader] = useState("");

  const wholesalerId = useSelector((state) => state.auth.login.id);
  const warehouseId = useSelector(
    (state) => state.profile.details?.warehouse_id
  );
  const warehouseDetails = useSelector(
    (state) => state.warehouse.details
  );

  const lang = useSelector((state) => state.locale.lang);
  const { warehouse, general } = messages[lang];

  useEffect(() => {
    return () => {
      dispatch(viewWarehouseResponse(null));
    };
  }, []);

  useEffect(() => {
    if (warehouseId) {
      dispatch(viewWarehouseRequest(warehouseId));
    }
  }, [warehouseId]);

  useEffect(() => {
    if (wholesalerId) {
      dispatch(viewProfileRequest(wholesalerId));
    }
  }, [wholesalerId]);

  const renderCoverageArea = ({
    id,
    name,
    polygon,
    timetable_slots,
    min_delivery_order_price
  }) => (
    <div className="coverage-area-wrapper" key={id}>
      <span className="text-dark area-label p-1">{name}</span>
      <p className="fsize-14 my-4">
        <LocationIcon className="ms-2" />
        <span
          className="pointer text-dark-blue show-map-btn fweight-500"
          onClick={() => handleShowCoverageArea({ polygon, name })}
        >
          {warehouse.viewMap}
        </span>
      </p>
      <div className="slots-wrapper d-flex">
        <ClockIcon className="ms-2" />
        <div className="slots">
          {timetable_slots.map((slot) => renderSingleSlot(slot))}
        </div>
      </div>
      <div className="d-flex ">
        <Price className="ms-2" />
        <p className="ms-2 min-price fweight-600 fsize-16">
          {warehouse.minOrderPrice}
        </p>
        <p className="ms-2 min-price-label fweight-600 fsize-16">
          {min_delivery_order_price}
          <span className="me-2">{general.le}</span>
        </p>
      </div>
    </div>
  );

  const renderSingleSlot = ({ id, name_ar, time_slots }) => (
    <div className="single-slot d-flex mb-4" key={id}>
      <p className="day fweight-600 ms-3 text-dark-blue fsize-16">
        {name_ar}
      </p>
      {time_slots.map((slot, i) => (
        <div key={slot.id} className="slot-time d-flex ms-3">
          <p className="from fweight-500 ms-1 fsize-16">
            {warehouse.from}
          </p>
          <p className="time fweight-500 ms-1 text-dark-blue fsize-16">
            {getTwelveHrFormat(slot.from)}
          </p>
          <p className="to fweight-500 ms-1 fsize-16">
            {warehouse.to}
          </p>
          <p className="time fweight-500 ms-1 text-dark-blue fsize-16">
            {getTwelveHrFormat(slot.to)}
          </p>
          {i !== time_slots.length - 1 && (
            <p className="comma fweight-500 fsize-16">,</p>
          )}
        </div>
      ))}
    </div>
  );

  const renderNoWarehouseState = () => (
    <EmptyState
      subTitle={warehouse.noWarehouseTitle}
      description={warehouse.noWarehouseDescription}
    />
  );
  const handleShowLocation = () => {
    setSavedCoords({
      coords: {
        lat: warehouseDetails.lat,
        lng: warehouseDetails.lng
      }
    });
    setModalHeader(warehouse.warehouseLocation);
    setSavedPolygon([]);
    setModalOpen(true);
  };
  const handleShowCoverageArea = ({ polygon, name }) => {
    const numericPolygon = polygon.map((coords) => ({
      lat: +coords.lat,
      lng: +coords.lng
    }));
    setSavedPolygon([...numericPolygon]);
    setModalHeader(name);
    setSavedCoords({
      coords: null
    });
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setSavedCoords({ coords: null });
    setSavedPolygon([]);
    setModalOpen(false);
  };
  return (
    <div className="content-wrapper">
      <div className="view-warehouse-page">
        {!warehouseId && renderNoWarehouseState()}
        {warehouseDetails && (
          <>
            <div className="row border-bottom border-gray-hr-light pb-5">
              <div className="col-6">
                <h5 className="fsize-18 text-dark mb-3 fweight-600 main-title">
                  {warehouse.warehouseDetails}
                </h5>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <Link to={ROUTE_PATHS.editWarehouse}>
                  <Button
                    label={
                      <span className="d-flex align-items-center">
                        <Edit />
                        <span className="fsize-16">
                          {warehouse.edit}
                        </span>
                      </span>
                    }
                    className="text-white py-2 px-5"
                  />
                </Link>
              </div>
              <div className="col-md-4 col-6">
                <p className="text-dark">{warehouse.name}</p>
                <p className="text-dark-blue fsize-16 font-bold">
                  {warehouseDetails.name}
                </p>
              </div>
              <div className="col-md-4 col-6">
                <p>
                  <LocationIcon className="ms-2" />
                  <span className="text-dark">
                    {warehouse.location}
                  </span>
                </p>
                <p
                  className="text-dark-blue fsize-14 pointer show-map-btn fweight-500"
                  onClick={handleShowLocation}
                >
                  {warehouse.viewMap}
                </p>
              </div>
            </div>
            <div className="row py-4">
              <div className="col-12">
                <h5 className="fsize-18 text-dark mb-5 fweight-600 coverage-area-title">
                  {warehouse.coverageArea}
                </h5>
              </div>
              {warehouseDetails.zones?.length ? (
                warehouseDetails.zones?.map((zone) =>
                  renderCoverageArea(zone)
                )
              ) : (
                <p>{warehouse.noCoverageAreas}</p>
              )}
            </div>
          </>
        )}
        {modalOpen && (
          <MapModal
            open={modalOpen}
            savedCoords={savedCoords}
            savedPolygon={savedPolygon}
            handleClose={handleCloseModal}
            modalHeaderLabel={modalHeader}
          />
        )}
      </div>
    </div>
  );
};

export default Warehouse;
