import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormHelperText } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";

import messages from "../../assets/locale/messages";
import ProductImagesSwiper from "../ProductImagesSwiper";
import {
  addWholesalerProductRequest,
  editWholesalerProductRequest
} from "../../store/WholsalerProducts/actions";
import Input from "../Input";
import Button from "../Button";
import {
  getProductRequest,
  getProductResponse
} from "../../store/KuzloProducts/actions";
import "./AddEditProduct.scss";
import { PRICE_REGEX } from "../../utils/Patterns";

const AddEditProduct = ({
  productId,
  onClose,
  productData,
  page,
  query,
  product_category_id
}) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const product = useSelector((state) => state.kuzloProducts.product);
  const [errMsg, setErrMsg] = useState("");
  const { products } = messages[lang];

  useEffect(() => {
    if (productId) {
      dispatch(getProductRequest(productId));

      return () => {
        dispatch(getProductResponse(null));
      };
    }
  }, [productId]);

  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    touched,
    setFieldTouched,
    dirty,
    isValid,
    setValues
  } = useFormik({
    initialValues: {
      price: "",
      quantity: "",
      min_ordering_quantity: "",
      max_ordering_quantity: ""
    },
    validationSchema: Yup.object({
      price: Yup.number()
        .typeError(products.productCard.pricrNumber)
        .required(products.productCard.priceErrorReq)
        .test(
          "min0",
          products.productCard.priceErrorMin,
          (price) => price > 0
        ),
      quantity: Yup.number().min(
        0,
        products.productCard.quantityError
      ),
      min_ordering_quantity: Yup.number().min(
        1,
        products.productCard.minError
      ),
      max_ordering_quantity: Yup.number().min(
        1,
        products.productCard.maxError
      )
    }),
    onSubmit: ({
      price,
      quantity,
      min_ordering_quantity,
      max_ordering_quantity
    }) => {
      if (
        +min_ordering_quantity > +max_ordering_quantity &&
        max_ordering_quantity !== ""
      ) {
        setErrMsg(products.productCard.minMaxError);
      } else {
        const data = {
          price: +price,
          quantity: +quantity || null,
          min_ordering_quantity: +min_ordering_quantity || null,
          max_ordering_quantity: +max_ordering_quantity || null
        };
        if (productData) {
          dispatch(
            editWholesalerProductRequest({
              id: productData.id,
              page,
              query,
              product_category_id,
              data
            })
          );
        } else {
          dispatch(
            addWholesalerProductRequest({
              product_id: productId,
              query,
              product_category_id,
              page,
              ...data
            })
          );
        }
        onClose(false);
      }
    }
  });

  useEffect(() => {
    if (productData) {
      setValues({
        price: productData.price.toString(),
        quantity: productData.quantity?.toString(),
        min_ordering_quantity:
          productData.min_ordering_quantity?.toString(),
        max_ordering_quantity:
          productData.max_ordering_quantity?.toString()
      });
    }
  }, [productData]);

  const renderdSlides = (images) => {
    if (images) {
      if (images?.length <= 4) {
        return (
          <div className="d-flex justify-content-start mx-5">
            {images?.map((image, index) => (
              <div
                key={index}
                style={{
                  backgroundImage: `url(${image.url})`,
                  backgroundSize: "cover"
                }}
                className="product-image mx-2 "
              ></div>
            ))}
          </div>
        );
      } else {
        return <ProductImagesSwiper slides={images} />;
      }
    }
  };

  return (
    <div>
      <div className="row px-5 py-4 ">
        <div className="col-6 pb-3 fsize-16 pe-5  text-dark-blue ">
          {productData
            ? productData?.product?.concatenated_name
            : product?.concatenated_name}
        </div>
        <div className="col-6">
          <span className="text-product-card-dark font-light ">
            {products.productCard.Category}:
          </span>
          <span className="text-product-card-dark pe-1 ">
            {productData
              ? productData?.product?.super_product?.product_category
                  ?.name
              : product?.super_product?.product_category?.name}
          </span>
        </div>
      </div>
      {productData
        ? renderdSlides(productData?.product?.images)
        : renderdSlides(product?.images)}

      <div className="d-flex  align-items-center px-5 my-5">
        <div className="text-product-card-dark fsize-14 my-2 pe-1 font-light">
          {products.productCard.description}:
        </div>
        <div className="fsize-16 text-product-card-dark my-2 pe-3">
          {productData
            ? productData?.product?.description
            : product?.description}
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row  mb-5 px-5">
          <div className="col-3  position-relative d-flex align-items-center">
            <Input
              label={products.productCard.price}
              id="price"
              name="price"
              required
              value={values["price"]}
              labelClassName=" mb-3 label-color text-right fsize-16"
              inputWrapperClass="modal-input"
              onChange={(value) => {
                setFieldTouched("price");
                if (value.match(PRICE_REGEX) || value === "") {
                  setFieldValue("price", value);
                }
              }}
              isInputHasErr={!!(touched["price"] && errors["price"])}
              errMsg={errors["price"]}
            />
            <p className="text-dark-blue pe-1 fsize-16 price">
              {products.productCard.pound}
            </p>
          </div>
          <div className="col-3 ">
            <Input
              label={products.productCard.quantity}
              id="quantity"
              name="quantity"
              value={values["quantity"]}
              labelClassName=" mb-3 label-color text-right fsize-16"
              inputWrapperClass="modal-input"
              onChange={(value) => {
                setFieldTouched("quantity");
                if (value.split("").every((num) => !isNaN(num))) {
                  setFieldValue("quantity", value);
                }
              }}
              isInputHasErr={
                !!(touched["quantity"] && errors["quantity"])
              }
              errMsg={errors["quantity"]}
            />
          </div>
          <div className="col-3 ">
            <Input
              label={products.productCard.minQuantity}
              id="min_ordering_quantity"
              name="min_ordering_quantity"
              labelClassName=" mb-3 label-color text-right fsize-16"
              value={values["min_ordering_quantity"]}
              inputWrapperClass="modal-input"
              onChange={(value) => {
                setFieldTouched("min_ordering_quantity");
                if (value.split("").every((num) => !isNaN(num))) {
                  setFieldValue("min_ordering_quantity", value);
                }
              }}
              isInputHasErr={
                !!(
                  touched["min_ordering_quantity"] &&
                  errors["min_ordering_quantity"]
                )
              }
              errMsg={errors["min_ordering_quantity"]}
            />
          </div>
          <div className="col-3 ">
            <Input
              label={products.productCard.maxQuantity}
              id="max_ordering_quantity"
              name="max_ordering_quantity"
              labelClassName=" mb-3 label-color text-right fsize-16"
              value={values["max_ordering_quantity"]}
              inputWrapperClass="modal-input"
              onChange={(value) => {
                setFieldTouched("max_ordering_quantity");
                if (value.split("").every((num) => !isNaN(num))) {
                  setFieldValue("max_ordering_quantity", value);
                }
              }}
              isInputHasErr={
                !!(
                  touched["max_ordering_quantity"] &&
                  errors["max_ordering_quantity"]
                )
              }
              errMsg={errors["max_ordering_quantity"]}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5 align-items-center">
          <Button
            label={products.productCard.modalButton}
            type="submit"
            labelClass="fsize-14 font-white py-1"
            className="my-5 w-50 "
            block
            disabled={!dirty || !isValid}
          />
        </div>
        {errMsg && (
          <FormHelperText
            error={!!errMsg}
            className="font-medium fsize-12 createErrorMsg"
          >
            <ErrorOutline />
            {errMsg}
          </FormHelperText>
        )}
      </form>
    </div>
  );
};

export default AddEditProduct;
AddEditProduct.propTypes = {
  productId: PropTypes.number,
  onClose: PropTypes.func,
  productData: PropTypes.object,
  page: PropTypes.number,
  query: PropTypes.string,
  product_category_id: PropTypes.number
};
