import {
  EDIT_WAREHOUSE_REQUEST,
  SET_WAREHOUSE_LOCATION,
  VIEW_WAREHOUSE_REQUEST,
  VIEW_WAREHOUSE_RESPONSE
} from "./types";

export const viewWarehouseRequest = (payload) => ({
  type: VIEW_WAREHOUSE_REQUEST,
  payload
});

export const viewWarehouseResponse = (payload) => ({
  type: VIEW_WAREHOUSE_RESPONSE,
  payload
});

export const setWarehouseLocation = (payload) => ({
  type: SET_WAREHOUSE_LOCATION,
  payload
});

export const editWarehouseRequest = (payload) => ({
  type: EDIT_WAREHOUSE_REQUEST,
  payload
});
