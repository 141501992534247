export const ROUTE_PATHS = {
  root: "/",
  login: "/login",
  viewProfile: "/profile/view",
  editProfile: "/profile/edit",
  forgotPassword: "/password/forgot",
  checkEmail: "/email/check",
  setPassword: "/password/set",
  verifyAccount: "/account/activate",
  warehouse: "/warehouse",
  editWarehouse: "/warehouse/edit",
  editWarehouseLocation: "/warehouse/edit/location",
  myProducts: "/my-products",
  myProductsQuickedit: "/my-products/quick-edit",
  serviceProviderProducts: `/${process.env.REACT_APP_SERVICE_PROVIDER_PRODUCTS_SLUG}`,
  viewMyProducts: "/my-products/:id",
  orders: "/orders",
  viewOrder: "/orders/:id",
  // notifications: "/notifications",
  offers: "/offers",
  viewOffer: "/offers/:id/view"
};
