import React from "react";
import PropTypes from "prop-types";
import { Button, FilledInput } from "@material-ui/core";

import "./Upload.scss";

const Upload = ({
  accept,
  onChange,
  label,
  required,
  disabled,
  img,
  name
}) => {
  return (
    <div className="upload-container">
      <FilledInput
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        accept={accept}
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            var reader = new FileReader();
            reader.onload = (event) => {
              onChange(event.target.result, e.target.files[0]);
            };
            reader.readAsDataURL(e.target.files[0]);
          }
        }}
        required={required}
        disabled={disabled}
        name={name}
      />
      <label htmlFor="raised-button-file">
        {img ? (
          <div
            style={{ backgroundImage: `url(${img})` }}
            className="render-img-input pointer"
          ></div>
        ) : (
          <Button
            variant="contained"
            component="span"
            className="btn-upload"
            disabled={disabled}
          >
            {label}
          </Button>
        )}
      </label>
    </div>
  );
};

export default Upload;

Upload.propTypes = {
  accept: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  img: PropTypes.any,
  name: PropTypes.string
};

Upload.defaultProps = {
  accept: "image/*",
  label: "Upload",
  required: false,
  disabled: false
};
