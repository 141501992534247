import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import amplitude from "amplitude-js";

import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import messages from "../../assets/locale/messages";
import { logoutRequest } from "../../store/Auth/actions";
import { LogoutIcon } from "../../utils/Icons/Logout";
import { WarehouseIcon } from "../../utils/Icons/Warehouse";
import { ProductsIcon } from "../../utils/Icons/Products";
import { OrdersIcon } from "../../utils/Icons/Orders";
import { Offers } from "../../utils/Icons/Offers";
import { getDeviceId } from "../../utils/Helpers";
import "./SideMenu.scss";

const SideMenu = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const dir = lang === "ar" ? "rtl" : "ltr";
  const message = messages[lang];
  const [path, setPath] = useState("");
  let location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
  }, [location, setPath]);

  useEffect(() => {
    if (
      location.pathname === ROUTE_PATHS.myProducts ||
      location.pathname === ROUTE_PATHS.serviceProviderProducts
    ) {
      amplitude
        .getInstance()
        .logEvent("wholesaler browse: view products list");
    }
  }, [location]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const activetRoute = (activeKeys) => {
    return activeKeys.some((key) => path.includes(key));
  };

  const sideMenu = [
    {
      id: 1,
      title: message.warehouse.title,
      icon: <WarehouseIcon />,
      slug: ROUTE_PATHS.warehouse,
      activekeys: ["warehouse"],
      hasChildren: false,
      children: []
    },
    {
      id: 2,
      title: message.products.title,
      icon: <ProductsIcon />,
      activekeys: [
        "my-products",
        process.env.REACT_APP_SERVICE_PROVIDER_PRODUCTS_SLUG
      ],
      hasChildren: true,
      children: [
        {
          id: 2.1,
          title: message.products.myProducts,
          slug: ROUTE_PATHS.myProducts,
          activekeys: ["my-products"]
        },
        {
          id: 2.2,
          title: message.products.serviceProviderProducts,
          slug: ROUTE_PATHS.serviceProviderProducts,
          activekeys: [
            process.env.REACT_APP_SERVICE_PROVIDER_PRODUCTS_SLUG
          ]
        }
      ]
    },
    {
      id: 3,
      title: message.orders.title,
      icon: <OrdersIcon />,
      slug: ROUTE_PATHS.orders,
      activekeys: ["orders"],
      hasChildren: false,
      children: []
    },
    {
      id: 4,
      title: message.offers.sideMenuTitle,
      icon: <Offers />,
      slug: ROUTE_PATHS.offers,
      activekeys: ["offers"],
      hasChildren: false,
      children: []
    }
  ];

  const drawerWidth = "100%";

  const useStyles = makeStyles((theme) => ({
    links: {
      color: theme.palette.white,
      textDecoration: "none",
      "&:hover, &.Mui-selected": {
        color: theme.palette.white
      },
      width: drawerWidth
    },

    listItem: {
      fontSize: "1rem",
      fontWeight: "500",
      fontFamily: "Inter",
      "&:hover, &.Mui-selected": {
        backgroundColor: theme.palette.white
      }
    },
    listItemIcon: {
      color: theme.palette.white
    },
    nested: {
      paddingLeft: theme.spacing(4),
      color: theme.palette.white
    }
  }));

  const classes = useStyles();

  const handleLogout = () => {
    dispatch(
      logoutRequest({
        device_id: getDeviceId()
      })
    );
  };

  const drawer = (
    <div dir={dir}>
      <List className={classes.links}>
        {sideMenu.map(
          ({
            id,
            title,
            icon,
            slug,
            activekeys,
            hasChildren,
            children
          }) => {
            return !hasChildren ? (
              <Link to={slug} key={id} dir={dir}>
                <ListItem
                  selected={activetRoute(activekeys)}
                  button
                  className={`${classes.ListItem} p-3`}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    {icon}
                  </ListItemIcon>
                  {title}
                </ListItem>
              </Link>
            ) : (
              <React.Fragment key={id}>
                <ListItem
                  dir={dir}
                  selected={activetRoute(activekeys)}
                  button
                  className={`${classes.ListItem} p-3`}
                  onClick={handleClick}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    {icon}
                  </ListItemIcon>
                  {title}
                  {open ? <ExpandMore /> : <ChevronLeftIcon />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div">
                    {children.map(
                      ({ id, title, slug, activekeys }) => (
                        <Link to={slug} key={id}>
                          <ListItem
                            className={`${classes.nested} ${
                              activetRoute(activekeys)
                                ? "active-child"
                                : ""
                            } pe-4`}
                          >
                            <p
                              className={`${
                                activetRoute(activekeys)
                                  ? "text-decoration-underline"
                                  : ""
                              } mb-0 pe-4`}
                            >
                              {title}
                            </p>
                          </ListItem>
                        </Link>
                      )
                    )}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          }
        )}
      </List>
      <div className="position-fixed bottom-0">
        <div className="divider m-auto"></div>
        <div className="d-flex">
          <div
            className="d-flex align-items-center p-4 pointer"
            onClick={handleLogout}
          >
            <LogoutIcon />
            <h3 className="fsize-14 text-white mb-0 me-3 logout opacity-6">
              {message.auth.logout}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="vh-100 Side-Menu position-fixed">
      <div className="logo-image d-flex justify-content-center align-items-center flex-column">
        <img
          src={process.env.REACT_APP_LOGO_SIDEMENU}
          alt="service provider logo"
        />
      </div>
      <div className="divider m-auto"></div>
      <Box sx={{ display: "flex" }}>
        <Box component="nav">
          <Drawer variant="permanent" open>
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </div>
  );
};

export default SideMenu;
