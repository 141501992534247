export const GET_OFFERS_REQUEST = "GET_OFFERS_REQUEST";

export const GET_OFFERS_RESPONSE = "GET_OFFERS_RESPONSE";

export const GET_OFFER_REQUEST = "GET_OFFER_REQUEST";

export const GET_OFFER_RESPONSE = "GET_OFFER_RESPONSE";

export const GET_PREVIOUS_PRODUCT_OFFERS_REQUEST =
  "GET_PREVIOUS_PRODUCT_OFFERS_REQUEST";

export const GET_PREVIOUS_PRODUCT_OFFERS_RESPONSE =
  "GET_PREVIOUS_PRODUCT_OFFERS_RESPONSE";
