import kuzloEn from "./kuzloEn";
import kuzloAr from "./kuzloAr";
import weCareEn from "./weCareEn";
import weCareAr from "./weCareAr";

let messages = {};

if (process.env.REACT_APP_LOCALE_TITLE === "kuzlo") {
  messages = {
    en: kuzloEn,
    ar: kuzloAr
  };
} else {
  messages = {
    en: weCareEn,
    ar: weCareAr
  };
}

export default messages;
