import React from "react";
import PropTypes from "prop-types";

import Button from "../Button";
import "./EmptyState.scss";

const EmptyState = ({
  subTitle,
  description,
  buttonLabel,
  handleButtonClick
}) => (
  <div className="d-flex justify-content-center align-items-center flex-column empty-state-container">
    <div className="mb-3">
      <img
        src={process.env.REACT_APP_LOGO_EMPTY_STATE_IMAGE}
        alt="empty-STATE-IMAGE"
      />
    </div>

    <p className="fsize-18 font-medium subtitle fweight-600 pb-2 mt-3">
      {subTitle}
    </p>
    <p className="fsize-16 font-medium description">{description}</p>
    {buttonLabel && (
      <Button
        label={buttonLabel}
        labelClass="fsize-16 text-white"
        onClick={handleButtonClick}
        block={true}
      />
    )}
  </div>
);

EmptyState.propTypes = {
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  handleButtonClick: PropTypes.func
};

export default EmptyState;
