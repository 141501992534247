import * as React from "react";

export const ProductsIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M7.924 22H4.54A2.549 2.549 0 0 1 2 19.44v-3.415a2.549 2.549 0 0 1 2.539-2.56h3.385a2.549 2.549 0 0 1 2.539 2.56v3.415A2.549 2.549 0 0 1 7.924 22Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.462 22h-3.386a2.549 2.549 0 0 1-2.539-2.56v-3.415a2.549 2.549 0 0 1 2.539-2.56h3.386A2.549 2.549 0 0 1 22 16.026v3.415A2.549 2.549 0 0 1 19.462 22Zm0-11.466h-3.386a2.549 2.549 0 0 1-2.539-2.56V4.56A2.55 2.55 0 0 1 16.076 2h3.386A2.55 2.55 0 0 1 22 4.56v3.415a2.549 2.549 0 0 1-2.538 2.56Zm-14.923 0h3.385a2.549 2.549 0 0 0 2.539-2.56V4.56A2.55 2.55 0 0 0 7.924 2H4.54A2.55 2.55 0 0 0 2 4.56v3.415a2.549 2.549 0 0 0 2.539 2.56Z"
      fill="#fff"
    />
  </svg>
);
