import React from "react";

export const CustomPrevOffersCalendar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      fill="none"
      viewBox="0 0 29 29"
    >
      <path
        fill="#ABABAB"
        d="M24.813 3.5h-4.125V1.437a.687.687 0 10-1.375 0V3.5H9.688V1.437a.688.688 0 10-1.376 0V3.5H4.189A3.442 3.442 0 00.75 6.938v17.875a3.442 3.442 0 003.438 3.437h20.625a3.44 3.44 0 003.437-3.438V6.938A3.44 3.44 0 0024.812 3.5zm2.062 21.313a2.062 2.062 0 01-2.063 2.062H4.188a2.062 2.062 0 01-2.062-2.063V13.126h24.75v11.688zm0-13.063H2.125V6.937c0-1.138.921-2.062 2.063-2.062h4.125v2.063a.688.688 0 101.374 0V4.874h9.626v2.063a.687.687 0 101.375 0V4.874h4.125a2.063 2.063 0 012.062 2.063v4.812z"
      ></path>
    </svg>
  );
};
